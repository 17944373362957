import { Component, OnInit, ViewChild, ElementRef, Renderer2, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { StoreService } from 'src/app/services/store/store.service';
import { CarritoService } from '../../services/carrito/carrito.service';
import { MapService } from '../../services/map/map.service';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { functions } from '../../helpers/funcionts';
import { ProductService } from 'src/app/services/product/product.service';
import { Router } from '@angular/router';
import { concat, forkJoin, merge } from 'rxjs';

declare var $:any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, AfterViewInit {

  @ViewChild('asGeocoder') asGeocoder : ElementRef | undefined;

  public d = this.Form.group({

    nombre:['', [ Validators.required]],
    apellido:['', [ Validators.required]],
    documento:['', [ Validators.required,  Validators.pattern('[0-9a-zA-ZáéíóúñÁÉÍÓÚÑ ]*') ]],
    telefono:['', [ Validators.required,  Validators.pattern('[-\\0-9]*') ]],
    email:[ '', [  Validators.email] ],
    delivery:[''],
    deliveryin:[ '', [ Validators.required ] ],
    direccion:[ '', [ Validators.required, Validators.pattern('[(\\)\\,\\.\\;\\:\\¡\\¿\\?\\!\\/\\´\\#\\_\\,\\.\\-\\0-9a-zA-ZáéíóúñÁÉÍÓÚÑ ]*'), Validators.maxLength(700) ] ],
    methodPago:[ '', [ Validators.required ] ],
    cupon:[ '', { Validators: [ Validators.pattern('[0-9a-zA-Z ]*') ], asyncValidators: [ this.verifyCupon() ], updateOn: 'blur'  } ],
    nota:[ '', [ Validators.pattern( '[(\\)\\,\\.\\;\\:\\¡\\¿\\?\\!\\/\\´\\#\\_\\,\\.\\-\\0-9a-zA-ZáéíóúñÁÉÍÓÚÑ ]*') ] ]

  })

  /*=============================================
	Controlador personalizado
	=============================================*/
  get nombre(){ return this.d.controls.nombre }
  get apellido(){ return this.d.controls.apellido }
  get documento(){ return this.d.controls.documento }
  get telefono(){ return this.d.controls.telefono }
  get email(){ return this.d.controls.email }
  get deliveryin(){ return this.d.controls.delivery }
  get direccion(){ return this.d.controls.direccion }
  get methodPago(){ return this.d.controls.methodPago }
  get cupon(){ return this.d.controls.cupon }
  get nota(){ return this.d.controls.nota }

  public e = this.Form.group({

    nombre:['', [ Validators.required]],
    apellido:['', [ Validators.required]],
    documento:['', [ Validators.required,  Validators.pattern('[0-9a-zA-ZáéíóúñÁÉÍÓÚÑ ]*') ]],
    telefono:['', [ Validators.required,  Validators.pattern('[-\\0-9]*') ]],
    email:[ '', [  Validators.email] ],
    methodPago:[ '', [ Validators.required ] ],
    sitios:[ '' ],
    cupon:[ '', { Validators: [ Validators.pattern('[0-9a-zA-Z ]*') ], asyncValidators: [ this.verifyCupon() ], updateOn: 'blur'  } ],
    nota:[ '', [ Validators.pattern( '[(\\)\\,\\.\\;\\:\\¡\\¿\\?\\!\\/\\´\\#\\_\\,\\.\\-\\0-9a-zA-ZáéíóúñÁÉÍÓÚÑ ]*') ] ]


  })

  /*=============================================
	Controlador personalizado
	=============================================*/
  get nombre1(){ return this.e.controls.nombre }
  get apellido1(){ return this.e.controls.apellido }
  get documento1(){ return this.e.controls.documento }
  get telefono1(){ return this.e.controls.telefono }
  get email1(){ return this.e.controls.email }
  get sitios(){ return this.e.controls.delivery }
  get direccion1(){ return this.e.controls.direccion }
  get methodPago1(){ return this.e.controls.methodPago }
  get cupon1(){ return this.e.controls.cupon }
  get nota1(){ return this.e.controls.nota }

  /*=============================================
	VALIDAR ENVIO DE FORMULARIO
	=============================================*/
  formSubmited:boolean = false;
  formSubmited1:boolean = false;

  entrega:boolean = false;
  delivery:boolean = false;

  precioDelibery:number = 0;
  precioDescuento:number = 0;

  // INICIALIZAR GOOGLE MAPS
  lat:number= 7.06851;
  lng:number= -66.54767;
  markers:any[] = [{
    lat: 7.06851,
    lng:-66.54767

  }];

  preloadBoton:boolean = false;
 
  iconMap:string = "https://whtscatalogo.com/assets/images/icono-mapa.png"

  currency:string = '';

  mensaje:boolean = false;

  tmpdelivery:any = [];
  tmptotal:any = [];

  showDelivery:boolean = false;

  carritoofcanvas:boolean = true;

  constructor( public storeService: StoreService, 
              public carritoService: CarritoService, 
              private mapservice: MapService, 
              private renderer2: Renderer2, 
              private Form:FormBuilder, 
              private cdRef:ChangeDetectorRef, 
              private productService: ProductService, 
              private router: Router ) { }
  continuar(value){
    this.carritoofcanvas = value;
    if( !value ){
      setTimeout( ()=>{
        const validateType = this.storeService.allDataStore[0].monedaPrincipal == '$' ? true : false;
        this.mapservice.buildMpa( validateType )
        .then( ({ map, geocoder, marker })=>{
  
          
          this.renderer2.appendChild( this.asGeocoder?.nativeElement ,
            geocoder.onAdd(map)  
          )
  
          map.on('click', (event) => {
            // https://docs.mapbox.com/mapbox-gl-js/api/#lnglat
            marker.setLngLat(event.lngLat).addTo(map);
             
            this.lng = event.lngLat.lng;
            this.lat = event.lngLat.lat;
  
        
          });
          
        })
        .catch( (err)=>{
  
          console.log( '******Error********', err )
  
        })
  
  
      }, 100)
    }
    
  }

  ngOnInit(): void { 

    // console.log( this.markers.length )

    // this.storeService.allDataStore[0].resp?.forEach(element => {
    //   console.log( element )
    // });

    //MAPA CON MAPBOX
    // this.mapservice.buildMpa()
    //   .then( ({ map, geocoder, marker })=>{
        
    //     this.renderer2.appendChild( this.asGeocoder?.nativeElement ,
    //       geocoder.onAdd(map)  
    //     )

    //     map.on('click', (event) => {
    //       // https://docs.mapbox.com/mapbox-gl-js/api/#lnglat
    //       marker.setLngLat(event.lngLat).addTo(map);
           
    //       this.lng = event.lngLat.lng;
    //       this.lat = event.lngLat.lat;

      
    //     });
        
    //   })
    //   .catch( (err)=>{

    //     console.log( '******Error********', err )

    //   })

    if( localStorage.getItem('datos')! ){

      let dataLocal = JSON.parse( localStorage.getItem('datos')! )

      this.nombre.setValue( dataLocal?.nombre);
      this.nombre1.setValue( dataLocal?.nombre);
      this.apellido.setValue( dataLocal?.apellido);
      this.apellido1.setValue( dataLocal?.apellido);
      this.documento.setValue( dataLocal?.documento);
      this.documento1.setValue( dataLocal?.documento);
      this.telefono.setValue( dataLocal?.telefono);
      this.telefono1.setValue( dataLocal?.telefono);
      this.email.setValue( dataLocal?.email);
      this.email1.setValue( dataLocal?.email);
      this.nota.setValue( dataLocal?.nota);
      this.nota1.setValue( dataLocal?.nota);

    }

    this.carritoService.total$.subscribe( (total:any) => {
      this.tmptotal = total;
      this.validateDelivery();
    })

    this.storeService.delivery$.subscribe( (delivery:any)=>{
      this.tmpdelivery = delivery;
      this.validateDelivery();
    })

  }

  //Validar delivery gratis
  validateDelivery(){

    if( this.tmpdelivery.length > 0 ){
      if( this.tmptotal > this.tmpdelivery[1] ){
        // this.d.controls.deliveryin.setValue('gratis')
        // this.d.controls.deliveryin.disable(); //deshabilitar
        this.d.controls.delivery.setValue('Delivery gratis')
        this.precioDelibery = 0;
        this.showDelivery = true;
      }else{
        this.d.controls.deliveryin.setValue('');
        // this.d.controls.deliveryin.enable(); //habilitar
        this.d.controls.delivery.setValue('')
        this.showDelivery = false;
      }
    }

  }

  //CAMBIO DE MONEDA
  setCurrency( data:any, monedas:any ){

    this.currency = data.symbol;

    monedas.forEach( (element:any) => {
      
      if( element.atc ){

        element.atc = false;

      }

    });

    data.atc = true;

    this.storeService.setCurrency(data.symbol, data.value)

  }

  //AGREGAR DATOS AL CARRITO
  aggCarrito( data:any, cantidad:any, type:string ){

    // if( data.stock == 0 || !data.estado )return

    if( type != 'clear' ){

      if( type == 'plus' ){

        if( data.stock > data.unit ) data.unit++;  


        $(`.valorCarrito-${ data.id }`).val( data.unit )

      }else if( type == 'minus'){

        data.unit--;

        $(`.valorCarrito-${ data.id }`).val( data.unit )

      }else if( type == 'change'){

        if( Number(cantidad) <= 0 || cantidad == ''){ 
    
          data.unit = 1;
          $(`.valorCarrito-${ data.id }`).val( data.unit )
          return;
        }

        if( cantidad <= data.stock ){

          data.unit = Number( cantidad )
          $(`.valorCarrito-${ data.id }`).val( data.unit )
        
        }else{

          data.unit = data.stock;
          $(`.valorCarrito-${ data.id }`).val( data.unit )

        }

      }

    }else{

      //AQUI SE ELIMINA EL PRODUCTO DEL CARRITO
      data.activar = false;
      this.carritoService.removeCaritot( data )

      return;
    }

    let valor = {

      detalles: data.detalles,
      detalles1: data.detalles1,
      image: data.imagen,
      name: data.name,
      precio: data.precio,
      store: data.store,
      unit: data.unit,
      newPrecio: data.precio[1],
      url: data.url,
      stock: data.stock

    }
   
    this.carritoService.aggCarrito( valor )

  }

  /*=============================================
  CAMBIO DE METODOS DE ENTREGA Y DELIVERY
  =============================================*/
  methodEntrega( type:any, data:any ){

    if( type === 'delivery' ){

      data.deliverySelect = true;
      data.entregraSelect = false;

    }else{

      data.deliverySelect = false;
      data.entregraSelect = true;

    }

  }

  /*=============================================
  Validamos formulario
  =============================================*/
  invaldField( campo: string ): boolean{
    
    return this.d.get(campo)!.invalid && this.formSubmited ?  true : false
  
  }

  invaldField1( campo: string ): boolean{
    
    return this.e.get(campo)!.invalid && this.formSubmited1 ?  true : false
  
  }


  //MARCAR UBUCACION ACTUAL RAPIDA
  marcarUbicacionActual(){

    this.markers = [];

    navigator.geolocation.getCurrentPosition((position)=>{

      this.lat = position.coords.latitude;
      this.lng = position.coords.longitude;
      // this.zoom = 15;

      this.markers.push({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      });

    })

  }

  /*=============================================
  DETECTOR DE CAMBIOS EN EL DOM
  =============================================*/
  ngAfterViewInit(){

    this.cdRef.detectChanges();

  }

  /*=============================================
  CAMBIO DE PRECIO DE DELIVERY
  =============================================*/
  cambioDelivery( valor:any, value:any ){
    
    const total = this.carritoService.total;

    if( !this.showDelivery ){

      if( Number(valor[value].precio) == 0 ){
        this.precioDelibery = 0;
        this.mensaje = false;
        return;
      }
  
      if( valor[value]?.delivery > 0 && total > valor[value]?.delivery ){
        this.precioDelibery = 0;
        this.mensaje = false;
        return;
      }
  
      this.precioDelibery = valor[value].precio;
  
      this.mensaje = false;

    }

  }

  //VERIFICAR SI EL CUPON DE DESCUENTO ES VALIDO PARA APLICAR EL DESCUENTO
  verifyCupon(){

    return( control: AbstractControl ) =>{
      
      return new Promise( (resolve, reject)=>{

        if( this.storeService.dataStore[0].codigo_descuento.length > 0 ){
 
          if( this.storeService.dataStore[0].codigo_descuento[1].trim() === control.value.trim() ){
            
            this.precioDescuento = this.storeService.dataStore[0].codigo_descuento[2];

            resolve( null )

          }else{

            if( control.value.trim() === '' ){

              this.precioDescuento = 0;

              resolve( null )

            }else{

              this.precioDescuento = 0;
              resolve( { coupon:true } )
            }

          }

        }else{
          this.precioDescuento = 0;
          resolve( null )

        }

      })

    }

  }

  /*=============================================
	ENVIAR DATOS DE DELIVERY
	=============================================*/
  sendDelivery(){

    /*=============================================
    VALIDAR ENVIO DE FORMULARIO
	  =============================================*/
    this.formSubmited = true;

    if( this.d.invalid ){

      return;

    }

    if( this.carritoService.total < this.storeService.dataStore[0].delivery_gratis[1] ){

      // console.log( this.d.controls.deliveryin.value )

      if( this.d.controls.deliveryin.value == 'gratis' ){

        this.mensaje = true;

        return;

      }

    }

    this.preloadBoton = true;

    let carrito = '';
    let detalle = '';
    this.carritoService.dataCarrito.forEach( (element:any)=>{

      if(element.detalles1.length > 0){

        detalle = '';

        element.detalles1.forEach(element1 => {

          console.log( element1 )

          detalle += `*[* ${element1.type} : ${element1.valor} *]* `
        
        });

        carrito +=  `*${element.unit}* x ${element.name.replace(/&/g,'y')}  ${detalle} *|* $${element.newPrecio} &`

      }else{

        carrito += `*${element.unit}* x ${element.name.replace(/&/g,'y')} *|* $${element.newPrecio} &`

      }

    })

    let dataLocal = {
      nombre: this.d.controls.nombre.value,
      apellido: this.d.controls.apellido.value,
      documento: this.d.controls.documento.value,
      telefono: this.d.controls.telefono.value,
      email: this.d.controls.email.value,
      nota: this.d.controls.nota.value
    }

    localStorage.setItem('datos', JSON.stringify(dataLocal))

    let mapa1 = `https://maps.google.com/maps?q=${this.lat},${this.lng}`;
    let mapa = mapa1.replace(/&/g,'%26');

    const detallesCarrito = carrito.replace(/ &/g,'%0d%0a' );
    const nombre = this.d.controls.nombre.value.replace(/ /g,'%20');
    const apellido =  this.d.controls.apellido.value.replace(/ /g,'%20');
    const documento = this.d.controls.documento.value;
    const metodoPago = this.d.controls.methodPago.value.split('_')[0];
    const telefono = this.d.controls.telefono.value.replace(/ /g,'%20')
                                    .replace('+', '%2B');
    const direccion = this.d.controls.direccion.value.replace(/ /g,'%20')
                                                      .replace(/#/g,'%23')
                                                      .replace(/"/g,'%22')
                                                      .replace(/~/g,'%7E')
                                                      .replace('+', '%2B')
                                                      .replace(/&/g,'%26')
    const nota = this.d.controls.nota.value.replace(/ /g,'%20')
                                            .replace(/#/g,'%23')
                                            .replace(/"/g,'%22')
                                            .replace(/~/g,'%7E')
                                            .replace(/&/g,'%26');
    
    const bancoSelec = this.d.controls.methodPago.value.split('_')[1];

    const totalvariasCrurrency = functions.currencyAll( this.carritoService.total, this.precioDelibery, this.precioDescuento, this.storeService.allDataStore[0] );

    const pedidos = new Promise( ( resolve, reject )=>{

      this.productService.getFilterDataLimitPedido( 'tienda', this.storeService.allDataStore[0].store, 1 )
      .subscribe( (resp:any)=>{

        if( resp == null ){

          let n_pedido1 = functions.nCorrelacion(1, 'new');

          let productos = [];

          this.carritoService.dataCarrito.forEach( (prod:any)=>{

            productos.push({
              'id':prod?.id,
              'image': prod.image,
              'cantidad':prod.unit,
              'producto':prod.name,
              'url':prod.url,
              'detalle':prod.detalles1,
              'precioN':prod.newPrecio,
              'precio':prod.precio,
              'costo': prod.costo
              })

          })

          const statusPedido = `https://www.whtscatalogo.com/${this.storeService.dataStore[0].url}/pedido/${n_pedido1}`;

          //CREAR PEDIDO A LA BD
          let body = {
          
            'nombre_cliente': `${this.d.controls.nombre.value} ${this.d.controls.apellido.value}`,
            'documento': this.d.controls.documento.value,
            'metodo_pago': metodoPago,
            'telefono': this.d.controls.telefono.value,
            'nota': this.d.controls.nota.value,
            'direccion': this.d.controls.direccion.value,
            'productos': JSON.stringify(productos),
            'total': this.carritoService.total,
            'tienda': this.storeService.allDataStore[0].store,
            'n_pedido': n_pedido1,
            'fecha_p': new Date(),
            'precio_delivery': this.precioDelibery,
            'status':'pending',
            'cupon': this.precioDescuento,
            'email': this.d.controls.email.value,
            'totalItem': this.carritoService.totalItems

          }

          // window.open(enviar)
          resolve(  { n_pedido1, statusPedido, status:true } )

          this.productService.registroPedidoSinUid(body)
          .subscribe( (resp1:any)=>{
            // ${procetajeDeCupon}
           
          })



        }else{

          let n_pedido1 = functions.nCorrelacion( Object.values( resp )[0]['n_pedido']);

          let productos = [];

          this.carritoService.dataCarrito.forEach( (prod:any)=>{

            productos.push({
              'id':prod?.id,
              'image': prod.image,
              'cantidad':prod.unit,
              'producto':prod.name,
              'url':prod.url,
              'detalle':prod.detalles1,
              'precioN':prod.newPrecio,
              'precio':prod.precio,
              'costo': prod.costo
              })

          })

          const statusPedido = `https://www.whtscatalogo.com/${this.storeService.dataStore[0].url}/pedido/${n_pedido1}`;

          //CREAR PEDIDO A LA BD
          let body = {
          
            'nombre_cliente': `${this.d.controls.nombre.value} ${this.d.controls.apellido.value}`,
            'documento': this.d.controls.documento.value,
            'metodo_pago': metodoPago,
            'telefono': this.d.controls.telefono.value,
            'nota': this.d.controls.nota.value,
            'direccion': this.d.controls.direccion.value,
            'productos': JSON.stringify(productos),
            'total': this.carritoService.total,
            'tienda': this.storeService.allDataStore[0].store,
            'n_pedido': n_pedido1,
            'fecha_p': new Date(),
            'precio_delivery': this.precioDelibery,
            'status':'pending',
            'cupon': this.precioDescuento,
            'email': this.d.controls.email.value,
            'totalItem': this.carritoService.totalItems

          }

          resolve(  { n_pedido1, statusPedido, status:false } )

          this.productService.registroPedidoSinUid(body)
          .subscribe( (resp1:any)=>{
            // ${procetajeDeCupon}

          })
          

        }

      })

    })

    pedidos.then( (resp:any)=>{

      setTimeout(() => {

        this.carritoService.dataCarrito = [];

        let telefonosend = this.storeService.dataStore[0].telefono.replace('+','').replace('-','');
        if(localStorage.getItem('sucursal')){
          telefonosend = localStorage.getItem('sucursal').replace('+','').replace('-','');
        }
        
        if( resp.status ){
          localStorage.removeItem( `carrito-${this.storeService.dataStore[0].url }` )
          window.open( `https://wa.me/${telefonosend}/?text=%20====🏷+*Pedido+${resp.n_pedido1}*====%0A%0A*Nuevo%20pedido*%20🛵%20*Delivery*%20*(${this.replaceCaracter(this.storeService.dataStore[0].tienda)})*%0A%0A*Articulos:*+${this.carritoService.totalItems}%0A%0A${detallesCarrito}%0A*Subtotal:*%20$${(this.carritoService.total).toFixed(2)}%0A*Delivery:*%20$${this.precioDelibery}${totalvariasCrurrency}%0A%0A=====*Entregar%20Al%20Cliente*=====%0A%0A👤+${nombre}+${apellido}%0A✔️+*ID:*+${documento}%0A📱+${telefono}%0A✔️+*Metodo+de+pago:*+${metodoPago}%0A📍+*Direccion:*+${direccion}%0A📍+*Ubicación+mapa:*+${mapa}%0A📝**${nota.toUpperCase()}**%0A🏷️+*Status+pedido:*+${resp.statusPedido}%0A%0A*${metodoPago.toUpperCase()}*%20%0d%0a${bancoSelec}%20%0d%0a*Observación:*+_${this.storeService.dataStore[0].mensaje_pago}_ `, '_self')

          this.d.reset();
          this.preloadBoton = false;

        }else{
          localStorage.removeItem( `carrito-${this.storeService.dataStore[0].url }` )
          window.open( `https://wa.me/${telefonosend}/?text=%20====🏷+*Pedido+${resp.n_pedido1}*====%0A%0A*Nuevo%20pedido*%20🛵%20*Delivery*%20*(${this.replaceCaracter(this.storeService.dataStore[0].tienda)})*%0A%0A*Articulos:*+${this.carritoService.totalItems}%0A%0A${detallesCarrito}%0A*Subtotal:*%20$${(this.carritoService.total).toFixed(2)}%0A*Delivery:*%20$${this.precioDelibery}${totalvariasCrurrency}%0A%0A=====*Entregar%20Al%20Cliente*=====%0A%0A👤+${nombre}+${apellido}%0A✔️+*ID:*+${documento}%0A📱+${telefono}%0A✔️+*Metodo+de+pago:*+${metodoPago}%0A📍+*Direccion:*+${direccion}%0A📍+*Ubicación+mapa:*+${mapa}%0A📝**${nota.toUpperCase()}**%0A🏷️+*Status+pedido:*+${resp.statusPedido}%0A%0A*${metodoPago.toUpperCase()}*%20%0d%0a${bancoSelec}%20%0d%0a*Observación:*+_${this.storeService.dataStore[0].mensaje_pago}_`, '_self' )

          this.d.reset();
          this.preloadBoton = false;

        }

        // window.open(resp)


        // window.location.reload();
        
      }, 1000);
     
    })


  }

  /*=============================================
	ENVIAR DATOS DE ENTREGA PRESONAL
	=============================================*/
  sendEntrega(){

    // console.log( this.e )

    /*=============================================
    VALIDAR ENVIO DE FORMULARIO
	  =============================================*/
    this.formSubmited1 = true;

    if( this.e.invalid ){

      return;

    }

    this.preloadBoton = true;

    let carrito = '';
    let detalle = '';
    this.carritoService.dataCarrito.forEach( (element:any)=>{

      if(element.detalles1.length > 0){

        detalle = '';

        element.detalles1.forEach(element1 => {

          console.log( element1 )

          detalle += `*[* ${element1.type} : ${element1.valor} *]* `
        
        });

        carrito +=  `*${element.unit}* x ${element.name.replace(/&/g,'y')}  ${detalle} *|* $${element.newPrecio} &`

      }else{

        carrito += `*${element.unit}* x ${element.name.replace(/&/g,'y')} *|* $${element.newPrecio} &`

      }

    })

    let dataLocal = {
      nombre: this.e.controls.nombre.value,
      apellido: this.e.controls.apellido.value,
      documento: this.e.controls.documento.value,
      telefono: this.e.controls.telefono.value,
      email: this.e.controls.email.value,
      nota: this.e.controls.nota.value
    }

    localStorage.setItem('datos', JSON.stringify(dataLocal))

    const detallesCarrito = carrito.replace(/ &/g,'%0d%0a' );
    const nombre = this.e.controls.nombre.value.replace(/ /g,'%20');
    const apellido =  this.e.controls.apellido.value.replace(/ /g,'%20');
    const documento = this.e.controls.documento.value;
    const metodoPago = this.e.controls.methodPago.value.split('_')[0];
    const telefono = this.e.controls.telefono.value.replace(/ /g,'%20')
                                    .replace('+', '%2B');
    const nota = this.e.controls.nota.value.replace(/ /g,'%20')
                                            .replace(/#/g,'%23')
                                            .replace(/"/g,'%22')
                                            .replace(/~/g,'%7E')
                                            .replace(/&/g,'%26');
    
    const bancoSelec = this.e.controls.methodPago.value.split('_')[1];

    const totalvariasCrurrency = functions.currencyAll( this.carritoService.total, 0, this.precioDescuento, this.storeService.allDataStore[0] );

    const pedidos = new Promise( ( resolve, reject )=>{

      this.productService.getFilterDataLimitPedido( 'tienda', this.storeService.allDataStore[0].store, 1 )
      .subscribe( (resp:any)=>{

        if( resp == null ){

          let n_pedido1 = functions.nCorrelacion(1, 'new');

          let productos = [];

          this.carritoService.dataCarrito.forEach( (prod:any)=>{

            productos.push({
              'id': prod?.id,
              'image': prod.image,
              'cantidad':prod.unit,
              'producto':prod.name,
              'url':prod.url,
              'detalle':prod.detalles1,
              'precioN':prod.newPrecio,
              'precio':prod.precio,
              'costo': prod.costo
              })

          })

          const statusPedido = `https://www.whtscatalogo.com/${this.storeService.dataStore[0].url}/pedido/${n_pedido1}`;

          //CREAR PEDIDO A LA BD
          let body = {
          
            'nombre_cliente': `${this.e.controls.nombre.value} ${this.e.controls.apellido.value}`,
            'documento': this.e.controls.documento.value,
            'metodo_pago': metodoPago,
            'telefono': this.e.controls.telefono.value,
            'nota': this.e.controls.nota.value,
            'direccion': '',
            'productos': JSON.stringify(productos),
            'total': this.carritoService.total,
            'tienda': this.storeService.allDataStore[0].store,
            'n_pedido': n_pedido1,
            'fecha_p': new Date(),
            'precio_delivery': this.precioDelibery,
            'status':'pending',
            'cupon': this.precioDescuento,
            'email': this.e.controls.email.value,
            'totalItem': this.carritoService.totalItems

          }

          // window.open(enviar)
          resolve(  { n_pedido1, statusPedido, status:true } )

          this.productService.registroPedidoSinUid(body)
          .subscribe( (resp1:any)=>{
            // ${procetajeDeCupon}
           
          })

        }else{

          let n_pedido1 = functions.nCorrelacion( Object.values( resp )[0]['n_pedido']);

          let productos = [];

          this.carritoService.dataCarrito.forEach( (prod:any)=>{

            productos.push({
              'id': prod?.id,
              'image': prod.image,
              'cantidad':prod.unit,
              'producto':prod.name,
              'url':prod.url,
              'detalle':prod.detalles1,
              'precioN':prod.newPrecio,
              'precio':prod.precio,
              'costo': prod.costo
              })

          })

          const statusPedido = `https://www.whtscatalogo.com/${this.storeService.dataStore[0].url}/pedido/${n_pedido1}`;

          //CREAR PEDIDO A LA BD
          let body = {
          
            'nombre_cliente': `${this.e.controls.nombre.value} ${this.e.controls.apellido.value}`,
            'documento': this.e.controls.documento.value,
            'metodo_pago': metodoPago,
            'telefono': this.e.controls.telefono.value,
            'nota': this.e.controls.nota.value,
            'direccion': '',
            'productos': JSON.stringify(productos),
            'total': this.carritoService.total,
            'tienda': this.storeService.allDataStore[0].store,
            'n_pedido': n_pedido1,
            'fecha_p': new Date(),
            'precio_delivery': this.precioDelibery,
            'status':'pending',
            'cupon': this.precioDescuento,
            'email': this.e.controls.email.value,
            'totalItem': this.carritoService.totalItems

          }

          resolve( { n_pedido1, statusPedido, status:false } )

          this.productService.registroPedidoSinUid(body)
          .subscribe( (resp1:any)=>{
            // ${procetajeDeCupon}

          })
          

        }

      })

    })

    pedidos.then( (resp:any)=>{

      setTimeout(() => {

        let telefonosend = this.storeService.dataStore[0].telefono.replace('+','').replace('-','');
        if(localStorage.getItem('sucursal')){
          telefonosend = localStorage.getItem('sucursal').replace('+','').replace('-','');
        }

        this.carritoService.dataCarrito = [];

        if(resp.status){
          localStorage.removeItem( `carrito-${this.storeService.dataStore[0].url }` )

          window.open( `https://wa.me/${telefonosend}/?text=%20====🏷+*Pedido+${resp.n_pedido1}*====%0A%0A*Nuevo%20pedido*%20🏬%20*Recoger*%20*(${this.replaceCaracter(this.storeService.dataStore[0].tienda)})*%0A%0A*Direccion+de+retiro:*+${this.replaceCaracter(this.e.controls.sitios.value ? this.e.controls.sitios.value: 'No hay dirección')}%0A%0A*Articulos:*+${this.carritoService.totalItems}%0A%0A${detallesCarrito}%0A*Subtotal:*%20${(this.carritoService.total).toFixed(2)}${totalvariasCrurrency}%0A%0A=====*Entregar%20Al%20Cliente*=====%0A%0A👤+${nombre}+${apellido}%0A✔️+*ID:*+${documento}%0A📱+${telefono}%0A✔️+*Metodo+de+pago:*+${metodoPago}%0A📝**${nota.toUpperCase()}**%0A🏷️+*Status+pedido:*+${resp.statusPedido}%0A%0A*${metodoPago.toUpperCase()}*%20%0d%0a${bancoSelec}%20%0d%0a*Observación:*+_${this.storeService.dataStore[0].mensaje_pago}_`, '_self')

          this.e.reset();
          this.preloadBoton = false;

        }else{

          localStorage.removeItem( `carrito-${this.storeService.dataStore[0].url }` )

          window.open( `https://wa.me/${telefonosend}/?text=%20====🏷+*Pedido+${resp.n_pedido1}*====%0A%0A*Nuevo%20pedido*%20🏬%20*Recoger*%20*(${this.replaceCaracter(this.storeService.dataStore[0].tienda)})*%0A%0A*Direccion+de+retiro:*+${this.replaceCaracter(this.e.controls.sitios.value ? this.e.controls.sitios.value: 'No hay dirección' )}%0A%0A*Articulos:*+${this.carritoService.totalItems}%0A%0A${detallesCarrito}%0A*Subtotal:*%20${(this.carritoService.total).toFixed(2)}${totalvariasCrurrency}%0A%0A=====*Entregar%20Al%20Cliente*=====%0A%0A👤+${nombre}+${apellido}%0A✔️+*ID:*+${documento}%0A📱+${telefono}%0A✔️+*Metodo+de+pago:*+${metodoPago}%0A📝**${nota.toUpperCase()}**%0A🏷️+*Status+pedido:*+${resp.statusPedido}%0A%0A*${metodoPago.toUpperCase()}*%20%0d%0a${bancoSelec}%20%0d%0a*Observación:*+_${this.storeService.dataStore[0].mensaje_pago}_`, '_self')

          this.e.reset();
          this.preloadBoton = false;

        }

        // window.location.reload();
        
      }, 1000);
     
    })

  }

  replaceCaracter(data){

    if( data )
      return data.replace(/ /g,'%20')
        .replace(/#/g,'%23')
        .replace(/"/g,'%22')
        .replace(/~/g,'%7E')
        .replace(/&/g,'%26')

  }
}
