import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { functions } from '../../helpers/funcionts';
import { Productin } from '../../interface/product.inerface';
import { Observable, Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  dataImg:any[] = [];

  dataProduct:any[] = [];

  ofertas:boolean = false;

  productOfert:Subject<any> = new Subject<any>();

  ofertProducts:any[] = [];

  constructor( private http: HttpClient,  private router: Router  ) { this.allProduct().subscribe( (resp)=> {  } ) }

  getFilterData(orderBy:string, equalTo:string){

		return this.http.get(`${environment.Api}productos.json?orderBy="${orderBy}"&equalTo="${equalTo}"&print=pretty`);

  }

  getLimitFilter(orderBy:string, equalTo:string, limit:number){

		return this.http.get(`${environment.Api}productos.json?orderBy="${orderBy}"&equalTo="${equalTo}"&limitToLast=${limit}&print=pretty`);

  }

  allProduct(){

    // return new Observable((observer)=>{
    //   const objeto = {
    //     "-OCs7EB84Tz7t_KN62Sp": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-29T13:34:03.528Z",
    //         "galeria": "[\"86618381732887244.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "8674881732887243.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Colgate Luminous 75ml",
    //         "oferta": "[\"Fixed\",5.67,\"2025-01-31\"]",
    //         "precio": 6.0669,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "colgate-luminous-75ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NwQsfJJMCppVHxi-DrT": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-04-26T19:29:51.407Z",
    //         "galeria": "[\"36385101714159793.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "43017721714159791.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Protectores Diarios Alwys X40 Sin Perfume",
    //         "oferta": "",
    //         "precio": 3.82,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "protectores-diarios-alwys-x40-sin-perfume",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mi7thUpQlG9WJGgdAVG": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "Arveja Amarilla partida Pantera 500g",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-09-19T15:07:23.185Z",
    //         "galeria": "[\"76959721630159529.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "80741401630159523.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Arvejas Amarillas Partidas Pantera 500g",
    //         "oferta": "[\"Fixed\",1.33,\"2025-01-31\"]",
    //         "precio": 1.4231,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "arvejas-amarillas-partidas-pantera-500g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OGgVk3v_iiWAqG6j5uB": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-01-16T00:28:31.671Z",
    //         "galeria": "[\"91712311736987312.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "59609631736987311.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Avena Fit Graan 250gr",
    //         "oferta": "[\"Fixed\",0.55,\"2025-12-31\"]",
    //         "precio": 0.5885,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "avena-fit-graan-250gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPlEh289JAs5q9AcacC": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "<ol><li><b>2Kg Solomo o Punta</b></li><li><b>1Kg Chuleta de Cerdo</b></li><li><b>1Kg Chorizo de Ajo, Ahumado o Picante</b></li></ol>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2020-12-30T01:24:07.273Z",
    //         "galeria": "[\"67798421640822710.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "49145571640822704.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo Parrillero 4kg",
    //         "oferta": "[\"Fixed\",29,\"2025-12-31\"]",
    //         "precio": 31.03,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-parrillero-4kg",
    //         "video": "[]",
    //         "views": 120
    //     },
    //     "-OA-Xjx2GY_2LhNZCSYl": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-24T21:59:45.923Z",
    //         "galeria": "[\"7608721729807187.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "63057911729807186.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Atun Margarita 170 G Aceite",
    //         "oferta": "",
    //         "precio": 2.37,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "atun-margarita-170-g-aceite",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OC3RP4n3YhsWkt7PE6_": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-19T12:41:52.945Z",
    //         "galeria": "[\"5527731732020116.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "91435231732020112.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Maíz Dulce Nutritec 340g",
    //         "oferta": "[\"Fixed\",1.63,\"2025-01-31\"]",
    //         "precio": 1.793,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "maiz-dulce-nutritec-340g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQwTpwQtk2g5WVcVvdJ": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "descripcion": "<br>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-13T16:32:21.093Z",
    //         "galeria": "[\"46218031721254803.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "22113891721254802.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Oferta Hueso Rojo X 2kg",
    //         "oferta": "[\"Fixed\",2,\"2025-12-31\"]",
    //         "precio": 2.14,
    //         "prox": false,
    //         "sales": 1,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "oferta-hueso-rojo-x-2kg",
    //         "video": "[]",
    //         "views": 47
    //     },
    //     "-MlHH6BO-_TF9KJpoBRF": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "Chees Tris 54g",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-10-05T21:07:23.424Z",
    //         "galeria": "[\"33262191633468051.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "6199751633468043.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Chees Tris 54g",
    //         "oferta": "",
    //         "precio": 0.98,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "chees-tris-54g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mi7ROnUBX-rrBOyj9Zr": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "Garbanzo Pantera 500g",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-28T22:32:22.101Z",
    //         "galeria": "[\"5105681630081751.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "83295811630081745.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Garbanzo Pantera 500g ",
    //         "oferta": "[\"Fixed\",2.54,\"2025-12-31\"]",
    //         "precio": 2.7178,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "garbanzo-pantera-500g-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQ30NvKWoDgUjTHhX_G": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-aue-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Suavizante bonaropa 1L</h5>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Aroma\":[\"Manzana\",\"Floral\"]}]",
    //         "estado": true,
    //         "fecha_create": "2025-01-16T00:33:01.251Z",
    //         "galeria": "[\"73585371609606466.jpg\",\"98298671668330489.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "25407201720449522.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Suavizante bonaropa 1L",
    //         "oferta": "[\"Fixed\",1.57,\"2026-12-31\"]",
    //         "precio": 1.6799,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "suavizante-bonaropa-1l",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MR_UoeG4_1GFdN0Qjqm": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "descripcion": "<p><b>Mora (Solo producto congelado)</b></p><ul><li><b>1kg</b></li></ul>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Kg \":[{\"500gr\":0.8,\"1 kg\":1.6}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-01-15T20:20:14.582Z",
    //         "galeria": "[\"5501911611241834.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "17683091611241832.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Mora congelada",
    //         "oferta": "[\"Disccount\",10,\"2025-02-28\"]",
    //         "precio": 1.6,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "mora-congelada",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OAtpc51ui3gIls_XLGF": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-05T01:00:37.009Z",
    //         "galeria": "[\"2246461730768437.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "48416981730768436.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Aceituna Rrellena Eureka 500g",
    //         "oferta": "",
    //         "precio": 5.21,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 50,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "aceituna-rrellena-eureka-500g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OIau7xajdH5WJcS0Tva": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T18:53:01.879Z",
    //         "galeria": "[\"50095031739040787.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "2434201739040781.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Cereal Fiocco Aritos Frutales 230g",
    //         "oferta": "[\"Fixed\",1.76,\"2025-12-31\"]",
    //         "precio": 1.8832,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cereal-fiocco-aritos-frutales-230g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPzZCx9LQlWCef9OXgE": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "descripcion": "<b>Corazón de Res</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Corazón en bisteck \":[{\"500g\":1.7869,\"1kg\":3.5738}]}]",
    //         "estado": true,
    //         "fecha_create": "2021-01-01T20:08:23.179Z",
    //         "galeria": "[\"52421609531709.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "9113641609531702.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Corazón de Res",
    //         "oferta": "[\"Fixed\",1.67,\"2025-12-31\"]",
    //         "precio": 1.7869,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "corazon-de-res",
    //         "video": "[]",
    //         "views": 11
    //     },
    //     "-OASkRmOqyrVUSOYM88o": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-30T14:08:37.509Z",
    //         "galeria": "[\"2455961730297318.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "25026221730297316.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Aceituna Osole Rellena 340g",
    //         "oferta": "",
    //         "precio": 2.75,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 50,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "aceituna-osole-rellena-340g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N6-Y5iWI5zhwmxbtaoM": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-07-02T20:13:49.862Z",
    //         "galeria": "[\"10542281656792832.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "13006151656792823.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Sal Bahía Bahia Refinada 1kg",
    //         "oferta": "",
    //         "precio": 1.4,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "sal-bahia-bahia-refinada-1kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OIb20H9tKnYA5aPWpuK": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T19:31:53.750Z",
    //         "galeria": "[\"4684711739043115.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "50776801739043113.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Nuthos Mani Dulce ",
    //         "oferta": "[\"Fixed\",1.65,\"2025-12-31\"]",
    //         "precio": 1.7655,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "nuthos-mani-dulce-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O9B1PbhHbyo3Zto3ab9": {
    //         "categoria": "bebidas-y-lacteos",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-14T17:18:14.939Z",
    //         "galeria": "[\"8692801728926295.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "95594801728926294.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Pepsi 1.5 ltr",
    //         "oferta": "[\"Fixed\",1.5,\"2026-12-31\"]",
    //         "precio": 1.605,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pepsi-1-5-ltr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Nw5C9ORoDwO0aVefoZC": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-04-22T14:27:43.609Z",
    //         "galeria": "[\"72538781713796064.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "19863681713796062.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Granola",
    //         "oferta": "",
    //         "precio": 3.77,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "granola",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NNRf2FLPOHEsZrt4Wpi": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-02-28T20:25:47.178Z",
    //         "galeria": "[\"67428571675518292.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "63474681675518291.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Lenteja  Pantera 900gr",
    //         "oferta": "[\"Fixed\",4.23,\"2025-01-31\"]",
    //         "precio": 4.5261,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "lenteja--pantera-900gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQ9DTxp8SeddVX0AHOz": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-yha-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Desodorante rexona clínica 35g</h5>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Fragancia \":[\"Dama\",\"Caballero\"]}]",
    //         "estado": true,
    //         "fecha_create": "2025-02-28T20:34:54.961Z",
    //         "galeria": "[\"85959221740776431.jpg\",\"94684531740776434.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "40052111740776430.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Desodorante rexona clínical 30g",
    //         "oferta": "[\"Fixed\",1.35,\"2025-12-31\"]",
    //         "precio": 1.445,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "desodorante-rexona-clinical-35g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPtO3yJrZPfG9qcxNhj": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "descripcion": "<b>Carne de Lagarto</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"500gr picado para sopa\":3.852,\"1kg picado para sopa\":7.704,\"500gr molido\":3.852,\"1kg molido\":7.704}]}]",
    //         "estado": true,
    //         "fecha_create": "2023-05-23T20:30:40.668Z",
    //         "galeria": "[\"94537871609428126.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "2971451609428124.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Carne Lagarto",
    //         "oferta": "[\"Fixed\",3.6,\"2025-12-31\"]",
    //         "precio": 3.852,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "carne-lagarto",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MR_WkoMlg0lFHDl3BEr": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "descripcion": "<b>Repollo por unidad</b>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-01-15T20:20:14.582Z",
    //         "galeria": "[\"1507701611242343.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "62969481611242339.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Repollo",
    //         "oferta": "[\"Disccount\",10,\"2025-02-28\"]",
    //         "precio": 1.5,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "repollo",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NF_qxYap89p0RlzS7kL": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-10-29T22:27:46.137Z",
    //         "galeria": "[\"74495771667082468.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "64082841667082467.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Salchichas Salchipollo 450g",
    //         "oferta": "[\"Fixed\",4.46,\"2025-12-31\"]",
    //         "precio": 4.7722,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salchichas-salchipollo-450g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OIzU81SwrFqKLKi6ClK": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-13T13:25:37.444Z",
    //         "galeria": "[\"76162421739453138.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "87472241739453138.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Çrema Crest Kids 130 g",
    //         "oferta": "",
    //         "precio": 1.57,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "çrema-crest-kids-130-g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQKgHBdJmN5i-_h_F-0": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Salsa Bolognesa Heinz</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-06T03:15:21.005Z",
    //         "galeria": "[\"80934451609902923.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "43999271609902920.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Salsa Bolognesa Heinz 495g",
    //         "oferta": "",
    //         "precio": 4.28,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salsa-bolognesa-heinz-495g",
    //         "video": "[]",
    //         "views": 3
    //     },
    //     "-MPtngFvjZny9Vzw3PuK": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-lgt-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Cheese Tris 150g</h5><div><br></div>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2020-12-31T17:18:09.013Z",
    //         "galeria": "[\"84653881609435103.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "8451761609435088.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Cheese Tris 150g",
    //         "oferta": "",
    //         "precio": 2.206,
    //         "prox": false,
    //         "sales": 3,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cheese-tris-150g",
    //         "video": "[]",
    //         "views": 14
    //     },
    //     "-MtJ-Ad13Kst2GqOVg38": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2023-04-05T10:23:20.871Z",
    //         "galeria": "[\"33781501642086838.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "94073771642086818.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Detergente Líquido Bonaropa baby 1l",
    //         "oferta": "[\"Fixed\",3.11,\"2025-12-31\"]",
    //         "precio": 3.3277,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "detergente-liquido-bonaropa-baby-1l",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQK1ZEHEu1vQRAoxnrg": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">G Maíz Para Cotufa La Redoma 500gr</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-06-11T09:31:11.869Z",
    //         "galeria": "[\"4123381616372083.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "46704471616372073.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "G Maíz Para Cotufa La Redoma 500gr",
    //         "oferta": "",
    //         "precio": 1.5,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "g-maiz-para-cotufa-la-redoma-500gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N9M_oJSIw56XS07InZG": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-11T14:09:01.993Z",
    //         "galeria": "[\"95995821660400632.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "59985751660400628.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Adobo Maggi 200g Sazonatodo",
    //         "oferta": "",
    //         "precio": 4.235,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "adobo-maggi-200g-sazonatodo",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Moo084EeiEVNPotjlMb": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-11-18T17:07:32.887Z",
    //         "galeria": "[\"40325701637255253.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "65498141637255252.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Cloro calidex litro aroma natural",
    //         "oferta": "",
    //         "precio": 1.21,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cloro-calidex-litro-aroma-natural",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MSFApNKL-uzecHLOgke": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "<b>Jabón las llaves en barra</b>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-29T22:06:51.318Z",
    //         "galeria": "[\"56524881611958014.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "24422511611958009.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabón las llaves en barra",
    //         "oferta": "",
    //         "precio": 1.03,
    //         "prox": false,
    //         "sales": 1,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-las-llaves-en-barra",
    //         "video": "[]",
    //         "views": 6
    //     },
    //     "-OIe-eDN9uZJ-sSqUw7Z": {
    //         "categoria": "bebidas-y-lacteos",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Sabor\":[\"Pera\",\"Manzana\",\"Durazno\"]}]",
    //         "estado": true,
    //         "fecha_create": "2025-02-09T09:20:27.640Z",
    //         "galeria": "[\"17728441739092828.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "4444241739092827.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Jugo Tunal Tripak ",
    //         "oferta": "[\"Fixed\",2.7,\"2025-12-31\"]",
    //         "precio": 2.889,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jugo-tunal-tripak-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NNBOPcTA2OSTxhtABz_": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-07-31T20:45:36.618Z",
    //         "galeria": "[\"31837401675245233.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "67621931675245229.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Aceite La Lucha De Soya 900ml",
    //         "oferta": "[\"Fixed\",3.02,\"2025-01-31\"]",
    //         "precio": 3.2314,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "aceite-la-lucha-de-soya-900ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPzSEMUfk21VNcCN9Os": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-mqj-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Galleta oreo</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-02-12T00:06:52.382Z",
    //         "galeria": "[\"63705151609529880.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "90287321609529877.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Paquete Galleta Oreo Original  432g",
    //         "oferta": "",
    //         "precio": 5.4,
    //         "prox": false,
    //         "sales": 1,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "paquete-galleta-oreo-original--432g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N_ZdW77zHF_XUuWcqv1": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Sabor\":[\"Durazno\"]}]",
    //         "estado": true,
    //         "fecha_create": "2023-07-17T16:19:07.103Z",
    //         "galeria": "[\"72391991689610751.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "12226391689610746.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Suntea 20g",
    //         "oferta": "[\"Fixed\",0.45,\"2025-01-31\"]",
    //         "precio": 0.4815,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "suntea-20g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MpvPF2TgO0Z1vEy04nv": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "descripcion": "Carne Para Hallaca.",
    //         "detalles": "",
    //         "especificaciones": "[{\"Carne Para Hallaca.\":[{\"500 gr\":4173,\"1kg\":8.346}]}]",
    //         "estado": true,
    //         "fecha_create": "2021-12-02T13:59:00.131Z",
    //         "galeria": "[\"41768911638453017.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "97528361638453016.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Carne Para hallaca.",
    //         "oferta": "[\"Fixed\",3.9,\"2025-12-31\"]",
    //         "precio": 4.173,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "carne-para-hallaca-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPoS_4ESCOuS_hbRmci": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "<b>Jamón Ahumado</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"250gr\":3.0495,\"500gr\":6.099}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-07-09T14:00:25.460Z",
    //         "galeria": "[\"17415841714963347.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "59639731714963346.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Jamón Ahumado ",
    //         "oferta": "[\"Fixed\",2.85,\"2025-12-31\"]",
    //         "precio": 3.0495,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jamon-ahumado-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NtWLKJATBDQ-qTp0Mki": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-04-20T19:16:29.118Z",
    //         "galeria": "[\"29504041711030227.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "86966811711030226.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Brisol Multi Acción 825ml",
    //         "oferta": "",
    //         "precio": 7.21,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 3,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "brisol-multi-accion-825ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MwT5w8XefAxtQIxfDNJ": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-02-15T21:05:00.155Z",
    //         "galeria": "[\"22694601645477608.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "3725721645477593.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Syrup Para Pancakes 355ml",
    //         "oferta": "[\"Fixed\",2.37,\"2025-01-31\"]",
    //         "precio": 2.5359,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "syrup-para-pancakes-355ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OKmIE_SIux8fCbj763x": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-03-07T20:29:58.565Z",
    //         "galeria": "[\"9354421741379401.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "77226011741379399.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Champú Sedal Rizoz",
    //         "oferta": "",
    //         "precio": 4.71,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "champu-sedal-rizoz",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MY8eVk1y6EuizA2TfWs": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "Jabón En Polvo Bonaropa 2800g",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-01-16T00:33:01.251Z",
    //         "galeria": "[\"3246431739045481.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "2542251739045462.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabón En Polvo Bonaropa Con Bicarbonato 2.800kg",
    //         "oferta": "[\"Fixed\",3.9,\"2025-12-31\"]",
    //         "precio": 4.173,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-en-polvo-bonaropa-con-bicarbonato-2-800kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPtC809ZXWUvD8XXyy-": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "descripcion": "<b>Chorizos Ahumados</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Paquete 450gr\":[\"Ajo\",\"Surtido\",\"Chorijamon\",\"Ahumados\"]}]",
    //         "estado": true,
    //         "fecha_create": "2024-07-09T13:46:25.236Z",
    //         "galeria": "[\"56104871720533301.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "46766021720533272.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Chorizos",
    //         "oferta": "[\"Fixed\",4,\"2025-12-31\"]",
    //         "precio": 4.28,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "chorizos",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPkKjJAWd3PWzIiuyBx": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "<ol><li><span style=\"font-weight: bolder;\">2Kg Bistec</span></li><li><span style=\"font-weight: bolder;\">2Kg Mechar o Guisar</span></li><li><span style=\"font-weight: bolder;\">2Kg Molida</span></li><li><span style=\"font-weight: bolder;\">2Kg Muslo de Pollo</span></li><li><span style=\"font-weight: bolder;\">2Kg Pechuga de Pollo</span></li><li><span style=\"font-weight: bolder;\">2Kg Alas o Picadillo de Pollo</span></li><li><span style=\"font-weight: bolder;\">2kg Chuleta o Costilla de Cerdo</span></li><li><span style=\"font-weight: bolder;\">1Kg Costilla de Res</span></li><li><span style=\"font-weight: bolder;\">1/2 Queso tipo paisa</span></li><li><span style=\"font-weight: bolder;\">1/2 Queso duro zuliano</span></li><li><span style=\"font-weight: bolder;\">1/2 Queso mozarella</span></li><li><span style=\"font-weight: bolder;\">1/2 Jamón de pierna Arichina</span></li><li><span style=\"font-weight: bolder;\">1/2 Boloña de Pollo Corral</span></li><li><span style=\"font-weight: bolder;\">1 Paquete De Chorizos</span></li><li><span style=\"font-weight: bolder;\">1 Crema de leche 500gr</span></li><li><span style=\"font-weight: bolder;\">1 Carton de huevos</span></li><li><span style=\"font-weight: bolder;\">1 Paquete de Salchichas 450gr</span></li></ol>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2020-12-29T21:43:29.766Z",
    //         "galeria": "[\"9893981640823114.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "1545551640823109.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo Mercafruver Full",
    //         "oferta": "[\"Fixed\",130,\"2025-12-31\"]",
    //         "precio": 139.1,
    //         "prox": false,
    //         "sales": 3,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-mercafruver-full",
    //         "video": "[]",
    //         "views": 323
    //     },
    //     "-Nabjfdl1gKrcIFXH1dW": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2023-07-30T17:00:38.878Z",
    //         "galeria": "[\"29171871690736441.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "13751511690736440.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Mayonesa Natu Campo 400g",
    //         "oferta": "[\"Fixed\",3.36,\"2025-01-31\"]",
    //         "precio": 3.5952,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "mayonesa-natu-campo-400g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O-7pClFcLvn3xoY-I9L": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-06-20T16:06:47.543Z",
    //         "galeria": "[\"29152621718135085.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "5529621718135084.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Saltitacos Tomy 7 Tacos ",
    //         "oferta": "[\"Fixed\",2.46,\"2025-01-31\"]",
    //         "precio": 2.6322,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "saltitacos-tomy-7-tacos-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NDDRwzaZCiMgPaC47PH": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2023-05-09T12:49:22.268Z",
    //         "galeria": "[\"77529021664542297.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "17679701664542289.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Pasta Sindoni Larga 1kg",
    //         "oferta": "[\"Fixed\",1.45,\"2025-01-31\"]",
    //         "precio": 1.5515,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1000,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pasta-sindoni-larga-1kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NQBpV0_BUC8OPwH4FYC": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-03-10T18:43:39.800Z",
    //         "galeria": "[\"56907241678473821.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "32315831678473819.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Sedal dos en 1",
    //         "oferta": "",
    //         "precio": 4.71,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "sedal-dos-en-1",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O5JBxfb2wFW61Lz8hkR": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-08-27T14:18:31.463Z",
    //         "galeria": "[\"8025651724768311.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "70355831724768310.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Lavaplatos Brillaves Queen 500g",
    //         "oferta": "",
    //         "precio": 1.18,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "lavaplatos-brillaves-queen-500g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQKObrcqMxBQfS7kNYX": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Pasta De Tomate Heinz Dobleconcentrado</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-06T01:53:50.587Z",
    //         "galeria": "[\"71708521609898031.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "32875411609898030.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Pasta De Tomate Heinz Dobleconcentrado190gr",
    //         "oferta": "",
    //         "precio": 3,
    //         "prox": false,
    //         "sales": 1,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pasta-de-tomate-heinz-dobleconcentrado190gr",
    //         "video": "[]",
    //         "views": 7
    //     },
    //     "-Mb3c9Nl02kR0x4CjGiB": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "887",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-04-03T12:24:45.086Z",
    //         "galeria": "[\"6402381622501532.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "19547571622501530.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Salsa Soya Heinz 300gr",
    //         "oferta": "",
    //         "precio": 3.92,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salsa-soya-heinz-300gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQK-sFSa9TL_yd-89pp": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">G Caraotas La Gran Redoma 500g</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-04-13T19:17:52.443Z",
    //         "galeria": "[\"32058861609891545.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "93858051609891543.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "G Caraotas La Gran Redoma 500g",
    //         "oferta": "",
    //         "precio": 1.47,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "g-caraotas-la-gran-redoma-500g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OGeUzaJcJUFzNCuLX-6": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-01-15T15:05:21.235Z",
    //         "galeria": "[\"92502641736953557.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "4984471736953520.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Cabello De Angel Caprissima 250gr",
    //         "oferta": "[\"Fixed\",0.6,\"2025-12-31\"]",
    //         "precio": 0.642,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cabello-de-angel-caprissima-250gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mb8cF6BXvhm26cgd0Cw": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-06-01T22:10:32.408Z",
    //         "galeria": "[\"98601341622585441.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "39159081622585433.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Alcohol 500ml",
    //         "oferta": "",
    //         "precio": 2.45,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "alcohol-500ml",
    //         "video": "[]",
    //         "views": 6
    //     },
    //     "-NBwnR7oNISARepA8c-Z": {
    //         "categoria": "pan",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-01-27T23:01:40.738Z",
    //         "galeria": "[\"26487101663172460.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "5131041663172459.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Papas Fritas Kythos 200g",
    //         "oferta": "[\"Fixed\",1.25,\"2024-12-31\"]",
    //         "precio": 1.3375,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "papas-fritas-kythos-200g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NY9gJbZ8jPk4Q1HBvK1": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-06-17T18:49:54.099Z",
    //         "galeria": "[\"32475021687027794.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "88653751687027793.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Ariel 850g",
    //         "oferta": "",
    //         "precio": 4.19,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "ariel-850g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NxsaZAF3p0wwLV2Id6G": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-05-14T19:35:25.883Z",
    //         "galeria": "[\"15586591715715326.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "72362091715715325.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Cafe Gurmet Osole 200g",
    //         "oferta": "",
    //         "precio": 2.12,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 25,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cafe-gurmet-osole-200g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NfmEf4kUEJk-DYf6Qg2": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-10-02T21:15:08.249Z",
    //         "galeria": "[\"62974391696281309.jpg\",\"57651331696281497.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "6750571696281308.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Sorbetico De Fresa Y Vainilla 100g",
    //         "oferta": "",
    //         "precio": 1.58,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "sorbetico-de-fresa-y-vainilla-100g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Nsd0IqBEAwnlzbvvSUm": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-03-10T15:39:49.853Z",
    //         "galeria": "[\"3106001710085190.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "38669301710085188.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Cepillo Palmareal De Pocets",
    //         "oferta": "",
    //         "precio": 3.29,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cepillo-palmareal-de-pocets",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MkCdxXGYBJONLzDzOpy": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "Arequipe Latti 250g&nbsp;",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2023-10-24T10:33:31.335Z",
    //         "galeria": "[\"97782821632316675.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "25653491632316671.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Arequipe Latti 250g ",
    //         "oferta": "[\"Fixed\",1.58,\"2023-12-31\"]",
    //         "precio": 1.6906,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "arequipe-latti-250g-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OBgRHY2Zg0TtG1hEEkw": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-14T20:50:32.342Z",
    //         "galeria": "[\"93606711731617433.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "70549571731617432.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Margarina Chifon 500g",
    //         "oferta": "",
    //         "precio": 2.65,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "margarina-chifon-500g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NrfPUX9bhntf_r5icv9": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-02-27T16:33:25.579Z",
    //         "galeria": "[\"49423571709051606.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "14458921709051606.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabón En Polvo Abc 800gr",
    //         "oferta": "[\"Fixed\",2.45,\"2024-12-31\"]",
    //         "precio": 2.6215,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-en-polvo-abc-800gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MR_QH8_EcxLzVTtPNpn": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"500 gr\":1.65,\"1kg\":3.3}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-01-15T20:20:14.582Z",
    //         "galeria": "[\"2585551611240644.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "51572561611240642.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Ají dulce",
    //         "oferta": "[\"Disccount\",20,\"2025-02-28\"]",
    //         "precio": 3.3,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "aji-dulce",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPkLq64GyGXpIlWrH42": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "<ol><li><b>1Kg Bistec</b></li><li><b>1Kg Guisar&nbsp;</b></li><li><b>1Kg Mechar</b></li><li><b>1Kg molida</b></li><li><b>1Kg Costilla de Res</b></li><li><b>1/2 Huevos</b></li></ol>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2020-12-29T21:48:09.579Z",
    //         "galeria": "[\"2153441720175112.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "865771720175111.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo Carne y Huevos",
    //         "oferta": "[\"Fixed\",33,\"2025-12-31\"]",
    //         "precio": 35.31,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-carne-y-huevos",
    //         "video": "[]",
    //         "views": 103
    //     },
    //     "-OJtbg4IUtBMBDz3lJIB": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-24T20:20:56.833Z",
    //         "galeria": "[\"4615871740428460.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "74618811740428458.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Harina Pan De 2kg",
    //         "oferta": "",
    //         "precio": 2.3,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 20,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "harina-pan-de-2kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQDvwzbpXxcc0MxEsvz": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-vnw-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Toallas sanitarias delgadas malla red 12 und Fresh &amp; Free</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T16:35:50.256Z",
    //         "galeria": "[\"23269251613253778.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "44112191613253775.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Toallas sanitarias delgadas malla red 12 und Fresh & Free",
    //         "oferta": "[\"Fixed\",1.08,\"2025-12-31\"]",
    //         "precio": 1.1556,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "toallas-sanitarias-delgadas-malla-red-12-und-fresh-y-free",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPt9yupjYE5eYl-qB1F": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "descripcion": "<b>Ganzo</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"500gr\":4.173,\"1kg\":8.346}]}]",
    //         "estado": true,
    //         "fecha_create": "2020-12-31T14:20:32.154Z",
    //         "galeria": "[\"3584361609424432.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "72524901609424431.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Ganso",
    //         "oferta": "[\"Fixed\",3.9,\"2025-12-31\"]",
    //         "precio": 4.173,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "ganso",
    //         "video": "[]",
    //         "views": 19
    //     },
    //     "-OC3QfgWAFTLMGFmWwEu": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-19T12:38:42.435Z",
    //         "galeria": "[\"98718081732019926.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "69853401732019921.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Pasticho Sindoni ",
    //         "oferta": "[\"Fixed\",1.6,\"2025-01-31\"]",
    //         "precio": 1.76,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pasticho-sindoni-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQFwlVw9PYbuGApTD9h": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h4 _ngcontent-nhs-c32=\"\" style=\"margin-bottom: 0.5rem; font-size: 1.5rem; color: rgb(255, 255, 255); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Maizorito Azucarado</h4>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-05T05:09:18.455Z",
    //         "galeria": "[\"97478151609823359.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "1766081609823358.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Maizorito Azucarado",
    //         "oferta": "",
    //         "precio": 3.13,
    //         "prox": false,
    //         "sales": 2,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "maizorito-azucarado",
    //         "video": "[]",
    //         "views": 8
    //     },
    //     "-MY7xkSkXr55XSxbOnSZ": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "Salsa de tomate bassi 500g",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-12-01T22:04:19.235Z",
    //         "galeria": "[\"58717821618279334.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "60519831618279326.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Salsa De Tomate Bassi 500g",
    //         "oferta": "[\"Fixed\",1.77,\"2025-01-31\"]",
    //         "precio": 1.8939,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salsa-de-tomate-bassi-500g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQ2yuT_Q7vJBNUI4gPo": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-aue-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Lavaplatos crema axion 500gr</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-02T16:43:30.072Z",
    //         "galeria": "[\"11173641609605817.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "75021011609605808.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Lavaplatos Crema Axion 450g",
    //         "oferta": "",
    //         "precio": 4.73,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "lavaplatos-crema-axion-450g",
    //         "video": "[]",
    //         "views": 5
    //     },
    //     "-OHWxNgFCbpTXHHms8s-": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-01-26T09:33:29.345Z",
    //         "galeria": "[\"83341651737884011.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "26194351737884010.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Cloro Lavan San 1 Litro",
    //         "oferta": "",
    //         "precio": 1.4,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cloro-lavan-san-1-litro",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N4rg3j2o3MY1Ygsat9q": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-06-18T16:40:46.170Z",
    //         "galeria": "[\"79825581655570450.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "80391581655570446.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Salsa Bbq Frizz 290gr",
    //         "oferta": "",
    //         "precio": 2.5,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salsa-bbq-frizz-290gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mm1DqdaVLniKBza8LxZ": {
    //         "categoria": "cerdo",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Lomo de cerdo \":[{\"Entero 500gr\":4.1195,\"En Bistec 500gr\":4.1195,\"Entero 1kg \":8.239,\"En Bistec 1kg\":8.239}]}]",
    //         "estado": true,
    //         "fecha_create": "2021-10-15T04:35:00.901Z",
    //         "galeria": "[\"98307531634272502.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "34774501634272502.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Lomo De Cerdo ",
    //         "oferta": "[\"Fixed\",3.85,\"2025-12-31\"]",
    //         "precio": 4.1195,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "lomo-de-cerdo-",
    //         "video": "[]",
    //         "views": 3
    //     },
    //     "-OIaPjwdvAUv_DSFA4y4": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T16:35:50.256Z",
    //         "galeria": "[\"38462801739032558.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "90405261739032549.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Protectores Diarios Nosotras X 15 Unidades ",
    //         "oferta": "[\"Fixed\",0.78,\"2025-12-31\"]",
    //         "precio": 0.8346,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "protectores-diarios-nosotras-x-15-unidades-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N27C374o4EJeQ0XLif1": {
    //         "categoria": "bebidas-y-lacteos",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-05-15T14:51:46.804Z",
    //         "galeria": "[\"16506861652626308.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "54111971652626307.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "7up 2lt",
    //         "oferta": "[\"Fixed\",1.76,\"2025-12-31\"]",
    //         "precio": 1.8832,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "7up-2lt",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MoOof8nSw5VyzpDu5aO": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "<ol><li><b>5 kg de Harina Pan&nbsp;</b></li><li><b>5 kg de Arroz Mary Tradicional&nbsp;</b></li><li><b>4 kg de pasta Capri Tradicional&nbsp;</b></li><li><b>3 kg de Grano Variano</b></li><li><b>3 kg de Azucar&nbsp;</b></li><li><b>&nbsp;1 lt de aceite coposa o naturoil&nbsp;</b></li><li><b>1 kg de café aroma&nbsp;</b></li><li><b>3 paquetes de galleta María&nbsp;</b></li><li><b>2kg de Bisteck&nbsp;</b></li><li><b>2kg de Mechar&nbsp;</b></li><li><b>2kg de Molida&nbsp;</b></li><li><b>2kg de pechuga Entera arreglada en filet&nbsp;</b></li><li><b>2kg de muslo&nbsp;</b></li><li><b>1kg de mortadela corral rebanada&nbsp;</b></li><li><b>1kg de queso duro</b></li></ol>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-11-13T15:02:55.736Z",
    //         "galeria": "[\"82144931640823426.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "11758121640823421.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo Mercafruver Extra ",
    //         "oferta": "[\"Fixed\",127,\"2025-12-31\"]",
    //         "precio": 135.89,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-mercafruver-extra-",
    //         "video": "[]",
    //         "views": 23
    //     },
    //     "-MQPwkXrPYEt94KQOtnb": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "<b>Chocolate Savoy Mediano 70g</b>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-03-22T17:36:59.225Z",
    //         "galeria": "[\"80871051609991128.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "13449341609991125.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Chocolate Savoy Mediano 70g",
    //         "oferta": "",
    //         "precio": 2.27,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 9,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "chocolate-savoy-mediano-70g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NOE3QXNRSb4JNGU1FHX": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-02-14T08:36:46.074Z",
    //         "galeria": "[\"57861301676363806.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "708371676363805.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Crema Sedal Ceramidas 300ml",
    //         "oferta": "",
    //         "precio": 4.39,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 3,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "crema-sedal-ceramidas-300ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NVABORSHYtzBBazOp7Y": {
    //         "categoria": "pan",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-05-11T14:22:47.710Z",
    //         "galeria": "[\"30702131683814968.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "9234241683814967.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Pan Árabe rey X 6 Und",
    //         "oferta": "[\"Fixed\",2.4,\"2025-01-31\"]",
    //         "precio": 2.568,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pan-arabe-rey-x-6-und",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQJrprzTmct1kPQwRdF": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Crema De Leche Nestle 295g</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-05T23:26:04.708Z",
    //         "galeria": "[\"57474681609889174.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "86561651609889161.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Crema De Leche Nestle 295g",
    //         "oferta": "",
    //         "precio": 3.935,
    //         "prox": false,
    //         "sales": 2,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "crema-de-leche-nestle-295g",
    //         "video": "[]",
    //         "views": 12
    //     },
    //     "-NqdIyEf2XZ8UoRvxkKL": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-02-14T20:30:00.752Z",
    //         "galeria": "[\"2180831707942601.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "2872941707942600.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Arroz Gran Marquez Premiun 1kg",
    //         "oferta": "",
    //         "precio": 1.36,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "arroz-gran-marquez-premiun-1kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MwT6TUPm5r6uIrN9UEd": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-01-16T00:33:01.251Z",
    //         "galeria": "[\"89414871645477749.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "99249611645477737.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Servilleta Doble Rendy 200 unid",
    //         "oferta": "[\"Fixed\",1.68,\"2025-12-31\"]",
    //         "precio": 1.7976,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "servilleta-doble-rendy-200-unid",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MZcD6u6IxtxfbKAt4Z2": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "Crema De Peinar Sedal&nbsp;",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-02-14T01:30:14.657Z",
    //         "galeria": "[\"98992271619877458.jpg\",\"16448451639580447.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "25702761619877457.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Crema Peinar Sedal Con Keratina",
    //         "oferta": "",
    //         "precio": 4.39,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "crema-peinar-sedal-con-keratina",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPzTEuDs_0_UEChAsAk": {
    //         "categoria": "cerdo",
    //         "costo": "",
    //         "descripcion": "<b>Costilla de Cerdo</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"500gr\":3.18325,\"1kg\":6.3665}]}]",
    //         "estado": false,
    //         "fecha_create": "2024-07-09T14:00:25.460Z",
    //         "galeria": "[\"32363551609530145.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "7208011609530141.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Costilla de Cerdo",
    //         "oferta": "[\"Fixed\",2.975,\"2025-12-31\"]",
    //         "precio": 3.18325,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "costilla-de-cerdo",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MgItoTNke8TTPaHKJeA": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-09-19T15:32:22.604Z",
    //         "galeria": "[\"87633131628126528.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "19287741628126526.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Aromatizado Calidez Surtido 1lit",
    //         "oferta": "",
    //         "precio": 1.53,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "aromatizado-calidez-surtido-1lit",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MRSVozp2-BQppqM64U3": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<b>Rikesa 300gr</b>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-06-14T20:49:34.173Z",
    //         "galeria": "[\"73069471611107880.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "25449001611107877.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Rikesa 300gr",
    //         "oferta": "",
    //         "precio": 4.69,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "rikesa-300gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OC3fe1TPI7c6VeoAU5z": {
    //         "categoria": "licores",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-19T13:48:34.242Z",
    //         "galeria": "[\"66865091732024114.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "74597211732024114.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Sangría Caroreña",
    //         "oferta": "[\"Fixed\",7.55,\"2025-12-31\"]",
    //         "precio": 8.305,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "sangria-carorena",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Nsd3Eo94-qfcv9jH0nL": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-03-10T15:52:41.027Z",
    //         "galeria": "[\"88318271710085962.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "53785431710085960.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Arroz Primor Tradicional",
    //         "oferta": "",
    //         "precio": 1.62,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "arroz-primor-tradicional",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MY8cDCE8wppdZqTpvdn": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "Crema De Leche Tachira Pequeña&nbsp;",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-09-11T21:14:42.739Z",
    //         "galeria": "[\"55159701618290466.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "5555561618290463.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Crema De Leche Tachira pequeña ",
    //         "oferta": "[\"Fixed\",4.99,\"2025-12-31\"]",
    //         "precio": 5.3393,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "crema-de-leche-tachira-pequena-",
    //         "video": "[]",
    //         "views": 4
    //     },
    //     "-MhFgirzkjvDLizzgDyu": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-08-16T20:41:47.409Z",
    //         "galeria": "[\"11381491629146507.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "35302751629146506.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Atun  Lomo En Aceite Dimari 160g",
    //         "oferta": "",
    //         "precio": 1.83,
    //         "prox": false,
    //         "sales": 1,
    //         "stock": 48,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "atun--lomo-en-aceite-dimari-160g",
    //         "video": "[]",
    //         "views": 7
    //     },
    //     "-MikkgxRQ50xaoVBudyh": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-10-05T13:46:29.835Z",
    //         "galeria": "[\"18669031630758149.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "460771630758148.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Galleta marilu fresa",
    //         "oferta": "",
    //         "precio": 1.88,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "galleta-marilu-fresa",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Nei3YPQ0kaJ6V0eauMa": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-09-19T15:32:22.604Z",
    //         "galeria": "[\"43055451695137543.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "69528361695137541.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Desinfectante Kikex 1 Litro Surtisurtidos",
    //         "oferta": "",
    //         "precio": 1.48,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "desinfectante-kikex-1-litro-surtisurtidos",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NFv3WrjdhZjxHuchoYU": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-11-03T01:19:11.807Z",
    //         "galeria": "[\"97262491667753405.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "5150261667753404.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Osole Maiz Dulce 280 g",
    //         "oferta": "",
    //         "precio": 1.6,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 48,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "osole-maiz-dulce-280-g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MSmpZYMYWe5Tycz9hL4": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "descripcion": "<b>Calabacín por kg&nbsp;</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Cantidad \":[{\"1kg\":1.7}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-01-15T20:20:14.582Z",
    //         "galeria": "[\"89363791612539381.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "12722921612539377.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Calabacín ",
    //         "oferta": "[\"Disccount\",10,\"2025-02-28\"]",
    //         "precio": 1.7,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "calabacin-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mi7U88cTbQrrXRJwG2P": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "Caraota Roja Pantera 500g",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-07-20T20:03:07.626Z",
    //         "galeria": "[\"87126751630082469.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "79612331630082463.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Caraota Roja Pantera 500g",
    //         "oferta": "[\"Fixed\",2.06,\"2025-01-31\"]",
    //         "precio": 2.2042,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "caraota-roja-pantera-500g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Nabf6Y8Y27x7uNYYlcQ": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-07-30T16:40:41.835Z",
    //         "galeria": "[\"12321431690735244.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "85939471690735243.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Crema Dental Fluo Carden 50g",
    //         "oferta": "[\"Fixed\",1.25,\"2025-12-31\"]",
    //         "precio": 1.3375,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "crema-dental-fluo-carden-50g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQDs3tzwzMpy8izWfuK": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Tipos\":[\"Limpieza renovadora\",\"2 en 1\"]}]",
    //         "estado": true,
    //         "fecha_create": "2024-09-23T13:28:05.478Z",
    //         "galeria": "[\"14053871609788574.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "73757251609788570.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Champoo Head Shoulders 375ml",
    //         "oferta": "",
    //         "precio": 6.95,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "champoo-head-shoulders-375ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NPFdYsngQJc3r79xvxp": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-10-05T13:48:08.409Z",
    //         "galeria": "[\"29583441677464057.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "59671841677464056.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabón Líquido Bonaropa Prendas Oscuras 1lt",
    //         "oferta": "[\"Fixed\",2.47,\"2025-12-31\"]",
    //         "precio": 2.6429,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-liquido-bonaropa-prendas-oscuras-1lt",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQEkK1xTeXPtDQrFsz_": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-vnw-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Azúcar 1kg</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-04T23:35:18.172Z",
    //         "galeria": "[\"5749681609803320.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "60327931609803318.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Azucar",
    //         "oferta": "",
    //         "precio": 1.65,
    //         "prox": false,
    //         "sales": 17,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "azucar",
    //         "video": "[]",
    //         "views": 171
    //     },
    //     "-NPFfTrTXu51YnzyQBdT": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2024-01-13T15:24:15.777Z",
    //         "galeria": "[\"16493221677464562.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "19637781677464561.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Bonaropa Quita Manchas Ropa Blanca 1lt",
    //         "oferta": "[\"Fixed\",1.49,\"2025-12-31\"]",
    //         "precio": 1.5943,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "bonaropa-quita-manchas-ropa-blanca-1lt",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MZK2AJuHbOVOwK3wLpH": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-04T14:42:39.458Z",
    //         "galeria": "[\"4379891619555824.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "28938021619555823.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Vanish  Gel Multiuso 450ml",
    //         "oferta": "",
    //         "precio": 2.06,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "vanish--gel-multiuso-450ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NH091QJHxLS6ZPJ_SVm": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-10-24T10:33:31.335Z",
    //         "galeria": "[\"47941741668614200.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "39095851668614197.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Cloro Blanqueador Brilla King 2l",
    //         "oferta": "[\"Fixed\",1.39,\"2025-12-31\"]",
    //         "precio": 1.4873,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cloro-blanqueador-brilla-king-2l",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPl337qdWzGbTOAB0Ph": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "<ol><li><b>1/2kg Queso Llanero</b></li><li><b>1/2kg Queso Tipo Paisa</b></li><li><b>1/2kg Queso Duro</b></li><li><b>1/2kg Jamón de Espalda Arichuna</b></li><li><b>1/2kg Boloña Corral</b></li><li><b>1 Paquete de Salchichas 450gr</b></li><li><b>1 Crema de Leche 500gr</b></li></ol>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2020-12-30T00:33:17.258Z",
    //         "galeria": "[\"10293011690539638.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "36422621690539635.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo Charcutero",
    //         "oferta": "[\"Fixed\",25,\"2026-12-31\"]",
    //         "precio": 26.75,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-charcutero",
    //         "video": "[]",
    //         "views": 119
    //     },
    //     "-MQ3-IB9uize5QgDHf1W": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-aue-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Lavaplatos crema llaves 500gr</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-02T16:49:39.669Z",
    //         "galeria": "[\"8462531609606181.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "51163161609606178.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Lavaplatos crema llaves 500gr",
    //         "oferta": "",
    //         "precio": 4.29,
    //         "prox": false,
    //         "sales": 2,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "lavaplatos-crema-llaves-500gr",
    //         "video": "[]",
    //         "views": 5
    //     },
    //     "-MR_RCoGxD6umYyVr6Hs": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"1kg\":2}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-01-15T20:20:14.582Z",
    //         "galeria": "[\"6196171611240889.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "71507431611240887.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Limón",
    //         "oferta": "[\"Disccount\",10,\"2025-02-28\"]",
    //         "precio": 2,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "limon",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQK0Wj0e8NDHWn4rjEP": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Lentejas La Gran Redoma 500g</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-04-13T19:17:52.443Z",
    //         "galeria": "[\"50916431609891715.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "13788591609891713.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "G Lentejas La Gran Redoma 500g",
    //         "oferta": "",
    //         "precio": 2,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "g-lentejas-la-gran-redoma-500g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Nd9bsJK2w_oLahlln6f": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-08-31T08:04:41.464Z",
    //         "galeria": "[\"74098741693469082.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "99315521693469080.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Caraota Roja Redoma 500g",
    //         "oferta": "",
    //         "precio": 1.57,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "caraota-roja-redoma-500g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Nvvgy2ME41bjk3zsiV_": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-04-20T13:30:53.139Z",
    //         "galeria": "[\"1928201713619853.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "94322511713619852.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Papas Pringles",
    //         "oferta": "",
    //         "precio": 1.46,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "papas-pringles",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O00C6E50OSAfezuaJMd": {
    //         "categoria": "articulos-de-belleza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-06-22T18:27:52.751Z",
    //         "galeria": "[\"97445531719080874.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "8123981719080873.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Champoo Sedal Ceraceramidas",
    //         "oferta": "",
    //         "precio": 4.71,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 3,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "champoo-sedal-ceraceramidas",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N7MI-Bba0IJ0J_bRwhZ": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Fragancia \":[\"Avena\"]}]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T16:35:50.256Z",
    //         "galeria": "[\"96238001658248233.jpg\",\"85842171673642046.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "79788161658248232.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabón De Baño Natural Feeling X3 Unidades 375g ",
    //         "oferta": "[\"Fixed\",2.1,\"2025-12-31\"]",
    //         "precio": 2.247,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-de-bano-natural-feeling-x3-unidades-375g-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NWIwE0hJNUEeadop2eh": {
    //         "categoria": "articulos-de-belleza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-05-25T17:24:32.888Z",
    //         "galeria": "[\"54933901685035478.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "54726571685035472.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Gel Every  Naygh 500g",
    //         "oferta": "",
    //         "precio": 3.33,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "gel-every--naygh-500g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQ3bPIIN__50OY8og73": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-aue-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Crema dental Colgate triple Acción 100ml</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T16:35:50.256Z",
    //         "galeria": "[\"553631609616433.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "31879851609616428.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Colgate Triple Acción 100ml",
    //         "oferta": "[\"Fixed\",2.86,\"2025-12-31\"]",
    //         "precio": 3.0602,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "colgate-triple-accion-100ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NuYunf1u_JP1aYZ_NPM": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-04-03T12:24:45.086Z",
    //         "galeria": "[\"22965881712147085.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "72134111712147085.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Salsa Napolitana Ccon Queso 490g",
    //         "oferta": "",
    //         "precio": 4.23,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 4,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salsa-napolitana-ccon-queso-490g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OBfMuz6Auwf9-PBAEeb": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-14T15:51:49.047Z",
    //         "galeria": "[\"17416741731599510.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "49593881731599509.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Flan 200g Tasty",
    //         "oferta": "",
    //         "precio": 2.76,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "flan-200g-tasty",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N9IdkrOzB5Q9eVsvNpo": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-07-31T20:45:36.618Z",
    //         "galeria": "[\"15891121660334573.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "91381101660334569.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Café Propatria 400g",
    //         "oferta": "[\"Fixed\",5.4,\"2025-01-31\"]",
    //         "precio": 5.778,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cafe-propatria-400g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQFz9HKh2x4D5rpIul9": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-nhs-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Chocolate Corona 500gr</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-07-09T14:13:55.781Z",
    //         "galeria": "[\"15118821609823986.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "29221791609823983.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Chocolate Corona 500gr",
    //         "oferta": "[\"Fixed\",5.94,\"2025-01-31\"]",
    //         "precio": 6.3558,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "chocolate-corona-500gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPt86RRkVZ4FWE2NRHv": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "<b>Salchichas Salchipollo a Granel</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"250gr\":1.712,\"500gr\":3.424}]}]",
    //         "estado": true,
    //         "fecha_create": "2023-02-10T12:53:43.215Z",
    //         "galeria": "[\"23466161609423943.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "28775131609423941.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Salchichas Salchipollo",
    //         "oferta": "[\"Fixed\",1.6,\"2025-12-31\"]",
    //         "precio": 1.712,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salchichas-salchipollo",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OBVgDHNucPZ_nlhv4sA": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-12T14:04:44.118Z",
    //         "galeria": "[\"58366541731420283.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "8476231731420283.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Compota Osole De Pera",
    //         "oferta": "",
    //         "precio": 0.74,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "compota-osole-de-pera",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OAP9xg0zJD7eh3Q-dvs": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-29T21:25:56.647Z",
    //         "galeria": "[\"34645971730237159.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "31048261730237157.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Harina Robin Hood Todo Uso 1kg",
    //         "oferta": "",
    //         "precio": 1.3,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 50,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "harina-robin-hood-todo-uso-1kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NYT4xXi5D2jdRfUmoc6": {
    //         "categoria": "bebidas-y-lacteos",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Sabor \":[\"Durazno\",\"Fresa\",\"Mango\",\"Piña\",\"Mora\"]}]",
    //         "estado": true,
    //         "fecha_create": "2023-06-21T13:18:34.224Z",
    //         "galeria": "[\"84898451687353282.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "13634361687353269.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Yogur Racakes Griego",
    //         "oferta": "[\"Fixed\",2.34,\"2025-12-31\"]",
    //         "precio": 2.574,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "yogur-racakes-griego",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MgR23S1mpymP2hYERRE": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "Prestobarba Xen 3 hojilla x2&nbsp;",
    //         "detalles": "",
    //         "especificaciones": "[{\"Categoría \":[\"Dama\",\"Caballero\"]}]",
    //         "estado": true,
    //         "fecha_create": "2023-12-23T22:19:44.724Z",
    //         "galeria": "[\"71273331628263091.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "17105431628263025.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Afeitadora Xen 3 Hojilla X2",
    //         "oferta": "[\"Fixed\",1.19,\"2025-12-31\"]",
    //         "precio": 1.2733,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "afeitadora-xen-3-hojilla-x2",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NXdme9RaehAknPTl3zJ": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-06-11T09:30:07.886Z",
    //         "galeria": "[\"28274901686475809.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "16923161686475807.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Salsa Inglesa Iberia 150",
    //         "oferta": "",
    //         "precio": 1.98,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salsa-inglesa-iberia-150",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MR_PdbeByK9g9OGXkoR": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"500 gr \":0.8,\"1 kg\":1.6}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-01-15T20:20:14.582Z",
    //         "galeria": "[\"20754921611240478.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "35544061611240477.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Junca O Cebollín",
    //         "oferta": "[\"Disccount\",15,\"2025-02-28\"]",
    //         "precio": 1.6,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "junca-o-cebollin",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NCep1aWD-JHbeDB_vty": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Presentación \":[\"Dodo uso\",\"Leudante\"]}]",
    //         "estado": true,
    //         "fecha_create": "2024-02-28T14:53:40.358Z",
    //         "galeria": "[\"73186081663944632.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "18222051663944627.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Harina De Trigo La Lucha 900g",
    //         "oferta": "[\"Fixed\",1.1,\"2025-12-31\"]",
    //         "precio": 1.177,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "harina-de-trigo-la-lucha-900g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O5eP9v7MJs_FUnX_LNE": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-11T14:09:01.993Z",
    //         "galeria": "[\"78693021725140872.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "518161725140870.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Leche Completa Campesina 800gn",
    //         "oferta": "",
    //         "precio": 10.76,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "leche-completa-campesina-800gn",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQPOR7120IIuZHo78Gy": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "<b>Paño Amarillo</b>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-07T01:11:07.371Z",
    //         "galeria": "[\"84661851609981870.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "29019861609981868.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Paño Amarillo",
    //         "oferta": "",
    //         "precio": 1.09,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pano-amarillo",
    //         "video": "[]",
    //         "views": 7
    //     },
    //     "-MPt8o4A_GF9jK6uCTZv": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "descripcion": "<b>Pulpa Negra</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"500gr\":4.173,\"1kg\":8.346}]}]",
    //         "estado": true,
    //         "fecha_create": "2020-12-31T14:15:23.429Z",
    //         "galeria": "[\"96555971609424125.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "223281609424122.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Pulpa Negra",
    //         "oferta": "[\"Fixed\",3.9,\"2025-12-31\"]",
    //         "precio": 4.173,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pulpa-negra",
    //         "video": "[]",
    //         "views": 12
    //     },
    //     "-MszLvndAzLJSvHRXi4Q": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-01-13T20:41:37.850Z",
    //         "galeria": "[\"2734331641740481.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "46164931641740473.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Leche En Polvo Descremada Latti 400g",
    //         "oferta": "[\"Fixed\",3.69,\"2025-12-31\"]",
    //         "precio": 3.9483,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "leche-en-polvo-descremada-latti-400g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPkMb_MytH5iXbwap42": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "<p>1Kg Pechuga</p><p>1Kg Muslo</p><p>1Kg Alas o Picadillo de Pollo</p><p>1/2 Cartón de Huevos</p>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Preparación \":[\"*todo entero sin arreglar*\",\"*todo arreglado con la pechuga entera*\",\"*todo arreglado con la pechuga en filet*\"]}]",
    //         "estado": true,
    //         "fecha_create": "2021-11-21T10:26:20.034Z",
    //         "galeria": "[\"24769401720176702.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "5634491720176701.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo Pollo Y Huevos",
    //         "oferta": "[\"Fixed\",15,\"2025-12-31\"]",
    //         "precio": 16.05,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-pollo-y-huevos",
    //         "video": "[]",
    //         "views": 7
    //     },
    //     "-Mbn8vmT1WfWGUtGiL3m": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-06-09T23:42:17.097Z",
    //         "galeria": "[\"7667341623282137.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "94231751623282136.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Coleto 3 rayas",
    //         "oferta": "",
    //         "precio": 2.06,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "coleto-3-rayas",
    //         "video": "[]",
    //         "views": 6
    //     },
    //     "-MSbgT0lnw_zhCG7yBSE": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<b>compota heinz frutas mixtas 186g</b>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-02-03T11:40:44.865Z",
    //         "galeria": "[\"34194631612352445.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "4928181612352444.jpg",
    //         "mostrarOcultar": true,
    //         "nombre": "Compota Heinz Surtida 186g",
    //         "oferta": "",
    //         "precio": 1.5,
    //         "prox": false,
    //         "sales": 1,
    //         "stock": 23,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "compota-heinz-surtida-186g",
    //         "video": "[]",
    //         "views": 9
    //     },
    //     "-MSxVQLOrhSYV_Ae83Yy": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "<b>Jabón en Barra Bonaropa 300g</b>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-02-27T02:28:40.098Z",
    //         "galeria": "[\"20873901612718387.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "83045191612718385.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabón En Barra Bonaropa Lavanda",
    //         "oferta": "[\"Fixed\",0.83,\"2025-12-31\"]",
    //         "precio": 0.8881,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-en-barra-bonaropa-lavanda",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O7KIMnz6mQDcb6J4WBN": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-09-21T15:57:22.775Z",
    //         "galeria": "[\"7389951726934243.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "44629821726934243.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Vinagre De Manzana Hheinz 500ml",
    //         "oferta": "",
    //         "precio": 1.63,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "vinagre-de-manzana-hheinz-500ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NQt6woBunNYAe4e0Nr9": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-05-09T08:57:10.265Z",
    //         "galeria": "[\"62406621679216855.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "45595991679216854.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Champu 2 En 1 Kolors 400ml",
    //         "oferta": "[\"Fixed\",2.28,\"2023-12-31\"]",
    //         "precio": 2.38,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "champu-2-en-1-kolors-400ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MShxMJzg2_D9dgcvHzF": {
    //         "categoria": "pollo-criollo",
    //         "costo": "",
    //         "descripcion": "<p><b>Picadillo de pollo</b></p><ul><li><b>1 kg</b></li></ul>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"1 kg\":4.6545}]}]",
    //         "estado": true,
    //         "fecha_create": "2021-02-04T16:52:16.654Z",
    //         "galeria": "[\"31037701612530538.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "84428731612530535.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Picadillo De Pollo",
    //         "oferta": "[\"Fixed\",4.35,\"2025-12-31\"]",
    //         "precio": 4.6545,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "picadillo-de-pollo",
    //         "video": "[]",
    //         "views": 51
    //     },
    //     "-NH02ozNGmbAoRS5IWGW": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "Galleta Ducales Noel 2 Tacos",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-01-16T00:33:01.251Z",
    //         "galeria": "[\"36382781668613328.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "21555141668613327.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Galleta Ducales Noel 2 Tacos",
    //         "oferta": "[\"Fixed\",2.43,\"2025-12-31\"]",
    //         "precio": 2.6001,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "galleta-ducales-noel-2-tacos",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NPYmdoVw1DZGJksMALy": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-04T14:42:39.458Z",
    //         "galeria": "[\"14249621677785209.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "57905891677785200.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Apresto Favor",
    //         "oferta": "",
    //         "precio": 5.38,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "apresto-favor",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MkIMkrC5CH5n8D2KvDz": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "Pasta Sindoni Surtida&nbsp;",
    //         "detalles": "",
    //         "especificaciones": "[{\"Selección \":[\"Pluma\",\"Ditalini\",\"Codito\"]}]",
    //         "estado": false,
    //         "fecha_create": "2023-05-09T12:49:22.268Z",
    //         "galeria": "[\"20241521632412557.jpg\",\"3176721632412566.jpg\",\"55113101632412567.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "18503691632412539.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Pasta Sindoni corta 1kg",
    //         "oferta": "[\"Fixed\",1.45,\"2025-01-31\"]",
    //         "precio": 1.5515,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1000,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pasta-sindoni-corta-1kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N_jQZ9Y0aGQQeUPykTH": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-07-19T18:34:00.126Z",
    //         "galeria": "[\"136731689791643.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "92392411689791640.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Salsa Mayo Chipotle 350g",
    //         "oferta": "[\"Fixed\",2.02,\"2024-12-31\"]",
    //         "precio": 2.1614,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salsa-mayo-chipotle-350g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NMG1rsHMM8n_MlktZ-s": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Primor Extra Especial 1kg\":[{\"Larga\":1.64,\"Corta\":1.64}]}]",
    //         "estado": true,
    //         "fecha_create": "2023-02-23T23:08:15.606Z",
    //         "galeria": "[\"31554271674249469.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "51217611674249468.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Primor Extra Especial 1kg",
    //         "oferta": "",
    //         "precio": 1.64,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "primor-extra-especial-1kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MR_PGOkrBghCGQ2WNmb": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"1kg\":2.5,\"500gr\":1.25}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-01-15T20:20:14.582Z",
    //         "galeria": "[\"63052851611240379.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "88269611611240377.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Pimentón",
    //         "oferta": "[\"Disccount\",15,\"2025-02-28\"]",
    //         "precio": 2.5,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pimenton",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OIaG-gI0-LBr_pFAmUu": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T15:53:21.827Z",
    //         "galeria": "[\"61221611739030005.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "18015721739030001.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabón Natural Feeling X 4 Jabones",
    //         "oferta": "[\"Fixed\",2.42,\"2025-12-31\"]",
    //         "precio": 2.5894,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-natural-feeling-x-4-jabones",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NFJvsrHk57LhcITXa9A": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-10-26T15:35:52.292Z",
    //         "galeria": "[\"75908531667753594.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "46297791667753590.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Golden Gelatina 12 Porciones",
    //         "oferta": "",
    //         "precio": 2.14,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "golden-gelatina-12-porciones",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OG44YVWZJeUUN1coRRZ": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-01-08T08:44:28.471Z",
    //         "galeria": "[\"69026651736325870.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "12479811736325868.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Sal Cristal 1kg",
    //         "oferta": "",
    //         "precio": 1.4,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "sal-cristal-1kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NO_JydgoSLe_uYiUes9": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-03-02T20:31:46.657Z",
    //         "galeria": "[\"94748291676737243.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "80327561676737239.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Papel Arbóra X 4 rollos",
    //         "oferta": "[\"Fixed\",1.98,\"2024-12-31\"]",
    //         "precio": 2.1186,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "papel-arbora-x-4-rollos",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPl4HiLftPz5LpDwKQi": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "<ol><li><b>1/2kg Queso Tipo Paisa</b></li><li><b>1/2kg Queso Mozarella</b></li><li><b>1/2kg Queso Amarillo<br></b></li><li><b>1/2kg Jamón de Pierna Arichuna</b></li><li><b>1/2kg Boloña Corral</b></li><li><b>1/2kg Jamón Ahumado Arichuna</b></li><li><b>1 Crema de Leche 500gr</b></li><li><b>1 Cartón de Huevos</b></li></ol>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2020-12-30T00:38:36.817Z",
    //         "galeria": "[\"5455551690539700.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "99698261690539696.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo Charcutero Full",
    //         "oferta": "[\"Fixed\",34,\"2025-12-31\"]",
    //         "precio": 36.38,
    //         "prox": false,
    //         "sales": 1,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-charcutero-full",
    //         "video": "[]",
    //         "views": 82
    //     },
    //     "-MQKqnA_vTvRYjlw8b18": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<b>Toddy 400gr</b>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-14T16:12:58.421Z",
    //         "galeria": "[\"79245891609905680.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "63887521609905679.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Toddy 400gr",
    //         "oferta": "",
    //         "precio": 6.47,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 4,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "toddy-400gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MnliEdaw5PZSKFSbp4j": {
    //         "categoria": "licores",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-29T08:47:52.248Z",
    //         "galeria": "[\"74948361636143002.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "33156631636142973.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Ponche Crema L.d",
    //         "oferta": "[\"Fixed\",15.72,\"2025-12-31\"]",
    //         "precio": 16.82,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "ponche-crema-l-d",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OCotqlb9_F2gKWPFkUX": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-28T22:32:22.101Z",
    //         "galeria": "[\"99079281732833142.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "9866661732833142.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Arroz Pantera 908g",
    //         "oferta": "[\"Fixed\",1.98,\"2025-01-31\"]",
    //         "precio": 2.1186,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "arroz-pantera-908g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MqycwJHsHFuvKbvtlwc": {
    //         "categoria": "mascotas",
    //         "costo": "",
    //         "descripcion": "Galletas Para Perros Magic Friends 150g&nbsp;",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2023-04-05T10:23:20.871Z",
    //         "galeria": "[\"77976331639580943.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "4977851639580941.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Galletas Para Perros Magic Friends 150g ",
    //         "oferta": "[\"Fixed\",1.19,\"2024-12-31\"]",
    //         "precio": 1.2733,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 3,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "galletas-para-perros-magic-friends-150g-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQ3APqOJuIJwILhm1x_": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-aue-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Afeitadora Gillette ×2</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2023-12-23T22:19:44.724Z",
    //         "galeria": "[\"56236221609609095.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "87845951609609091.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Afeitadora Gillette x2",
    //         "oferta": "[\"Fixed\",2.29,\"2025-12-31\"]",
    //         "precio": 2.4503,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "afeitadora-gillette-x2",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQK6s0Xuu5rnJ1d8ojq": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Leche en polvo campiña 900gr</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-08-30T20:03:35.497Z",
    //         "galeria": "[\"48924311609893379.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "40303641609893379.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Leche en polvo campiña 900gr",
    //         "oferta": "",
    //         "precio": 11.76,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "leche-en-polvo-campina-900gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O74HYlJBytXyjuLGDME": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-09-18T13:20:03.552Z",
    //         "galeria": "[\"4399591726665603.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "29405471726665602.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Detergente Alive Surtido 1kg",
    //         "oferta": "",
    //         "precio": 2.6,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "detergente-alive-surtido-1kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQKmxP3tfz5Qo6zhXTy": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Salsa de soya china La colmena 397gr</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-04-27T14:25:25.006Z",
    //         "galeria": "[\"1084401609904673.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "37402291609904671.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Salsa de soya china La colmena 397gr",
    //         "oferta": "",
    //         "precio": 1.65,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salsa-de-soya-china-la-colmena-397gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O8SH67tfeS-3XKfy0pY": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-05T15:24:40.550Z",
    //         "galeria": "[\"88935431728141881.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "65385271728141880.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Papel Envoplas Para Alimentos",
    //         "oferta": "",
    //         "precio": 1.7,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 30,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "papel-envoplas-para-alimentos",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MhL2PegzNhScNogMVxT": {
    //         "categoria": "bebidas-y-lacteos",
    //         "costo": "",
    //         "descripcion": "Coca Cola de 1.5 Litros",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-08-17T21:38:56.698Z",
    //         "galeria": "[\"91318151629236338.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "84694631629236334.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Coca cola 1.5L",
    //         "oferta": "",
    //         "precio": 1.32,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "coca-cola-1-5l",
    //         "video": "[]",
    //         "views": 3
    //     },
    //     "-MPzYtlZMh4cIfgoYTkI": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "descripcion": "<p><br></p><p><b>Chinchurria&nbsp;</b></p>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"1kg\":3.4668}]}]",
    //         "estado": false,
    //         "fecha_create": "2023-05-23T20:30:40.668Z",
    //         "galeria": "[\"65259691609531627.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "93506781609531625.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Chinchurria ",
    //         "oferta": "[\"Fixed\",3.24,\"2025-01-31\"]",
    //         "precio": 3.4668,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "chinchurria-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N8_7_H4etktQEF-DDhG": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-08-03T19:15:02.594Z",
    //         "galeria": "[\"9934651659554123.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "146911659554100.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Leche San Simon Completa 900g",
    //         "oferta": "",
    //         "precio": 11.47,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 3,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "leche-san-simon-completa-900g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N47hfbcbflyEY_PfUbg": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-06-09T13:45:39.100Z",
    //         "galeria": "[\"36585501654782342.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "73641681654782339.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Recogedor Basura palma real",
    //         "oferta": "",
    //         "precio": 3.97,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 3,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "recogedor-basura-palma-real",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQ9CkUjIvN4BYIoLRzE": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-yha-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Desodorante Speed Stick Xtreme Night 30g</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-28T20:34:54.961Z",
    //         "galeria": "[\"36639091609710372.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "60358111609710370.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Desodorante Speed Stick Xtreme 30g",
    //         "oferta": "[\"Fixed\",1.15,\"2025-12-31\"]",
    //         "precio": 1.2305,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "desodorante-speed-stick-xtreme-30g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MpqVpsegdILIWOHCF_d": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "Jabón Rexona Antibacterial Tripack&nbsp;",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-01-12T16:26:26.179Z",
    //         "galeria": "[\"58998021645980458.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "93398031645980457.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabón Rexona Antibacterial Tripack ",
    //         "oferta": "[\"Fixed\",3.25,\"2025-01-31\"]",
    //         "precio": 3.4775,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-rexona-antibacterial-tripack-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OC3OQ98IMETifnSk75R": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2024-11-19T12:28:50.553Z",
    //         "galeria": "[\"24163971732019334.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "58844831732019329.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Oferta Pasta Sindoni Más Pascticho",
    //         "oferta": "[\"Fixed\",2.9,\"2025-01-31\"]",
    //         "precio": 3.19,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "oferta-pasta-sindoni-mas-pascticho",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NcmMP10lOxnEWjllsjH": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-08-26T15:01:48.903Z",
    //         "galeria": "[\"89247711693062110.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "96211841693062108.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Toalla Alwys Nocturna Ultra Delgada x8",
    //         "oferta": "",
    //         "precio": 2.5,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "toalla-alwys-nocturna-ultra-delgada-x8",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OCs6QMfSHcXXQUPy1b_": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-28T21:31:33.903Z",
    //         "galeria": "[\"9989741732887032.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "73406661732887032.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Colgate Menta 150ml",
    //         "oferta": "[\"Fixed\",4.83,\"2025-12-31\"]",
    //         "precio": 5.1681,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "colgate-menta-150ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O5Zshl3PqCdrppwDeoX": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-08-30T20:03:35.497Z",
    //         "galeria": "[\"54674481725048216.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "384671725048215.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Kellogs Zucarita 250 Gr",
    //         "oferta": "",
    //         "precio": 3.43,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "kellogs-zucarita-250-gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O-GwXA1NmTvYmzbtu-Z": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-06-13T14:13:17.546Z",
    //         "galeria": "[\"77077261718287999.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "82079461718287997.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Carre Leche Y Avellana 100g",
    //         "oferta": "",
    //         "precio": 4.12,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "carre-leche-y-avellana-100g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NNrP-R9HNFOx5lKXQ4Z": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-02-09T18:20:06.316Z",
    //         "galeria": "[\"19807461675966806.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "17347721675966805.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Champu Sedal Cecélulas Madres 340ml",
    //         "oferta": "",
    //         "precio": 4.71,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "champu-sedal-cecelulas-madres-340ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NoDm7AkBk6ngEQ_uGRY": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "descripcion": "<p>Aguacates por unidades </p>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-01-15T20:20:14.582Z",
    //         "galeria": "[\"34264621705350027.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "79546591705349996.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Aguacate ",
    //         "oferta": "[\"Disccount\",10,\"2025-02-28\"]",
    //         "precio": 2.6,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPtAjPCFPcwwbA0rgeP": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "descripcion": "<b>Cola de Res</b>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-05-23T20:30:40.668Z",
    //         "galeria": "[\"8309481609424631.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "89414811609424629.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Cola de Res",
    //         "oferta": "[\"Fixed\",5.67,\"2025-12-31\"]",
    //         "precio": 6.0669,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cola-de-res",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O9BMows6R0z6nFwTOyS": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-14T18:51:48.002Z",
    //         "galeria": "[\"9164991728931908.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "79663961728931908.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Margarina Mavesa 500gr  X 12 Und",
    //         "oferta": "[\"Fixed\",25.56,\"2025-01-31\"]",
    //         "precio": 27.3492,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "margarina-mavesa-500gr--x-12-und",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NqZlnhn6A7mdLk8DKwm": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-02-13T23:22:12.099Z",
    //         "galeria": "[\"90634231707866532.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "96248661707866530.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Pasra Capri Larga 1kg Premiun",
    //         "oferta": "",
    //         "precio": 1.85,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pasra-capri-larga-1kg-premiun",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MSNtpAJFrq9Yj_ILWEi": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "<b>Jabón palmolive paq x 3</b>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-28T20:34:54.961Z",
    //         "galeria": "[\"5860861645982552.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "79622321645982543.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabón Palmolive Tripack ",
    //         "oferta": "[\"Fixed\",3,\"2025-12-31\"]",
    //         "precio": 3.21,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-palmolive-tripack-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NwQt-y7dBWHzXdKuA7A": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-04-26T19:31:20.491Z",
    //         "galeria": "[\"34656551714159881.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "52306951714159880.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Protectores Diarios Olways Largos X50",
    //         "oferta": "",
    //         "precio": 4.42,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "protectores-diarios-olways-largos-x50",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MR_OsuRqH_3XAaSas2s": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"1 kg\":2.6}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-01-15T20:20:14.582Z",
    //         "galeria": "[\"91322651611240278.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "39853991611240277.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Tomate",
    //         "oferta": "[\"Disccount\",15,\"2025-02-28\"]",
    //         "precio": 2.6,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "tomate",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPzRW0UgQEl4TSwQCZw": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-mqj-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Galleta club social</h5><ul><li style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\"><b>6 unidades</b></li></ul>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-01T19:34:47.637Z",
    //         "galeria": "[\"70296111610735930.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "21913621610735928.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Galleta club social",
    //         "oferta": "",
    //         "precio": 1.71,
    //         "prox": false,
    //         "sales": 5,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "galleta-club-social",
    //         "video": "[]",
    //         "views": 27
    //     },
    //     "-NtcFtq1P5HBqr8_MPuI": {
    //         "categoria": "accesorios-de-cumpleanos-",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-03-22T22:24:04.996Z",
    //         "galeria": "[\"46916141711146245.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "476551711146244.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Vaso Los Llanos Nnro 77 X100",
    //         "oferta": "",
    //         "precio": 2.12,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 20,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "vaso-los-llanos-nnro-77-x100",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MwvoQ5vOLX0BSZ2TUrP": {
    //         "categoria": "articulos-de-limpieza",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Ambientador \":[{\"Manzana canela\":3.39,\"Coco splash\":3.39}]}]",
    //         "estado": true,
    //         "fecha_create": "2022-02-27T15:34:26.324Z",
    //         "galeria": "[\"4374921645976071.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "14817161645976065.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Ambientador Spraymatic 360ml",
    //         "oferta": "",
    //         "precio": 3.39,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "ambientador-spraymatic-360ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OIbBj19ifkaTR3reRCA": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T20:13:57.926Z",
    //         "galeria": "[\"70024071739045661.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "36434191739045637.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabón Bonaropa Bicarbonato 900gr",
    //         "oferta": "[\"Fixed\",1.38,\"2025-12-31\"]",
    //         "precio": 1.4766,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-bonaropa-bicarbonato-900gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPtJa7ItIsuPZ4cR5CZ": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "descripcion": "<b>Chocozuela</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"500gr\":4.173,\"1kg\":8.346}]}]",
    //         "estado": true,
    //         "fecha_create": "2020-12-31T15:02:32.112Z",
    //         "galeria": "[\"4001241609426952.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "19184471609426951.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Chocozuela",
    //         "oferta": "[\"Fixed\",3.9,\"2025-12-31\"]",
    //         "precio": 4.173,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "chocozuela",
    //         "video": "[]",
    //         "views": 6
    //     },
    //     "-MQDy8tvCbEO7no21gnZ": {
    //         "categoria": "mascotas",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-vnw-c32=\"\" class=\"card-title mb-2\" style=\"font-weight: 500; font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Gatarina dali Surtido</h5>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Sabor\":[\"Pescado\",\"Carne\"]}]",
    //         "estado": false,
    //         "fecha_create": "2023-04-05T10:23:20.871Z",
    //         "galeria": "[\"8979951609790168.jpg\",\"91926921639666996.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "49421021609790164.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Gatarina Dali 1kg",
    //         "oferta": "[\"Fixed\",2.33,\"2024-01-31\"]",
    //         "precio": 2.43,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "gatarina-dali-1kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MUJ8oFtorcHh4wuUJs6": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "<p><br></p><p><b><br></b></p>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-28T20:34:54.961Z",
    //         "galeria": "[\"85984141614172077.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "57618451614172075.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabón Palmolive Por unidad",
    //         "oferta": "[\"Fixed\",1.11,\"2025-12-31\"]",
    //         "precio": 1.1877,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-palmolive-por-unidad",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NCFeq5YKyTkTcvFU-78": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-06-19T15:01:30.295Z",
    //         "galeria": "[\"57472881663505752.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "76008841663505751.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Aceite Puro Gold Soya 500ml",
    //         "oferta": "[\"Fixed\",1.65,\"2023-12-31\"]",
    //         "precio": 1.75,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "aceite-puro-gold-soya-500ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NyufJsaZHNLGcwRja2y": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-05-27T15:31:03.215Z",
    //         "galeria": "[\"17912801716823869.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "38406211716823861.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Leche Parmalat 400g",
    //         "oferta": "",
    //         "precio": 4.2,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "leche-parmalat-400g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NT45QY7QSeYLW2UmFFJ": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-28T20:34:54.961Z",
    //         "galeria": "[\"46199931681565267.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "21899331681565266.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Desodorante Lady Speed Stick 30g",
    //         "oferta": "[\"Fixed\",1.15,\"2025-12-31\"]",
    //         "precio": 1.2305,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "desodorante-lady-speed-stick-30g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N4biSW5_kmtEB9nCTsB": {
    //         "categoria": "licores",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-29T08:47:52.248Z",
    //         "galeria": "[\"78882271735815066.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "51568571735815052.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Ron Superior Santa Teresa 1l",
    //         "oferta": "[\"Fixed\",4.78,\"2025-12-31\"]",
    //         "precio": 5.1146,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "ron-superior-santa-teresa-1l",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MVIsDr5vqEtTWlO6Uou": {
    //         "categoria": "reposteria",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-03-08T22:06:46.904Z",
    //         "galeria": "[\"22231271615241210.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "87766641615241206.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Ponque 1",
    //         "oferta": "",
    //         "precio": 0,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "ponque-1",
    //         "video": "[]",
    //         "views": 1
    //     },
    //     "-NsPSlZe183pU2IH7_sy": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-03-07T19:49:51.435Z",
    //         "galeria": "[\"13412391709840996.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "81508331709840991.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Lavaplatos kikex 500ml",
    //         "oferta": "",
    //         "precio": 1.51,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "lavaplatos-kikex-500ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NrPOPVuTInPMb2jkNqK": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-02-24T09:15:10.926Z",
    //         "galeria": "[\"78509861708766111.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "11631271708766110.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Guisante Predilecta",
    //         "oferta": "",
    //         "precio": 1.58,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 30,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "guisante-predilecta",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MY8W7tvOnMjXefaJsd5": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">Bicarbonato de sodio speciarina 200gr</font></font>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-01-16T00:33:01.251Z",
    //         "galeria": "[\"22437761618288610.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "68633041618288609.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Bicarbonato De Sodio Speciarina 200gr",
    //         "oferta": "[\"Fixed\",1.25,\"2025-12-31\"]",
    //         "precio": 1.3375,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "bicarbonato-de-sodio-speciarina-200gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NhGXNWiGwvbh1WEAvU_": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-10-21T09:20:48.488Z",
    //         "galeria": "[\"98309231697880049.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "68551251697880048.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Naturalismo De Maggi",
    //         "oferta": "",
    //         "precio": 4.41,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 5,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "naturalismo-de-maggi",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-No2Q6vRqSPYHnWY-6R_": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-01-13T15:24:15.777Z",
    //         "galeria": "[\"23375961705159457.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "1042931705159456.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Esponja Multiuso Tidy House X 3",
    //         "oferta": "[\"Fixed\",0.85,\"2025-01-31\"]",
    //         "precio": 0.9095,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "esponja-multiuso-tidy-house-x-3",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mi7rlXIoxEu0Yl-6M55": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "Arveja Verde Entera Pantera 500g",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-09-19T15:07:23.185Z",
    //         "galeria": "[\"40404901630088927.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "40304371630088926.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Arveja Verde Entera Pantera 500g",
    //         "oferta": "[\"Fixed\",1.15,\"2025-01-31\"]",
    //         "precio": 1.2305,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "arveja-verde-entera-pantera-500g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NPBrsVZnSmsv0DfJLvB": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-05-09T08:57:10.265Z",
    //         "galeria": "[\"99982001677400704.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "56714921677400703.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Toallin De Cocina Rendy Premium Triple Hoja",
    //         "oferta": "[\"Fixed\",0.78,\"2025-12-31\"]",
    //         "precio": 0.8346,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "toallin-de-cocina-rendy-premium-triple-hoja",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O1cT7z6vs4XEdRyamY2": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-07-12T19:43:10.133Z",
    //         "galeria": "[\"52983821720813391.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "1495711720813390.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Lampazo Andecol",
    //         "oferta": "",
    //         "precio": 3.21,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "lampazo-andecol",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OJ4AyT1SvaS1adZL9EY": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-14T15:59:37.256Z",
    //         "galeria": "[\"69863511739548780.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "82936081739548778.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Atun Margarita En Trozos 170g",
    //         "oferta": "",
    //         "precio": 2,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "atun-margarita-en-trozos-170g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQK9UzHT3KBOcMrJoLc": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Margarina mavesa 500gr</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-06T00:47:45.102Z",
    //         "galeria": "[\"12146111609894067.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "82363791609894064.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Margarina mavesa 500gr",
    //         "oferta": "",
    //         "precio": 2.65,
    //         "prox": false,
    //         "sales": 3,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "margarina-mavesa-500gr",
    //         "video": "[]",
    //         "views": 16
    //     },
    //     "-MTSMXtug5hH9QKhcnU8": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Fragancia \":[\"Revita color\",\"Limon\"]}]",
    //         "estado": true,
    //         "fecha_create": "2025-02-09T09:20:27.640Z",
    //         "galeria": "[\"78088781613252930.jpg\",\"10245501637592545.jpg\",\"57133751637592544.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "20532491613252927.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabón Ariel 4kg",
    //         "oferta": "[\"Fixed\",17,\"2025-12-31\"]",
    //         "precio": 18.19,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-ariel-4kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPtKxQAmsQkc93tNtn2": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "descripcion": "<b>Carne Punta Trasera</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Punta trasera \":[{\"1.7 kg entera\":13.7709,\"1.7kg en bisteck \":13.7709}]}]",
    //         "estado": true,
    //         "fecha_create": "2022-03-25T16:28:35.038Z",
    //         "galeria": "[\"86959921609427309.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "5613651609427302.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Carne Punta Trasera 1.7kg ",
    //         "oferta": "[\"Fixed\",12.87,\"2025-12-31\"]",
    //         "precio": 13.7709,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "carne-punta-trasera-1-7kg-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPt4IjiWNb1MJEUJTjO": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "<b>Jamón de Pavo</b>",
    //         "detalles": "[{\"title\":\"Marca\",\"value\":\"Cordillera\"}]",
    //         "especificaciones": "[{\"Peso\":[{\"250gr \":2.6108,\"300gr\":3.13296,\"500gr\":5.2216}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-07-09T14:00:25.460Z",
    //         "galeria": "[\"64405311714963564.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "54172981714963562.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Jamón De Pavo",
    //         "oferta": "[\"Fixed\",2.44,\"2025-10-31\"]",
    //         "precio": 2.6108,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jamon-de-pavo",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MYBnt4nB1U4pO0bY237": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Sabores cri cri\":[\"Leche\",\"Rikiti\"]}]",
    //         "estado": true,
    //         "fecha_create": "2024-03-22T17:36:59.225Z",
    //         "galeria": "[\"76247971618343857.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "78508251618343836.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Chocolate Savoy 130g Surtidos",
    //         "oferta": "",
    //         "precio": 3.96,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "chocolate-savoy-130g-surtidos",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MV1O4mRDf1-gtxqyhPW": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "<b>Desodorante Dioxogen</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Dioxogen \":[\"Original\",\"Talco\",\"Bicarbonato\",\"Aloe vera\"]}]",
    //         "estado": true,
    //         "fecha_create": "2022-11-25T16:47:28.174Z",
    //         "galeria": "[\"85046471614947832.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "3111121614947831.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Desodorante Dioxogen",
    //         "oferta": "[\"Fixed\",2.41,\"2023-07-31\"]",
    //         "precio": 2.5,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "desodorante-dioxogen",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N3KNe1_3IDRvCHqgA_K": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-05-30T14:33:12.351Z",
    //         "galeria": "[\"44793491653921193.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "10299271653921192.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Yesquero",
    //         "oferta": "",
    //         "precio": 0.47,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "yesquero",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NIgfgQpl8cLDm0aTnQu": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "descripcion": "<p>Ajo porro por kg</p>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"1 kg\":2.8}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-01-15T20:20:14.582Z",
    //         "galeria": "[\"57669431671554440.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "30762751671554439.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Ajo Porro ",
    //         "oferta": "[\"Disccount\",10,\"2025-02-28\"]",
    //         "precio": 2.8,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "ajo-porro-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NP0lHQkGqBkP2NB5ayr": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-22T12:36:39.802Z",
    //         "galeria": "[\"70196441677214426.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "44544711677214424.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Harina Lucharepa 1kg ",
    //         "oferta": "[\"Fixed\",0.96,\"2025-12-31\"]",
    //         "precio": 1.0272,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "harina-lucharepa-1kg-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O-johQyHaZilAoQh7MV": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Preparación \":[\"Con hígado de res\",\"Con callo de res\"]}]",
    //         "estado": true,
    //         "fecha_create": "2024-06-19T09:27:43.601Z",
    //         "galeria": "[\"99332681718789264.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "73528381718789264.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo Oferta 3",
    //         "oferta": "[\"Fixed\",12,\"2025-12-31\"]",
    //         "precio": 12.84,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-oferta-3",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O9YUL_uMFF3PiY_pPiU": {
    //         "categoria": "cerdo",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Cantidad \":[{\"100gr\":2.14,\"200gr\":4.28,\"300gr\":6.42,\"400gr\":8.56,\"500gr\":10.7}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-10-19T06:35:56.294Z",
    //         "galeria": "[\"77334141729319757.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "88022131729319756.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Tocineta ",
    //         "oferta": "[\"Fixed\",2,\"2025-12-31\"]",
    //         "precio": 2.14,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1000,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "tocineta-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OIb2_WIOd1wqrwvUmmN": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T19:34:21.766Z",
    //         "galeria": "[\"72545091739043263.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "18085571739043261.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Nuthos Mani Con Pasas",
    //         "oferta": "[\"Fixed\",1.55,\"2025-12-31\"]",
    //         "precio": 1.6585,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "nuthos-mani-con-pasas",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NPFe7FucwNKpT3ZGm8H": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-02-27T02:16:44.849Z",
    //         "galeria": "[\"73424941677464207.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "96334541677464206.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabón Líquido Bonaropa Prendas Delicadas 1lt",
    //         "oferta": "[\"Fixed\",1.95,\"2025-12-31\"]",
    //         "precio": 2.0865,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-liquido-bonaropa-prendas-delicadas-1lt",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPsufx-Jfag05ExaOJN": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "<b>Tocineta Ahumada de Cerdo</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"100gr \":2.14,\"200gr \":4.28,\"300gr\":6.42,\"400gr\":8.56,\"500gr\":10.7}]}]",
    //         "estado": true,
    //         "fecha_create": "2022-06-08T17:18:01.972Z",
    //         "galeria": "[\"33531341714963623.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "133531714963622.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Tocineta Ahumada de Cerdo",
    //         "oferta": "[\"Fixed\",2,\"2025-12-31\"]",
    //         "precio": 2.14,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "tocineta-ahumada-de-cerdo",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NBTuhO-Qc1rtB2P2GTW": {
    //         "categoria": "accesorios-de-cumpleanos-",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-07-02T21:16:25.868Z",
    //         "galeria": "[\"8155321668697668.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "60307341668697647.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Platos Anime Nro 8 X 20 Unid",
    //         "oferta": "",
    //         "precio": 1.23,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "platos-anime-nro-8-x-20-unid",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NrVl099iViwD4HVhoyr": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-02-25T14:55:59.382Z",
    //         "galeria": "[\"9243551708872962.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "49414221708872961.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Mantequilla Torondoy",
    //         "oferta": "[\"Fixed\",5.51,\"2024-12-31\"]",
    //         "precio": 5.8957,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "mantequilla-torondoy",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPzZjWTH7v5F6u82PmF": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "descripcion": "<p><b>Lengua</b></p><ul><li><b>Por unidad</b></li></ul>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-05-23T20:30:40.668Z",
    //         "galeria": "[\"66666251609531847.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "84562011609531845.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Lengua",
    //         "oferta": "[\"Fixed\",6.49,\"2025-12-31\"]",
    //         "precio": 6.9443,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "lengua",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MhEK-0f5oUmt_2p-Umq": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-08-16T14:18:26.801Z",
    //         "galeria": "[\"74047741629123509.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "70131391629123506.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Paño Micro Fibra Matrix Por Unidad",
    //         "oferta": "",
    //         "precio": 0.68,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 15,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pano-micro-fibra-matrix-por-unidad",
    //         "video": "[]",
    //         "views": 7
    //     },
    //     "-MPtOJWSFS4_tLFcsB75": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "descripcion": "<b>Carne de Guisar</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"500gr\":3.852,\"1kg\":7.704}]}]",
    //         "estado": true,
    //         "fecha_create": "2023-05-23T20:30:40.668Z",
    //         "galeria": "[\"37037601609428190.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "72342551609428188.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Carne Guisar",
    //         "oferta": "[\"Fixed\",3.6,\"2025-12-31\"]",
    //         "precio": 3.852,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "carne-guisar",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MaTw2qgjI7WTkNyD_qu": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Dove\":[\"Carite vainilla\",\"Original\",\"Indulgente\",\"Exfoliación\"]}]",
    //         "estado": true,
    //         "fecha_create": "2023-06-08T12:40:31.288Z",
    //         "galeria": "[\"5738621621869213.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "79004551621869211.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabón Dove 90g ",
    //         "oferta": "",
    //         "precio": 1.56,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-dove-90g-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OASo-Lrm9zAuV_x7jya": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-30T14:24:10.521Z",
    //         "galeria": "[\"27629051730298250.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "84841721730298250.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Frescolita 2 Litros",
    //         "oferta": "",
    //         "precio": 1.77,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "frescolita-2-litros",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQDsTHnaJ1RCcJGC7Pd": {
    //         "categoria": "bebes-",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-vnw-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Shampoo Little angels 360ml</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-10-24T10:33:31.335Z",
    //         "galeria": "[\"43723371609788678.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "15924901609788676.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Shampoo Little angels 360ml",
    //         "oferta": "",
    //         "precio": 1.95,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "shampoo-little-angels-360ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQK4QLygXS77Yd8E9bo": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Harina Pan 1kg</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-06T00:25:34.029Z",
    //         "galeria": "[\"22837721609892737.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "5739511609892733.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Harina Pan 1kg",
    //         "oferta": "",
    //         "precio": 1.3,
    //         "prox": false,
    //         "sales": 37,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "harina-pan-1kg",
    //         "video": "[]",
    //         "views": 219
    //     },
    //     "-MszJn0Bz-9zqG5cIvFh": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Sabor\":[\"Fresa\",\"Vainilla\"]}]",
    //         "estado": true,
    //         "fecha_create": "2022-02-12T00:06:52.382Z",
    //         "galeria": "[\"80528991641739918.png\",\"2389661641739920.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "60989961641739897.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Galleta Wafers Italo 240g ",
    //         "oferta": "[\"Fixed\",1.99,\"2025-01-31\"]",
    //         "precio": 2.1293,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "galleta-wafers-italo-240g-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O4u-hzIak22HJ9IDfWJ": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-08-22T12:14:52.175Z",
    //         "galeria": "[\"7629751724328893.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "80849431724328891.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Salsa De Tomate Emvesa 200gr Venezolana 💯",
    //         "oferta": "[\"Fixed\",0.78,\"2025-01-31\"]",
    //         "precio": 0.8346,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salsa-de-tomate-emvesa-200gr-venezolana-💯",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPkTdaczHLYvtx-gzRn": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "<ol><li><b>1Kg Picadillo de Pollo</b></li><li><b>1Kg Pechuga</b></li><li><b>1Kg Muslo</b></li><li><b>1/2 Bologña Corral</b></li><li><b>1/2 Jamón de Pierna Arichuna</b></li><li><b>1/2 Queso Duro</b></li><li><b>1/2 Queso Mozarella</b></li><li><b>1/2 Cartón de Huevos</b></li></ol>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2020-12-29T22:23:10.652Z",
    //         "galeria": "[\"65471641690539809.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "47477081690539806.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo Charcupollo",
    //         "oferta": "[\"Fixed\",31,\"2025-12-31\"]",
    //         "precio": 33.17,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-charcupollo",
    //         "video": "[]",
    //         "views": 144
    //     },
    //     "-NPOh-gjlFjfwUVBLv3K": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-05-18T11:43:34.593Z",
    //         "galeria": "[\"10189271677615950.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "77502791677615949.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Caraota Negra Pantera 900gr",
    //         "oferta": "[\"Fixed\",3.23,\"2025-01-31\"]",
    //         "precio": 3.4561,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "caraota-negra-pantera-900gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NM6eqiR-hbQ0R0_vcaE": {
    //         "categoria": "bebidas-y-lacteos",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T19:34:21.766Z",
    //         "galeria": "[\"90973201674092175.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "3558331674092141.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Leche Liquida Latti X3 Unidades",
    //         "oferta": "[\"Fixed\",4.05,\"2025-12-31\"]",
    //         "precio": 4.3335,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "leche-liquida-latti-x3-unidades",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N9cSRHjC0wYHRKOIBMe": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-07-25T20:01:55.633Z",
    //         "galeria": "[\"87467921660683666.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "32143951660683665.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Toallin De Cocina Rendy Verde ",
    //         "oferta": "[\"Fixed\",0.65,\"2025-12-31\"]",
    //         "precio": 0.6955,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "toallin-de-cocina-rendy-verde-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NXdmJh_1D6a8lXuJvZ_": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-06-11T09:28:40.114Z",
    //         "galeria": "[\"51431801686475721.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "52014381686475719.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Salsa Soya Iberia 150",
    //         "oferta": "",
    //         "precio": 1.98,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salsa-soya-iberia-150",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NCV3LPPqs9EcLmfx0IB": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-07-09T14:00:25.460Z",
    //         "galeria": "[\"60935061663764095.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "3488801663764088.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Crema De Leche La cremosa ",
    //         "oferta": "[\"Fixed\",2.99,\"2025-12-31\"]",
    //         "precio": 3.1993,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "crema-de-leche-la-cremosa-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O9BK4EGBOufNXpkEcLv": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-14T18:39:42.721Z",
    //         "galeria": "[\"56062701728931188.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "61325051728931182.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Harina Pan Fardo X 20kg",
    //         "oferta": "[\"Fixed\",20,\"2025-12-31\"]",
    //         "precio": 21.4,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "harina-pan-fardo-x-20kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MVfs2qeTGzUq5AcB_AJ": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "<b>Paquete de Galleta Chips Ahoy! 168g</b>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-03-13T13:56:56.441Z",
    //         "galeria": "[\"60342001615643818.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "6533381615643816.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Paquete De Galleta chips ahoy 168g",
    //         "oferta": "",
    //         "precio": 2.44,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 4,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "paquete-de-galleta-chips-ahoy-168g",
    //         "video": "[]",
    //         "views": 8
    //     },
    //     "-ODDCjC6Mp7vb7W2xkw9": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-12-03T20:29:44.340Z",
    //         "galeria": "[\"93332871733257785.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "8629681733257784.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Snickers",
    //         "oferta": "",
    //         "precio": 1.92,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 5,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "snickers",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQ4Jee09OTruct5ruCD": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-exu-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Desodorante Gillette Clear Gel 45g</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2021-01-02T22:58:06.055Z",
    //         "galeria": "[\"4838541609628296.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "7775851609628284.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Desodorante Gillette Clear Gel 45g",
    //         "oferta": "[\"Fixed\",2.11,\"2023-12-31\"]",
    //         "precio": 2.22,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "desodorante-gillette-clear-gel-45g",
    //         "video": "[]",
    //         "views": 8
    //     },
    //     "-MQJsdC6MewN0Yyge2BL": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Cubito de Pollo Maggi 8 Unds</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-05T08:31:00.938Z",
    //         "galeria": "[\"6319021609889382.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "36847141609889376.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Cubito de Pollo Maggi 8 Unds",
    //         "oferta": "",
    //         "precio": 2.23,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 20,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cubito-de-pollo-maggi-8-unds",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MmKCiAlXz4aNFzMGWin": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Tipo de Cabello\":[\"Seco Maltratado\",\"Rizos\",\"Liso\"]}]",
    //         "estado": true,
    //         "fecha_create": "2021-10-18T21:02:52.623Z",
    //         "galeria": "[\"14218981634590974.jpg\",\"84476991638029495.jpg\",\"67154761638029495.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "44176481634590973.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Champú Drene Surtido 370ml",
    //         "oferta": "",
    //         "precio": 4.91,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "champu-drene-surtido-370ml",
    //         "video": "[]",
    //         "views": 3
    //     },
    //     "-NnyOYJ3jdtKuCGHREoM": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2024-01-12T15:59:15.250Z",
    //         "galeria": "[\"92091771705075159.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "16602911705075157.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Colgate triple acción 150ml",
    //         "oferta": "[\"Fixed\",4.83,\"2025-01-31\"]",
    //         "precio": 5.1681,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "colgate-triple-accion-150ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MY5JT2N-r5R9YDcT_Bl": {
    //         "categoria": "bebidas-y-lacteos",
    //         "costo": "",
    //         "descripcion": "Jugo Tripack natulat",
    //         "detalles": "",
    //         "especificaciones": "[{\"Sabor\":[\"Pera\",\"Manzana\"]}]",
    //         "estado": true,
    //         "fecha_create": "2021-04-12T13:42:34.039Z",
    //         "galeria": "[\"98139781618234955.jpg\",\"5419431620304446.jpg\",\"78367831620304448.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "28645071618234955.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Jugo Tripack Natulat ",
    //         "oferta": "[\"Fixed\",2.72,\"2025-12-31\"]",
    //         "precio": 2.9104,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jugo-tripack-natulat-",
    //         "video": "[]",
    //         "views": 14
    //     },
    //     "-MPwA4gAjUMxDrZxGNrv": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-gws-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Galleta Marilú</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-10-05T13:46:29.835Z",
    //         "galeria": "[\"64199661609989261.jpg\",\"26679011609989261.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "58715431609989259.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Galleta Marilú",
    //         "oferta": "",
    //         "precio": 1.88,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "galleta-marilu",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPtu0Du7iodvIjRZs5m": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-lgt-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Pepito 80gr</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-10-05T21:04:05.414Z",
    //         "galeria": "[\"20508031609436761.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "2370541609436700.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Pepito 25gr",
    //         "oferta": "",
    //         "precio": 0.79,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pepito-25gr",
    //         "video": "[]",
    //         "views": 2
    //     },
    //     "-NDmusNZxJBji9zVbvrV": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Tipo\":[\"Domestico\"]}]",
    //         "estado": true,
    //         "fecha_create": "2023-10-24T10:33:31.335Z",
    //         "galeria": "[\"86477701667835443.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "56569961667835442.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Guantes Domesticos  Talla m",
    //         "oferta": "[\"Fixed\",1.25,\"2024-01-31\"]",
    //         "precio": 1.8,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "guantes-domesticos--talla-m",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NxSNmGycCcS1VloRijg": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2024-05-09T12:45:29.576Z",
    //         "galeria": "[\"70037751715258729.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "32937711715258728.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Sardina Arrecife 170g",
    //         "oferta": "[\"Fixed\",0.98,\"2025-01-31\"]",
    //         "precio": 1.0486,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "sardina-arrecife-170g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NiyygmsDgFrchxAERjd": {
    //         "categoria": "licores",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-29T08:47:52.248Z",
    //         "galeria": "[\"46287711699716188.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "98965801699716175.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Vino Espumante De Manzana Greco",
    //         "oferta": "[\"Fixed\",7.14,\"2025-12-31\"]",
    //         "precio": 7.64,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "vino-espumante-de-manzana-greco",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPw8AozFpyy1QUMFYXB": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-gws-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Galleta Hony Bran 252gr</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-01T04:11:30.222Z",
    //         "galeria": "[\"82877221609474292.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "32041021609474289.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Galleta Hony Bran 252gr",
    //         "oferta": "",
    //         "precio": 2.85,
    //         "prox": false,
    //         "sales": 1,
    //         "stock": 14,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "galleta-hony-bran-252gr",
    //         "video": "[]",
    //         "views": 12
    //     },
    //     "-NN9hBK5vV6NK3fK7fTn": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-02-01T02:00:55.317Z",
    //         "galeria": "[\"30601701675216856.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "72611211675216852.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Aceite naturoil soya 850ml",
    //         "oferta": "",
    //         "precio": 3.48,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "aceite-naturoil-soya-850ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQEn_tqZKzqCU4VEgL_": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-vnw-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Café Sello Rojo 500gr</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2023-03-19T09:07:34.872Z",
    //         "galeria": "[\"7375501609804175.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "79501781609804174.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Café Sello Rojo 500gr",
    //         "oferta": "[\"Fixed\",6.14,\"2025-12-31\"]",
    //         "precio": 6.5698,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cafe-sello-rojo-500gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NxOmG4bErF5fKRonEPo": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-05-08T19:58:19.651Z",
    //         "galeria": "[\"19435281715198300.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "8592911715198299.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Ricota Tachira 250gr",
    //         "oferta": "[\"Fixed\",4.29,\"2025-12-31\"]",
    //         "precio": 4.5903,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "ricota-tachira-250gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MV1l-YxwDpLK1fvIRaI": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "<b>Toallín Rosal</b>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-03-05T14:21:42.567Z",
    //         "galeria": "[\"10377881614954103.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "94926551614954102.jpg",
    //         "mostrarOcultar": true,
    //         "nombre": "Toallin rosal",
    //         "oferta": "",
    //         "precio": 1.54,
    //         "prox": false,
    //         "sales": 1,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "toallin-rosal",
    //         "video": "[]",
    //         "views": 1
    //     },
    //     "-Nvww5t_mH3738YAgQKk": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-04-20T19:16:29.118Z",
    //         "galeria": "[\"22625481713640588.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "76788651713640588.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Suavizante Downy 700",
    //         "oferta": "",
    //         "precio": 3.45,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 3,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "suavizante-downy-700",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NXQBG8-uMkrGIQjvHR5": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-06-08T13:27:24.613Z",
    //         "galeria": "[\"64902991686230862.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "2326041686230843.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Desodorante Rexona V8 Barra 50g",
    //         "oferta": "",
    //         "precio": 3.81,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "desodorante-rexona-v8-barra-50g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MV6VGJ3XixSBPCOSf_X": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "<b>Chocolate de colores Dandy</b>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-01-30T20:09:45.557Z",
    //         "galeria": "[\"99109481675276703.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "45093231675276702.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Chocolate Dandy ",
    //         "oferta": "",
    //         "precio": 0.45,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 18,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "chocolate-dandy-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OIb1-69i2PIuKzEm0ME": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T19:27:21.853Z",
    //         "galeria": "[\"4596101739042848.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "21428731739042841.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Galleta Oreo Original 144g",
    //         "oferta": "[\"Fixed\",1.67,\"2025-12-31\"]",
    //         "precio": 1.7869,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "galleta-oreo-original-144g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O2_G8YyjwSvNp1Zhdmq": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-07-24T15:03:16.244Z",
    //         "galeria": "[\"22517331721833396.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "12089811721833395.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Leche Vitta 380g Entera",
    //         "oferta": "",
    //         "precio": 4.12,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "leche-vitta-380g-entera",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mq4UQYmZuAjM1nLMCeQ": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-11-07T09:28:22.826Z",
    //         "galeria": "[\"50497541638622147.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "17464291638622117.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Maggi Sazonatodo",
    //         "oferta": "",
    //         "precio": 3.68,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "maggi-sazonatodo",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NQqISm0gl8nscL2dvkQ": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-03-18T19:58:59.770Z",
    //         "galeria": "[\"22029671679169542.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "91523751679169540.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Papel Elite X 12 Rollos ",
    //         "oferta": "[\"Fixed\",5.63,\"2023-12-31\"]",
    //         "precio": 5.73,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "papel-elite-x-12-rollos-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQKcK3A2MvW9b6t-9I1": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Puré De Tomate Heinz</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-06T02:58:05.946Z",
    //         "galeria": "[\"83644761609901886.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "15787181609901885.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Puré De Tomate Heinz 195g",
    //         "oferta": "",
    //         "precio": 2.18,
    //         "prox": false,
    //         "sales": 1,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pure-de-tomate-heinz-195g",
    //         "video": "[]",
    //         "views": 8
    //     },
    //     "-NPXfWR-HSvN1mPbyUgL": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-03-02T14:16:00.650Z",
    //         "galeria": "[\"73431761677766562.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "40774181677766561.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Cera AutoBrillante Brilla King 1l",
    //         "oferta": "[\"Fixed\",3.56,\"2024-12-31\"]",
    //         "precio": 3.8092,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cera-autobrillante-brilla-king-1l",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NH07rtbvQiC-pDqH-Cz": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Paquete De Oreo X6 216g\":[\"Original\",\"Fresa\"]}]",
    //         "estado": true,
    //         "fecha_create": "2023-07-31T20:41:06.442Z",
    //         "galeria": "[\"13527071668613897.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "82385751668613895.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Paquete De Oreo X6 216g",
    //         "oferta": "",
    //         "precio": 2.4,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "paquete-de-oreo-x6-216g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MV2-TbAosXeVxy79W50": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<p><b>Nestle cerelac</b></p><ul><li><b>Todo lo bueno del cereal</b></li></ul>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-03-05T15:28:44.319Z",
    //         "galeria": "[\"66636941614958157.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "74483571614958123.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Nestle Cerelac 400g",
    //         "oferta": "",
    //         "precio": 6.33,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "nestle-cerelac-400g",
    //         "video": "[]",
    //         "views": 3
    //     },
    //     "-MQFyYKTvKevv3Srn6-8": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-nhs-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Chocolate Corona 250g</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-04-23T17:21:03.341Z",
    //         "galeria": "[\"40259401609823826.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "21477781609823821.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Chocolate Corona 250g",
    //         "oferta": "[\"Fixed\",2.77,\"2025-01-31\"]",
    //         "precio": 2.9639,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "chocolate-corona-250g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQ3fLAIL4D96zRmw7dU": {
    //         "categoria": "bebes-",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-aue-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Crema Dermoprotectora Little Angels 150g</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-02-15T21:37:29.411Z",
    //         "galeria": "[\"3502321609617465.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "5995651609617464.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Crema Dermoprotectora Little Angels 150g",
    //         "oferta": "",
    //         "precio": 2.48,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "crema-dermoprotectora-little-angels-150g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Niq1Zt0yrXs06CqC3EG": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-11-09T21:43:28.833Z",
    //         "galeria": "[\"6224141699566209.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "8835431699566208.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Choco Tigo Con Avellana 200 Grs",
    //         "oferta": "",
    //         "precio": 2.79,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "choco-tigo-con-avellana-200-grs",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MoOpxSAQrD7Ls-ZfSkl": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "<ol><li><strong>1 pollo entero (2.2 kg aprox.)</strong></li><li><strong>1kg de muslo</strong></li><li><strong>1 kg de alas</strong></li><li><strong>1kg de carne molida&nbsp;</strong></li><li><strong>1kg de carne para Mechar&nbsp;</strong></li><li><strong>2kg de harina pan&nbsp;</strong></li><li><strong>2kg de Arroz Mary Tradicional&nbsp;</strong></li><li><strong>1kg de pasta primor premium&nbsp;</strong></li><li><strong>1kg de azucar</strong></li><li><strong>3 atunes margarita 140g</strong></li><li><strong>1kg de leche latti 1kg</strong></li><li><strong>1kg de queso duro&nbsp;</strong></li><li><strong>250g de queso tipo paisa&nbsp;</strong></li><li><strong>250g de jamón de pavo</strong></li><li><strong>500g de salchicha de pollo&nbsp;</strong></li><li><strong>500g de mortadela de pollo rebanada&nbsp;</strong></li><li><strong>1/2 Carton de huevos</strong></li><li><strong>1 Paquete de Galleta oreo de chocolate de Tubo</strong></li></ol>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-08-01T18:24:36.667Z",
    //         "galeria": "[\"64684941664752251.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "94691931664752244.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo Mercafruver Cool ",
    //         "oferta": "[\"Fixed\",80,\"2025-12-31\"]",
    //         "precio": 85.6,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-mercafruver-cool-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQ39r8Qd-ImQw5TzswD": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-aue-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Afeitadora Gillette 3 hojillas</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-06-10T09:24:50.445Z",
    //         "galeria": "[\"50842521609608949.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "98526021609608948.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Afeitadora Gillette 3 hojillas",
    //         "oferta": "",
    //         "precio": 2.12,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 4,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "afeitadora-gillette-3-hojillas",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NBTxui7Nq1eRsVbHygT": {
    //         "categoria": "accesorios-de-cumpleanos-",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-09-08T21:18:09.837Z",
    //         "galeria": "[\"80666201668697998.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "66245401668697956.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Plato Nro 9 X20 Unid",
    //         "oferta": "",
    //         "precio": 1.53,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "plato-nro-9-x20-unid",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NHjffQBPIF6U2nEz3O_": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-28T20:34:54.961Z",
    //         "galeria": "[\"32953661669394773.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "70791501669394772.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Cepillo Colgate ",
    //         "oferta": "[\"Fixed\",1.13,\"2025-12-31\"]",
    //         "precio": 1.2091,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cepillo-colgate-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N27Ra1pF1yssFSWc_pt": {
    //         "categoria": "pan",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-19T13:48:34.242Z",
    //         "galeria": "[\"77143731652630379.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "989141653559092.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Pan De Hamburguesa rey X 6 Unidades ",
    //         "oferta": "[\"Fixed\",2.15,\"2025-01-31\"]",
    //         "precio": 2.3005,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pan-de-hamburguesa-rey-x-6-unidades-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MWo0tuQ5zjqt7BPsmPJ": {
    //         "categoria": "articulos-de-belleza",
    //         "costo": "",
    //         "descripcion": "Removedor valmy 60ml",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-03-27T14:12:35.518Z",
    //         "galeria": "[\"8646281616854358.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "74949161616854354.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Removedor Valmy 50ml",
    //         "oferta": "",
    //         "precio": 2.21,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "removedor-valmy-50ml",
    //         "video": "[]",
    //         "views": 5
    //     },
    //     "-NH06aQDWoOSJ_kKpEW4": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T19:34:21.766Z",
    //         "galeria": "[\"32834891668613563.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "7811641668613562.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Saltisimas 4 Tacos",
    //         "oferta": "[\"Fixed\",1.57,\"2025-12-31\"]",
    //         "precio": 1.6799,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "saltisimas-4-tacos",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NIggfWSvQ4TaOk2VTOZ": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "descripcion": "<p>Apio España 250gr </p>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Paquete\":[{\"Paquete  200gr\":1}]}]",
    //         "estado": true,
    //         "fecha_create": "2022-12-07T13:07:16.821Z",
    //         "galeria": "[\"34541691671554478.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "74836551671554476.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Apio España",
    //         "oferta": "[\"Disccount\",10,\"2025-02-28\"]",
    //         "precio": 1,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "apio-espana",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OGgWm1RV5NG5udcykBx": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-01-16T00:33:01.251Z",
    //         "galeria": "[\"67588681736987582.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "75148011736987581.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Chupetas Bonbonbun X 6 Unidades ",
    //         "oferta": "[\"Fixed\",1.57,\"2025-12-31\"]",
    //         "precio": 1.6799,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "chupetas-bonbonbun-x-6-unidades-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MWK92Hn6Ezc-EqaaNpq": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "Jabón en barra las llaves para bebe",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-03-21T14:19:56.418Z",
    //         "galeria": "[\"14735341616336400.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "87338191616336396.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabón En Barra Las llaves para bebé ",
    //         "oferta": "",
    //         "precio": 0.86,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-en-barra-las-llaves-para-bebe-",
    //         "video": "[]",
    //         "views": 14
    //     },
    //     "-O7ozd2TuNvxORjQoWP0": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-09-27T19:39:10.976Z",
    //         "galeria": "[\"36818251727465951.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "67917141727465950.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Cepillo Lavar  Con Asa Palmareal",
    //         "oferta": "",
    //         "precio": 2.42,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cepillo-lavar--con-asa-palmareal",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPtRPqTy_8PE33ZYEkg": {
    //         "categoria": "pollo-criollo",
    //         "costo": "",
    //         "descripcion": "<b>Muslos de Pollo</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"1kg arreglados sin cuero\":4.6545,\"1kg enteros con cuero\":4.6545}]}]",
    //         "estado": true,
    //         "fecha_create": "2020-12-31T15:36:41.238Z",
    //         "galeria": "[\"926681609429003.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "19681411609429000.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Muslo de Pollo",
    //         "oferta": "[\"Fixed\",4.35,\"2025-12-31\"]",
    //         "precio": 4.6545,
    //         "prox": false,
    //         "sales": 14,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "muslo-de-pollo",
    //         "video": "[]",
    //         "views": 307
    //     },
    //     "-Mi7u6qBZRF2grxbKrEs": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "Lenteja Bebé Pantera 500g&nbsp;",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-05-18T11:43:34.593Z",
    //         "galeria": "[\"2662701630089542.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "8632221630089536.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Lenteja  Pantera 500g ",
    //         "oferta": "[\"Fixed\",2.24,\"2025-01-31\"]",
    //         "precio": 2.3968,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "lenteja--pantera-500g-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mwvs4CnAcLI4Q8wetCL": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Aroma\":[\"Active emotion\"]}]",
    //         "estado": true,
    //         "fecha_create": "2023-06-08T12:40:31.288Z",
    //         "galeria": "[\"22552071645977029.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "35681271645977024.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Desodorante Rexona En barra 45gr",
    //         "oferta": "",
    //         "precio": 4.62,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "desodorante-rexona-en-barra-45gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQKpZiZeu2mOmHA5BIQ": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Servilleta Z Pequeña</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-06T03:55:28.712Z",
    //         "galeria": "[\"89962501609905358.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "59239131609905328.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Servilleta Z Pequeña 160",
    //         "oferta": "",
    //         "precio": 1.03,
    //         "prox": false,
    //         "sales": 1,
    //         "stock": 40,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "servilleta-z-pequena-160",
    //         "video": "[]",
    //         "views": 10
    //     },
    //     "-NFy0uKfIJcJkbXEv3Yp": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Cantidad\":[{\"250gr\":2.6215,\"300gr\":3.1458,\"500gr\":5.243}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-07-09T14:00:25.460Z",
    //         "galeria": "[\"55806951714963387.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "89529451714963386.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Jamón De Espalda ",
    //         "oferta": "[\"Fixed\",2.45,\"2025-12-31\"]",
    //         "precio": 2.6215,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jamon-de-espalda-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQ2zdicInUiaXfAwONZ": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-aue-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Lavaplatos crema axion 230gr</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-11-16T19:44:55.230Z",
    //         "galeria": "[\"90329831609606011.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "38185501609606010.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Lavaplatos Crema Axion 235 g",
    //         "oferta": "",
    //         "precio": 1.34,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "lavaplatos-crema-axion-235-g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OK7TYERBtsbEwzztlVu": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-27T17:35:19.884Z",
    //         "galeria": "[\"49315261740677722.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "33363541740677721.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabón Safeguard 130g",
    //         "oferta": "",
    //         "precio": 1.12,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-safeguard-130g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NmwF55v96X6Ld8Q6x7Z": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-12-30T19:43:01.281Z",
    //         "galeria": "[\"1994571703965384.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "808671703965380.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Primor Extraextra Especial 1kg corta",
    //         "oferta": "",
    //         "precio": 1.64,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "primor-extraextra-especial-1kg-corta",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPtoXLUx3J6hZIrmObo": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-lgt-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Cheese Tris XXL 450g</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2020-12-31T17:22:04.020Z",
    //         "galeria": "[\"1437581609435325.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "41827331609435323.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Cheese Tris XXL",
    //         "oferta": "",
    //         "precio": 5.03,
    //         "prox": false,
    //         "sales": 1,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cheese-tris-xxl",
    //         "video": "[]",
    //         "views": 5
    //     },
    //     "-MPtsqHPUGkyZpwmiOqw": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-lgt-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Pepito XXL 180gr</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2020-12-31T17:40:29.045Z",
    //         "galeria": "[\"76411671609436451.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "44343911609436428.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Pepito XXL",
    //         "oferta": "",
    //         "precio": 2.37,
    //         "prox": false,
    //         "sales": 2,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pepito-xxl",
    //         "video": "[]",
    //         "views": 3
    //     },
    //     "-NmMxzK8jyvIw-aGOoSP": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-12-23T18:37:36.878Z",
    //         "galeria": "[\"13453561703356657.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "7477701703356655.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Ace regular 2kg",
    //         "oferta": "",
    //         "precio": 8.56,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MibA-pWgVGyGj5MJaTN": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-09-02T15:41:20.522Z",
    //         "galeria": "[\"40833761630597286.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "22666241630597279.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Avena Quaker 800gr",
    //         "oferta": "",
    //         "precio": 2.84,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "avena-quaker-800gr",
    //         "video": "[]",
    //         "views": 6
    //     },
    //     "-MbHIcX9_1Isp8ZOnyPi": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-10-24T10:33:31.335Z",
    //         "galeria": "[\"77937421739044928.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "27783161739044894.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabón Bonaropa Xtra Tech Antibacterial 3kg",
    //         "oferta": "[\"Fixed\",7.4,\"2025-12-31\"]",
    //         "precio": 7.918,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-bonaropa-xtra-tech-antibacterial-3kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NXdmtgbMT-5I492stxr": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-06-11T09:31:11.869Z",
    //         "galeria": "[\"18612881686475872.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "52137271686475871.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Salsa Ajo Iberia 150",
    //         "oferta": "",
    //         "precio": 1.82,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salsa-ajo-iberia-150",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NIEoaWkKqOIbVk31C5W": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-09T09:20:27.640Z",
    //         "galeria": "[\"89321691669933982.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "34501781669933980.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Lavaplatos Agente x 500g",
    //         "oferta": "[\"Fixed\",1.09,\"2025-12-31\"]",
    //         "precio": 1.1663,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "lavaplatos-agente-x-500g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MZK4-VjW4QOW_eDl8TJ": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-04-27T20:45:03.234Z",
    //         "galeria": "[\"86403481619556304.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "748381619556303.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Vanish Blanco Total 450ml",
    //         "oferta": "",
    //         "precio": 1.795,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "vanish-blanco-total-450ml",
    //         "video": "[]",
    //         "views": 6
    //     },
    //     "-MQKceC9HLrqM511ZDpm": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Rikesa 200g</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-06T02:59:29.562Z",
    //         "galeria": "[\"52784281609901973.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "53052971609901969.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Rikesa cheddar 200g",
    //         "oferta": "",
    //         "precio": 3.26,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "rikesa-cheddar-200g",
    //         "video": "[]",
    //         "views": 2
    //     },
    //     "-MQJqtTg_DIQPigaM1J6": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Compota Chiqui Chups 125g</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-03-02T14:16:00.650Z",
    //         "galeria": "[\"4024091609888924.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "7260821609888910.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Compota Chiqui Chups 125g",
    //         "oferta": "[\"Fixed\",0.95,\"2025-01-31\"]",
    //         "precio": 1.0165,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "compota-chiqui-chups-125g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NCLZBHZl17xLEXM075B": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-09-19T16:24:20.806Z",
    //         "galeria": "[\"655181663604662.jpg\",\"18446531663604664.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "16678801663604660.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Desodorante Gillette Rollon 60 g",
    //         "oferta": "",
    //         "precio": 2.71,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "desodorante-gillette-rollon-60-g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NNvMHiyjBPrL7ap4cqr": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-07-08T17:11:23.471Z",
    //         "galeria": "[\"59646501676033206.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "40909911676033204.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabon Lux 125g",
    //         "oferta": "[\"Fixed\",1.15,\"2025-01-31\"]",
    //         "precio": 1.2305,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-lux-125g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MkIM7U2QtjxML2OWeXR": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "Maiz Para Cotufa Pantera 500g",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-11-17T21:42:23.928Z",
    //         "galeria": "[\"32615331632412402.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "60709961632412389.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Maiz para cotufa Pantera 500g",
    //         "oferta": "[\"Fixed\",1.56,\"2025-12-31\"]",
    //         "precio": 1.6692,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "maiz-para-cotufa-pantera-500g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NrPMXkEduOuh5ZYWx2h": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-02-24T09:06:59.848Z",
    //         "galeria": "[\"90601591708765620.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "8850131708765619.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Champiñones Tigo 425g",
    //         "oferta": "",
    //         "precio": 1.83,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "champinones-tigo-425g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N48WsYS5tbr6APrRPhO": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-06-09T17:33:46.077Z",
    //         "galeria": "[\"57115601654796026.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "187641654796025.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Chupon  Palma Real Palmareal",
    //         "oferta": "",
    //         "precio": 3.65,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "chupon--palma-real-ppocet",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NOV15Q_K51zAOTf2Yy_": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-02-17T15:39:39.916Z",
    //         "galeria": "[\"18854991676648704.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "54782161676648703.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Gelatina montalvan 90g",
    //         "oferta": "",
    //         "precio": 1.3,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "gelatina-montalvan-90g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NH96JutbPTXa1PCoBeB": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-11-18T09:41:26.474Z",
    //         "galeria": "[\"88245411668764486.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "47254941668764484.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Aceite Vatel Soya 1 lit",
    //         "oferta": "",
    //         "precio": 3.76,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "aceite-vatel-soya-1-lit",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NqlHtsXA0tIWt6vCq0t": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "descripcion": "<p>Guineo verde por kg</p>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-02-16T09:42:18.255Z",
    //         "galeria": "[\"3339141708076539.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "42801811708076538.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Guineo Verde",
    //         "oferta": "[\"Disccount\",15,\"2025-02-28\"]",
    //         "precio": 1.1,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "guineo-verde",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NH0FQyPJPdzJeqVrqtW": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-06-30T18:39:09.762Z",
    //         "galeria": "[\"33782951668615879.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "99127671668615864.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Mermelada De Fresa 300g",
    //         "oferta": "[\"Fixed\",3.05,\"2024-12-31\"]",
    //         "precio": 3.2635,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "mermelada-de-fresa-200g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O0yoaPVhsEZzETZTwbc": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-07-04T17:37:15.731Z",
    //         "galeria": "[\"74466111720114636.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "29077041720114635.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Leche Vitta 900g",
    //         "oferta": "",
    //         "precio": 9.2,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "leche-vitta-900g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NyzjBX_NyksgIGGwJOf": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-05-28T15:06:05.849Z",
    //         "galeria": "[\"1726991716908766.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "59846381716908765.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Azucar morena montalban 1kg",
    //         "oferta": "",
    //         "precio": 2.53,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "azucar-morena-montalban-1kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NDloNrqv7odOEY_190f": {
    //         "categoria": "articulos-de-limpieza",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-10-07T09:40:41.792Z",
    //         "galeria": "[\"97749491665135643.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "14032211665135643.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Esponja De Brillo Tidy House ",
    //         "oferta": "",
    //         "precio": 0.4,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "esponja-de-brillo-tidy-house-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N27Sk0N4X8lw0j4EdR-": {
    //         "categoria": "pan",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-19T13:48:34.242Z",
    //         "galeria": "[\"6994431652630682.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "4990781652630676.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Pan De Perro Jumbo El rey ",
    //         "oferta": "[\"Fixed\",2.15,\"2025-01-31\"]",
    //         "precio": 2.3005,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pan-de-perro-jumbo-el-rey-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NvsEP7U_xD5uBaTBgJ-": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-04-19T21:22:52.101Z",
    //         "galeria": "[\"2883981713561772.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "2734701713561771.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Gel Rrolda 250g",
    //         "oferta": "",
    //         "precio": 2.91,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "gel-rrolda-250g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPszEC5J3q4By_etXWg": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "<b>Queso Blanco Especial</b>",
    //         "detalles": "[{\"title\":\"Marca\",\"value\":\"Agua Linda\"}]",
    //         "especificaciones": "[{\"Peso\":[{\"250gr Rebanado \":2.14,\"500gr Rebanado\":4.28,\"250gr Entero\":2.14,\"500gr Entero\":4.28,\"300gr rebanado\":2.568}]}]",
    //         "estado": true,
    //         "fecha_create": "2022-05-20T20:36:36.298Z",
    //         "galeria": "[\"4403371609421353.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "70316981609421344.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Queso Blanco Pasteuriszado Agua Linda",
    //         "oferta": "[\"Fixed\",2,\"2025-12-31\"]",
    //         "precio": 2.14,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "queso-blanco-pasteuriszado-agua-linda",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NV6RZnmOZajSJfjYi5f": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-05-10T20:54:44.434Z",
    //         "galeria": "[\"69526301683752085.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "56428561683752083.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Leche parmalat max 750g",
    //         "oferta": "",
    //         "precio": 7.32,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "leche-parmalat-max-750g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPk5sZASq_wGiINE_T5": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "<ol><li><b>2Kg Bistec</b></li><li><b>2Kg Strogonof</b></li><li><b>2Kg Mechar</b></li><li><b>2Kg Guisar</b></li><li><b>2Kg Molida</b></li><li><b>2kg chuleta de Cerdo</b></li></ol>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-08-01T18:24:36.667Z",
    //         "galeria": "[\"6025711641426620.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "426441641426616.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo Carne Y Cerdo 12kg",
    //         "oferta": "[\"Fixed\",83,\"2025-12-31\"]",
    //         "precio": 88.81,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-carne-y-cerdo-12kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N9Xrr--J6808nx0v5KH": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-08-15T18:58:48.193Z",
    //         "galeria": "[\"1221581667835592.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "53048341667835591.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Kerosene osirex 500ml",
    //         "oferta": "",
    //         "precio": 4.04,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "kerosene-osirex-500ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MgVShH7vyuGW18OSar2": {
    //         "categoria": "higiene-personal",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-06-15T14:17:18.122Z",
    //         "galeria": "[\"89418041628337263.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "32297371628337262.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Alcohol Antiséptico Santa Teresa 0.70ml ",
    //         "oferta": "",
    //         "precio": 2.87,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "alcohol-antiseptico-santa-teresa-0-70ml-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NFv-LUzzp2_j8AnoX60": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-11-03T01:00:57.842Z",
    //         "galeria": "[\"71085461667753694.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "22521851667753693.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Harina Blanca Flor Leudante",
    //         "oferta": "",
    //         "precio": 1.33,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "harina-blanca-flor-leudante",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MlGPOeafZY891IK8Ddd": {
    //         "categoria": "mascotas",
    //         "costo": "",
    //         "descripcion": "Alimentos Para Cachorros Pedigree Sabores Surtidos 100g",
    //         "detalles": "",
    //         "especificaciones": "[{\"Sabor \":[\"Carne\",\"Pollo\"]}]",
    //         "estado": true,
    //         "fecha_create": "2023-06-30T18:39:09.762Z",
    //         "galeria": "[\"67535231633453446.jpg\",\"97549811633453446.jpg\",\"27665161633453446.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "12829801633453440.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Alimentos Para Cachorros Pedigree Sabores Surtidos 100g",
    //         "oferta": "[\"Fixed\",1.11,\"2023-12-31\"]",
    //         "precio": 1.2,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "alimentos-para-cachorros-pedigree-sabores-surtidos-100g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQKliLZTc7fU5KLDUnE": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Salsa Para Pizza Heinz 480g</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-06T03:39:06.487Z",
    //         "galeria": "[\"76712451609904349.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "30534441609904346.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Salsa Para Pizza Heinz 480g",
    //         "oferta": "",
    //         "precio": 4.06,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salsa-para-pizza-heinz-480g",
    //         "video": "[]",
    //         "views": 3
    //     },
    //     "-MWg0h3JcI4V1QUXIQSC": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "Chupetas bom bom bum surtidas tropical 24 unidades",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-03-26T00:53:51.101Z",
    //         "galeria": "[\"79407811616720069.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "30632001616720013.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Chupetas Bom Bom Bum Surtido  De 24 Uniunidades",
    //         "oferta": "",
    //         "precio": 3.18,
    //         "prox": false,
    //         "sales": 2,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "chupetas-bom-bom-bum-surtido--de-24-uniunidades",
    //         "video": "[]",
    //         "views": 4
    //     },
    //     "-OGfwGuFEPQJvySC6sQq": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-01-15T21:49:07.369Z",
    //         "galeria": "[\"46216941736977750.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "45632391736977747.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Mostaza Fritz 1kg",
    //         "oferta": "[\"Fixed\",4.78,\"2025-12-31\"]",
    //         "precio": 5.1146,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "mostaza-fritz-1kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O4u-F1vqym_BEUYESBE": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-08-22T12:14:52.175Z",
    //         "galeria": "[\"43278551724328771.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "33601001724328770.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Mayonesa Emvesa 200gr Venezolana 💯",
    //         "oferta": "[\"Fixed\",0.65,\"2025-01-31\"]",
    //         "precio": 0.6955,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "mayonesa-emvesa-200gr-venezolana-💯",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OIaJcg7WhyEn6z7RP7q": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T16:09:15.635Z",
    //         "galeria": "[\"40978021739030956.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "35945731739030955.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Galleta Capri Colombiana 288g",
    //         "oferta": "[\"Fixed\",2.28,\"2025-12-31\"]",
    //         "precio": 2.4396,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "galleta-capri-colombiana-288g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O9BNgJfITAuAawS9wbT": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-14T18:55:34.198Z",
    //         "galeria": "[\"24061021728932135.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "9562971728932134.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Mayonesa Mavesa 445g X 12 Unidades ",
    //         "oferta": "[\"Fixed\",38.21,\"2025-01-31\"]",
    //         "precio": 40.88,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "mayonesa-mavesa-445g-x-12-unidades-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPt0u2aqhDHjaHzIbmR": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "<b>Queso Duro Zuliano</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"500gr\":3.959,\"1kg\":7.918}]}]",
    //         "estado": true,
    //         "fecha_create": "2023-12-04T15:13:21.902Z",
    //         "galeria": "[\"26762981609422052.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "92836271609422047.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Queso Duro ",
    //         "oferta": "[\"Fixed\",3.7,\"2025-12-31\"]",
    //         "precio": 3.959,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "queso-duro-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQ31iQqYEtAS-y932DK": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-aue-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Suavizante suavitel 180cm</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2022-04-03T14:11:47.331Z",
    //         "galeria": "[\"2097501681564801.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "52263171681564799.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Suavizante suavitel 180cm",
    //         "oferta": "[\"Fixed\",0.55,\"2023-07-31\"]",
    //         "precio": 0.7,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "suavizante-suavitel-180cm",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MY8bnyjI1-W5jN-w6WB": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "<font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">Crema de leche Tachira grande</font></font>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-09-11T21:14:42.739Z",
    //         "galeria": "[\"78695931618290358.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "76302771618290357.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Crema De Leche Tachira Grande",
    //         "oferta": "[\"Fixed\",9.05,\"2025-12-31\"]",
    //         "precio": 9.6835,
    //         "prox": false,
    //         "sales": 1,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "crema-de-leche-tachira-grande",
    //         "video": "[]",
    //         "views": 4
    //     },
    //     "-NnVHOQtDTwgg8pHIWRc": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-01-06T19:39:25.814Z",
    //         "galeria": "[\"51629081704569967.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "47751711704569966.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Suavizante Downy 1400cc",
    //         "oferta": "",
    //         "precio": 6,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "suavizante-downy-1400cc",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPtSHL5kBTrS-Ae4HPd": {
    //         "categoria": "pollo-criollo",
    //         "costo": "",
    //         "descripcion": "<b>Churrasco de Muslos&nbsp;</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"1kg\":8.881}]}]",
    //         "estado": true,
    //         "fecha_create": "2020-12-31T15:40:27.172Z",
    //         "galeria": "[\"13823641609429230.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "53745631609429226.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Churrasco de Muslo",
    //         "oferta": "[\"Fixed\",8.3,\"2025-12-31\"]",
    //         "precio": 8.881,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "churrasco-de-muslo",
    //         "video": "[]",
    //         "views": 24
    //     },
    //     "-NFJv0Eq_VC5wpKpCCis": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-10-26T15:32:05.091Z",
    //         "galeria": "[\"85095651667835870.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "54727981667835869.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Rosal X12 400 Hojas",
    //         "oferta": "",
    //         "precio": 6.18,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "rosal-x12-400-hojas",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQ9EQqxZBZ8u0zLZ-Nt": {
    //         "categoria": "bebes-",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-yha-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Jabón Little Angels ×3 Para Bebés</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-02-15T21:37:29.411Z",
    //         "galeria": "[\"30481951609710811.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "3929411609710809.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabón Little Angels X3 Para Bebés",
    //         "oferta": "",
    //         "precio": 2.2,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 3,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-little-angels-x3-para-bebes",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NV-YnVTwKhTYeKfVjuE": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Fragancia \":[\"Legacy\",\"Kaos\",\"Revo\",\"Sport\"]}]",
    //         "estado": true,
    //         "fecha_create": "2023-05-09T12:49:22.268Z",
    //         "galeria": "[\"28021241683636564.jpg\",\"86066761683636635.jpg\",\"5256861683636635.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "53688521683636563.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Desodorante Apex Caballero 71g",
    //         "oferta": "[\"Fixed\",3.05,\"2023-12-31\"]",
    //         "precio": 3.15,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "desodorante-apex-caballero-71g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NCLYOU4Se-o4xlH6tFn": {
    //         "categoria": "higiene-personal",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-09-19T16:21:00.650Z",
    //         "galeria": "[\"7235901663604463.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "9953501663604460.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Pantene Ampollas tratamiento ",
    //         "oferta": "",
    //         "precio": 6.85,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pantene-ampollas-tratamiento-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OCj5IALhahZfhdNn0Wz": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-27T19:28:59.362Z",
    //         "galeria": "[\"29172621732735741.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "29233721732735740.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Maxcoco X 10 Unidades",
    //         "oferta": "",
    //         "precio": 4.68,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 50,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "maxcoco-x-10-unidades",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Nrqk3WLfLn3ScmmM1PL": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-02-29T21:23:30.692Z",
    //         "galeria": "[\"20973811709241812.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "64859601709241810.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Adobo La Ccomadre 200g",
    //         "oferta": "",
    //         "precio": 1.9,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 50,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "adobo-la-ccomadre-200g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQ2zzey-q4AvRBZCZmn": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-aue-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Lavaplatos crema llaves 250gr</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-02T16:48:20.803Z",
    //         "galeria": "[\"65113051609606101.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "15879361609606099.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Lavaplatos crema llaves 250gr",
    //         "oferta": "",
    //         "precio": 2.41,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 50,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "lavaplatos-crema-llaves-250gr",
    //         "video": "[]",
    //         "views": 4
    //     },
    //     "-MVIsd46qdwqlbX9-V-B": {
    //         "categoria": "reposteria",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-03-08T22:08:28.563Z",
    //         "galeria": "[\"41402801615241312.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "81394991615241308.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Ponque 2",
    //         "oferta": "",
    //         "precio": 0,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "ponque-2",
    //         "video": "[]",
    //         "views": 1
    //     },
    //     "-Mg1BiB68eBCJHM5VpJb": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "Paquete Nuthos Mezcla Divertida 200g",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T19:27:21.853Z",
    //         "galeria": "[\"15483581627829491.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "46394041627829485.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Nuthos Mezcla Divertida 200g",
    //         "oferta": "[\"Fixed\",1.83,\"2025-12-31\"]",
    //         "precio": 1.9581,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "nuthos-mezcla-divertida-200g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPl4v1n9ZUkzyKBG_sK": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "<ol><li><b>2Kg Lagarto</b></li><li><b>2Kg Callo</b></li><li><b>2Kg Patas de Res</b></li></ol>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2020-12-30T00:41:23.092Z",
    //         "galeria": "[\"88144231641426679.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "34115361641426677.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo Mondonguero",
    //         "oferta": "[\"Fixed\",24,\"2025-12-31\"]",
    //         "precio": 25.68,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-mondonguero",
    //         "video": "[]",
    //         "views": 20
    //     },
    //     "-MWnwh-MJxqEFkxCGfHd": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "Desodorante every night surtido 90g",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-03-27T13:49:53.911Z",
    //         "galeria": "[\"13968431616852995.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "9701241616852993.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Desodorante Every Night Surtido 90g",
    //         "oferta": "",
    //         "precio": 2.15,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "desodorante-every-night-surtido-90g",
    //         "video": "[]",
    //         "views": 17
    //     },
    //     "-NIIMzfAgLz9oUCSkieL": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-12-02T15:06:34.136Z",
    //         "galeria": "[\"41025501669993593.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "7607611669993592.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Adobo Completo Eureka",
    //         "oferta": "",
    //         "precio": 2.03,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "adobo-completo-eureka",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O46K18njtEErPkv1-VO": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-08-12T16:03:00.577Z",
    //         "galeria": "[\"44775071723478581.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "72865421723478580.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Chocolate Aroma 500gr",
    //         "oferta": "[\"Fixed\",4.3,\"2025-01-31\"]",
    //         "precio": 4.601,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "chocolate-aroma-500gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NHf7vVdm9FPXuBcaaDY": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-11-24T19:35:54.347Z",
    //         "galeria": "[\"44001741669318555.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "2130131669318554.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Arroz Mary Superior 1kg",
    //         "oferta": "",
    //         "precio": 1.324,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "arroz-mary-superior-1kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MmKDJjF85tCRKAFg6b0": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-10-18T21:05:30.451Z",
    //         "galeria": "[\"30781101634591132.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "58931391634591131.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Baygon Doble Ppoder 154ml",
    //         "oferta": "",
    //         "precio": 4.88,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "baygon-doble-ppoder-154ml",
    //         "video": "[]",
    //         "views": 1
    //     },
    //     "-Mt8_Bu5d-rQJd_b_y5H": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2022-01-11T14:39:53.870Z",
    //         "galeria": "[\"79471641911995.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "38614731641911994.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Shampoo Anticaspa Xen 400ml",
    //         "oferta": "[\"Fixed\",3.2,\"2024-01-31\"]",
    //         "precio": 3.424,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "shampoo-anticaspa-xen-400ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O-jqnbtpqCCvErLoftA": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-06-19T09:36:52.836Z",
    //         "galeria": "[\"62141481718789814.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "707991718789813.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo Surtido 2",
    //         "oferta": "[\"Fixed\",76,\"2025-12-31\"]",
    //         "precio": 81.32,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-surtido-2",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NnyPOE_I9hvrv9_uTFp": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-01-12T16:15:24.753Z",
    //         "galeria": "[\"12982081705075380.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "65803701705075376.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Colgate Luminous white 140ml",
    //         "oferta": "[\"Fixed\",7.62,\"2024-12-31\"]",
    //         "precio": 8.1534,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "colgate-luminous-white-140ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NAjLZGhaxUa_eBlm8et": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"250gr Rebanado\":2.1079,\"300gr Rebanado\":2.52948,\"500gr Rebanado\":4.2158,\"250gr Entero\":2.1079,\"300gr Entero\":2.52948,\"500gr Entero\":4.2158}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-01-13T15:16:25.055Z",
    //         "galeria": "[\"80891801661873046.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "45346761661873044.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Queso Especial Tipo Paisa",
    //         "oferta": "[\"Fixed\",1.97,\"2025-12-31\"]",
    //         "precio": 2.1079,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "queso-especial-tipo-paisa",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mz0u7v76DpbzD6TIRVm": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-12-07T10:13:58.390Z",
    //         "galeria": "[\"92896561648225717.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "80666981648225715.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Melocotones Nutivia 820g",
    //         "oferta": "[\"Fixed\",3.9,\"2024-12-31\"]",
    //         "precio": 4.173,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "melocotones-nutivia-820g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQKqGAJi0piEom_yCZs": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Toddy 200gr</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-18T15:23:53.923Z",
    //         "galeria": "[\"14766391609905541.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "31456201609905539.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Toddy 200gr",
    //         "oferta": "",
    //         "precio": 3.43,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 50,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "toddy-200gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MSwhahcOmCeTeCRXUHc": {
    //         "categoria": "mascotas",
    //         "costo": "",
    //         "descripcion": "<b>Perrarina Perrosky 800g</b>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2023-03-02T14:16:00.650Z",
    //         "galeria": "[\"98403951612705064.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "12323431612705056.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Perrarina Perrosky",
    //         "oferta": "",
    //         "precio": 1.6906,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "perrarina-perrosky",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N4BvZ7mzAIBRg9D24Mz": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-01-16T00:33:01.251Z",
    //         "galeria": "[\"42945851673643079.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "44038911673643078.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Saltin Noel 5 Tacos ",
    //         "oferta": "[\"Fixed\",2.68,\"2025-12-31\"]",
    //         "precio": 2.8676,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "saltin-noel-5-tacos-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O5TPm2H401SGEXVNNjE": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "<p>Lleva</p><ul><li>1 Cloro 1ltr</li><li>1 Detergente líquido ½ ltr</li><li>1Lavaplatos liquido ½ ltr</li><li>1 Cera ½ ltr</li><li>2 aromatizante ½ ltr</li></ul>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-08-29T13:54:29.632Z",
    //         "galeria": "[\"13628601724939705.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "4426561724939668.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo limpieza ",
    //         "oferta": "",
    //         "precio": 5.59,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-limpieza-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OKDRF9bTvz-k7x_uo6o": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-28T21:22:59.985Z",
    //         "galeria": "[\"3345011740777783.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "8924521740777780.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Crema Dental Fluo Carden 50g + Cepillo",
    //         "oferta": "[\"Fixed\",1.25,\"2025-12-31\"]",
    //         "precio": 1.3375,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "crema-dental-fluo-carden-50g---cepillo",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPj05ayRkmeNpfLPF9k": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "<ol><li><b>1Kg&nbsp;Bistec</b></li><li><b>1Kg&nbsp;Mechar</b></li><li><b>1Kg&nbsp;Molida</b></li><li><b>1Kg&nbsp;Costilla de res</b></li></ol>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-11-21T10:26:20.034Z",
    //         "galeria": "[\"54589461720175024.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "88998751720175023.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo Carne 4kg",
    //         "oferta": "[\"Fixed\",24,\"2025-12-31\"]",
    //         "precio": 25.68,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-carne-4kg",
    //         "video": "[]",
    //         "views": 2
    //     },
    //     "-MQPe0Gv39xdD0SQGGnl": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "<b>Limpia Hornos Osirex</b>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-07T02:23:35.703Z",
    //         "galeria": "[\"3562041609986216.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "14448571609986215.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Limpia Hornos Osirex 470gr",
    //         "oferta": "",
    //         "precio": 5.49,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "limpia-hornos-osirex-470gr",
    //         "video": "[]",
    //         "views": 2
    //     },
    //     "-O1MmzrBtB-yn-nx59tt": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Paquete \":[\"370gr\"]}]",
    //         "estado": true,
    //         "fecha_create": "2024-07-09T14:00:25.460Z",
    //         "galeria": "[\"9417381720533645.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "27502541720533624.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Lomo cerdo Ahumado",
    //         "oferta": "[\"Fixed\",3.95,\"2025-12-31\"]",
    //         "precio": 4.2265,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "lomo-cerdo-ahumado",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NHjfy4MElzghNtgGsK8": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-07-08T17:11:23.471Z",
    //         "galeria": "[\"43692091669394849.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "64437751669394848.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Colgate Menta 100gr",
    //         "oferta": "[\"Fixed\",2.73,\"2025-01-31\"]",
    //         "precio": 2.9211,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "colgate-menta-100gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NIEqql9E0u3iC6qtFNy": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Fabuloso \":[\"Frescura activa bebé\",\"3 en 1\"]}]",
    //         "estado": true,
    //         "fecha_create": "2022-12-01T22:42:52.841Z",
    //         "galeria": "[\"69084651669934574.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "10455021669934573.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Fabuloso Sobre 180ml",
    //         "oferta": "",
    //         "precio": 0.38,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "fabuloso-sobre-180ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NEMttzY7n5r1nHUZGJv": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2022-10-14T19:10:22.122Z",
    //         "galeria": "[\"88057321665774624.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "83451201665774623.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Cocosette 50g",
    //         "oferta": "",
    //         "precio": 1,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cocosette-50g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPl72YnVNEvQPnohuzr": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "<ol><li><strong>2Kg Harina Pan</strong></li><li><strong>2Kg Arroz tipo A</strong></li><li><strong>2Kg Pasta Corta o Larga</strong></li><li><strong>1Kg Harina de Trigo</strong></li><li><strong>1Kg Sal</strong></li><li><strong>1Kg Azúcar</strong></li><li><strong>1L Aceite</strong></li><li><strong>200gr Café Concafé  o kiwa</strong></li><li><strong>1Kg Bistec</strong></li><li><strong>1Kg Molida</strong></li><li><strong>1Kg Mechar o Guisar</strong></li><li><strong>1Kg Costilla</strong></li><li><strong>1Kg Muslos o Alas</strong></li><li><strong>1Kg Pechuga</strong></li><li><strong>1/2kg Queso Duro zuliano</strong></li><li><strong>1/2kgQueso tipo Paisa</strong></li><li><strong>1 Crema de Leche 500gr</strong></li><li><strong>1Kg Mortadela Corral</strong></li><li><strong>1/2 Cartón de Huevos</strong></li></ol>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-08-01T18:24:36.667Z",
    //         "galeria": "[\"58507891641404649.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "27205591641404646.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo Mercafruver Mixto",
    //         "oferta": "[\"Fixed\",72,\"2025-12-31\"]",
    //         "precio": 77.04,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-mercafruver-mixto",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPtLBQawc0QGSBeaRDS": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "descripcion": "<b>Carne Pecho de Res</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"500g\":3.852,\"1kg\":7.704}]}]",
    //         "estado": true,
    //         "fecha_create": "2023-05-23T20:30:40.668Z",
    //         "galeria": "[\"10476041609427371.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "49019091609427368.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Carne Pecho",
    //         "oferta": "[\"Fixed\",3.6,\"2025-12-31\"]",
    //         "precio": 3.852,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "carne-pecho",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-McoLIbFfSokNGjDdNpI": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-06-22T15:31:40.149Z",
    //         "galeria": "[\"16767581624375900.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "85233321624375899.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Protectores Diarios Diva 80unid",
    //         "oferta": "",
    //         "precio": 3.13,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "protectores-diarios-diva-80unid",
    //         "video": "[]",
    //         "views": 6
    //     },
    //     "-MV6ZP0EnodMbK7SJJUO": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "<b>Esponja salva uñas izy clean</b>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-03-06T12:44:36.338Z",
    //         "galeria": "[\"96587311615034684.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "50067861615034675.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Esponja Salva Uñas Izy clean ",
    //         "oferta": "",
    //         "precio": 1.27,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "esponja-salva-unas-izy-clean-",
    //         "video": "[]",
    //         "views": 5
    //     },
    //     "-MPw8SzhqpqCcL-0apRO": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-gws-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Galleta Kraker bran</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-01T04:12:44.312Z",
    //         "galeria": "[\"646511609474366.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "26635851609474363.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Galleta Kraker bran",
    //         "oferta": "",
    //         "precio": 2.85,
    //         "prox": false,
    //         "sales": 2,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "galleta-kraker-bran",
    //         "video": "[]",
    //         "views": 4
    //     },
    //     "-NjX3yjZPK28jWEOBUhO": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-29T08:47:52.248Z",
    //         "galeria": "[\"94361911701944215.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "51132181701944212.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Aceituna Rellena Osole 830g",
    //         "oferta": "",
    //         "precio": 6.18,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "aceituna-rellena-osole-830g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NryjUDRyWnk6fzoIBB7": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-03-02T10:37:58.339Z",
    //         "galeria": "[\"23856131709375877.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "30953921709375877.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Ariel Downy 750g",
    //         "oferta": "",
    //         "precio": 4.03,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "ariel-downy-750g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NxY3cTy-ybZaqdzfpKR": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2024-05-10T15:15:04.764Z",
    //         "galeria": "[\"871971715354108.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "90978411715354104.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Queso De Año Mi Vaquita 160g",
    //         "oferta": "[\"Fixed\",5.1,\"2024-12-31\"]",
    //         "precio": 5.457,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "queso-de-ano-mi-vaquita-160g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N27R8JIccb1x12mhLWQ": {
    //         "categoria": "pan",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-19T13:48:34.242Z",
    //         "galeria": "[\"61264431652630261.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "51885461653559161.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Pan De Sandwinch El Rey ",
    //         "oferta": "[\"Fixed\",2.15,\"2025-01-31\"]",
    //         "precio": 2.3005,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pan-de-sandwinch-el-rey-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NMyMkiDIqe-FXpELK_M": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-01-29T16:31:38.375Z",
    //         "galeria": "[\"25107651675009913.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "89052821675009897.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Detergente Líquido Bonaropa Antibacterial 1l",
    //         "oferta": "[\"Fixed\",2.35,\"2025-12-31\"]",
    //         "precio": 2.5145,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "detergente-liquido-bonaropa-antibacterial-1l",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OJJgd-9bjiZSEzsvk4g": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-17T16:16:37.875Z",
    //         "galeria": "[\"41429671739809000.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "79452531739808997.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Galleta Oreo X 6 Surtida",
    //         "oferta": "",
    //         "precio": 2.4,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "galleta-oreo-x-6-surtida",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NQq4t4F37nLVpKctrLN": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-03-18T18:59:43.308Z",
    //         "galeria": "[\"61251771679165984.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "31643251679165982.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Esponja Acero Inoxidable Clean",
    //         "oferta": "",
    //         "precio": 1.03,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "esponja-acero-inoxidable-clean",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MXmt3NS2sdR78u9_YVN": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-10-26T15:40:20.700Z",
    //         "galeria": "[\"45105131617909007.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "47137851617909003.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Gelatina Sonrisa 5 Porciones",
    //         "oferta": "",
    //         "precio": 1.85,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "gelatina-sonrisa-5-porciones",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NLgkCU1GK-LXLEUGyEZ": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Fragancia\":[\"Lavanda\",\"Manzana\",\"Citronela\"]}]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T19:34:21.766Z",
    //         "galeria": "[\"57597481673640595.png\",\"96634691673640595.jpg\",\"29582101673640722.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "58168171673640594.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Desinfectante Brilla King 1l",
    //         "oferta": "[\"Fixed\",0.97,\"2025-12-31\"]",
    //         "precio": 1.0379,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "desinfectante-brilla-king-1l",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NmMCfJsU0ZP0hCuCGOn": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-12-23T15:06:24.926Z",
    //         "galeria": "[\"80585221703343993.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "10194751703343981.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Gillette prestobarba 3 hojillax4",
    //         "oferta": "",
    //         "precio": 6,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "gillette-prestobarba-3-hojillax4",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NIOCSuCPAYrp2iNjgLm": {
    //         "categoria": "pan",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-12-03T18:18:01.861Z",
    //         "galeria": "[\"93398571670091483.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "81379011670091481.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Pan De Hamburguesa X 10 Uniunidades ",
    //         "oferta": "[\"Fixed\",2.44,\"2025-01-31\"]",
    //         "precio": 2.6108,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pan-de-hamburguesa-x-10-uniunidades-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O7OpUuw2JN1acZ7mZko": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-09-22T13:05:07.187Z",
    //         "galeria": "[\"36411611727010307.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "39514511727010306.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Bolsa Aseo 200 Llit X 10 Uniunid",
    //         "oferta": "",
    //         "precio": 3.382,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "bolsa-aseo-200-llit-x-10-uniunid",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O8r-a9goB0inBu89Nvk": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-10T15:18:16.658Z",
    //         "galeria": "[\"84987211728573496.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "68519271728573495.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Lampazo Microfibra Con Palo",
    //         "oferta": "",
    //         "precio": 4.41,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "lampazo-microfibra-con-palo",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MwDqOB9joP_amQDogII": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-05-15T14:42:24.945Z",
    //         "galeria": "[\"87025611645221613.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "74545731645221603.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Samba De Fresa Savoy",
    //         "oferta": "",
    //         "precio": 0.74,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 8,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "samba-de-fresa-savoy",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPtQuT0bUhUyLayPuXv": {
    //         "categoria": "pollo-criollo",
    //         "costo": "",
    //         "descripcion": "<b>Pechuga Entera</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Pechuga\":[{\"entera sin arreglar 1kg\":4.815,\"Entera Arreglada en filet 1kg\":4.815,\"Entera Arreglada para Hallacas 1kg\":4.815}]}]",
    //         "estado": true,
    //         "fecha_create": "2023-02-01T18:29:06.421Z",
    //         "galeria": "[\"8237971609428870.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "22958241609428868.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Pechuga ",
    //         "oferta": "[\"Fixed\",4.5,\"2025-12-31\"]",
    //         "precio": 4.815,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pechuga-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MgVAqB9xV5P_hv8VG_H": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2023-02-27T02:44:40.569Z",
    //         "galeria": "[\"55176381628332580.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "74217401628332577.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Café Aroma 250gr",
    //         "oferta": "[\"Fixed\",3.03,\"2025-12-31\"]",
    //         "precio": 3.2421,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cafe-aroma-250gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NxIg8qWhcYxh32qB3NB": {
    //         "categoria": "licores",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Sabor\":[\"Tinta\"]}]",
    //         "estado": false,
    //         "fecha_create": "2024-11-19T13:48:34.242Z",
    //         "galeria": "[\"4648241715096034.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "29851721715096033.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Sangría La Que Manda",
    //         "oferta": "[\"Fixed\",5.4,\"2025-01-31\"]",
    //         "precio": 5.94,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "sangria-la-que-manda",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NLglsFWW2CM2dNKjIcm": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-01-13T20:17:12.655Z",
    //         "galeria": "[\"5698351673641033.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "26625501673641033.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Limpiador De Vidrios Brilla King",
    //         "oferta": "[\"Fixed\",1,\"2025-01-31\"]",
    //         "precio": 1.07,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "limpiador-de-vidrios-brilla-king",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MY-YkMSMjknsb8NhbhC": {
    //         "categoria": "pan",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-11-12T22:06:44.905Z",
    //         "galeria": "[\"88105421618182015.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "42178611618182015.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Pan Dulce",
    //         "oferta": "[\"Fixed\",2.16,\"2023-12-31\"]",
    //         "precio": 3,
    //         "prox": false,
    //         "sales": 2,
    //         "stock": 98,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pan-dulce",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MiC-4-j-7T1HPB4GgLn": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "Avena en Hojuelas pantera 400g",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-11-17T21:42:23.928Z",
    //         "galeria": "[\"23945201630158212.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "69814961630158196.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Avena en Hojuelas pantera 400g",
    //         "oferta": "[\"Fixed\",1.58,\"2025-12-31\"]",
    //         "precio": 1.6906,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "avena-en-hojuelas-pantera-400g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPtMsGrauHj8_9dxC2x": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "descripcion": "<b>Muchacho Cuadrado</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"500gr\":4.173,\"1kg\":8.346}]}]",
    //         "estado": true,
    //         "fecha_create": "2020-12-31T15:16:50.966Z",
    //         "galeria": "[\"63224601609427813.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "72922181609427810.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Muchacho Cuadrado",
    //         "oferta": "[\"Fixed\",3.9,\"2025-12-31\"]",
    //         "precio": 4.173,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "muchacho-cuadrado",
    //         "video": "[]",
    //         "views": 10
    //     },
    //     "-MRoaAJ2kJYjP2eMfi6_": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<b>Cronch Flakes</b>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-24T13:32:34.720Z",
    //         "galeria": "[\"87405531611495158.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "43879011611495154.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Maizorito Cronch Flakes 300g",
    //         "oferta": "",
    //         "precio": 3.13,
    //         "prox": false,
    //         "sales": 4,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "maizorito-cronch-flakes-300g",
    //         "video": "[]",
    //         "views": 31
    //     },
    //     "-MjK_7BF_47d1df5RoGg": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "Mayonesa Kraft 445g",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-09-11T15:57:57.193Z",
    //         "galeria": "[\"91287381631375884.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "15195451631375876.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Mayonesa Kraft 445g",
    //         "oferta": "",
    //         "precio": 3.81,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "mayonesa-kraft-445g",
    //         "video": "[]",
    //         "views": 2
    //     },
    //     "-MPjOjOVKaCs1fTaWoNX": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "<ol><li><i><b>1Kg Bistec</b></i></li><li><i><b>1Kg Mechar</b></i></li><li><i><b>1Kg Costilla de res</b></i></li><li><i><b>1Kg molida</b></i></li><li><i><b>1Kg Costilla o chuleta de cerdo</b></i></li></ol>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2020-12-29T17:21:43.168Z",
    //         "galeria": "[\"51969981720175065.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "91445391720175064.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo Carne Y Cerdo 5kg",
    //         "oferta": "[\"Fixed\",30,\"2025-12-31\"]",
    //         "precio": 32.1,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-carne-y-cerdo-5kg",
    //         "video": "[]",
    //         "views": 60
    //     },
    //     "-MPtR7h68yD2nCMb73i_": {
    //         "categoria": "pollo-criollo",
    //         "costo": "",
    //         "descripcion": "<b>Patas de Pollo</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"1kg\":3.4668,\"500g\":1.7334}]}]",
    //         "estado": true,
    //         "fecha_create": "2023-03-03T12:19:05.836Z",
    //         "galeria": "[\"17043041609428928.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "94391841609428926.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Patas de pollo",
    //         "oferta": "[\"Fixed\",3.24,\"2025-12-31\"]",
    //         "precio": 3.4668,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "patas-de-pollo",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NmMHtSaxLdZ5yf-nU-f": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-18T15:23:53.923Z",
    //         "galeria": "[\"62703351703345362.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "27984761703345361.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Ariel Doble Poder 4kg",
    //         "oferta": "",
    //         "precio": 17.23,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "ariel-doble-poder-4kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NTJq9wHnHH7RklOKbQZ": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-04-18T14:50:39.193Z",
    //         "galeria": "[\"84963911681829440.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "97051751681829438.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Cepillo Lavlavar Palmareal",
    //         "oferta": "",
    //         "precio": 1.85,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cepillo-lavlavar-palmareal",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OC3j-53rLAKn6yeuCbN": {
    //         "categoria": "bebidas-y-lacteos",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Sabor\":[\"Pera\",\"Manzana\",\"Durazno\"]}]",
    //         "estado": true,
    //         "fecha_create": "2025-02-09T09:20:27.640Z",
    //         "galeria": "[\"57276481732024990.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "87014581732024970.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Jugo Tunal 1ltr",
    //         "oferta": "[\"Fixed\",2.32,\"2025-12-31\"]",
    //         "precio": 2.4824,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jugo-tunal-1ltr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQKoCfbxMdqqBxdQGs4": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Sardinas Margarita 170gr&nbsp;</h5>",
    //         "detalles": "[{\"title\":\"Sardinas margarita 170 g\",\"value\":\"\"},{\"title\":\"Sardina margarita 170g \",\"value\":\"Aceite \"},{\"title\":\"Sardina margarita 170g \",\"value\":\"Picante \"}]",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-24T21:51:37.572Z",
    //         "galeria": "[\"89323511626043049.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "47379281626043041.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Sardinas Margarita 170gr ",
    //         "oferta": "",
    //         "precio": 1.41,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "sardinas-margarita-170gr-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQJsLc_sN5k5_IphVZp": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Cubito de Pollo Maggi 16 Unds</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-05T08:31:00.938Z",
    //         "galeria": "[\"99944851609889308.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "15315311609889295.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Cubito de Pollo Maggi 16 Unds",
    //         "oferta": "",
    //         "precio": 3.9,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cubito-de-pollo-maggi-16-unds",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NVa-FGonxnY-uEOi5Jj": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-05-16T19:19:39.625Z",
    //         "galeria": "[\"61704971684264780.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "3468361684264779.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Arroz Mary Premiun 900g",
    //         "oferta": "",
    //         "precio": 1.92,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "arroz-mary-premiun-900g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQKZ_525hGHxNKlwiUP": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Pasta larga primor 1kg</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-06T02:41:41.352Z",
    //         "galeria": "[\"79636401609900903.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "43281921609900901.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Pasta larga primor 1kg",
    //         "oferta": "",
    //         "precio": 2.24,
    //         "prox": false,
    //         "sales": 1,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pasta-larga-primor-1kg",
    //         "video": "[]",
    //         "views": 15
    //     },
    //     "-O598knlgZMrKKPuHQki": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-08-25T15:28:17.459Z",
    //         "galeria": "[\"55453161724599697.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "20213101724599696.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Dessodorante Rexona Rolon Tono Perfecto",
    //         "oferta": "",
    //         "precio": 2.3,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "dessodorante-rexona-rolon-tono-perfecto",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MR_Pxw9ng5IIB-eLUzW": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"1 kg\":1}]}]",
    //         "estado": true,
    //         "fecha_create": "2021-01-21T14:49:21.919Z",
    //         "galeria": "[\"93834151611240561.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "98657961611240560.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Zanahoria",
    //         "oferta": "[\"Disccount\",10,\"2025-02-28\"]",
    //         "precio": 1,
    //         "prox": false,
    //         "sales": 1,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "zanahoria",
    //         "video": "[]",
    //         "views": 72
    //     },
    //     "-O5n8Rd1PWBtniSkVGiN": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-09-02T14:31:24.080Z",
    //         "galeria": "[\"68479261725287483.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "79246281725287483.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Papel rosal vino tinto x 2 rollos",
    //         "oferta": "",
    //         "precio": 1.03,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "papel-rosal-vino-tinto-x-2-rollos",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NQGAPsZ-0N3zAH49xh_": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-09-19T15:07:23.185Z",
    //         "galeria": "[\"38344031678546676.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "57902581678546676.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Caraota Blanca Pantera 500gr",
    //         "oferta": "[\"Fixed\",1.65,\"2023-12-31\"]",
    //         "precio": 1.75,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "caraota-blanca-pantera-500gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NPFgqhXT1XUULHcXB8h": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2024-01-13T15:24:15.777Z",
    //         "galeria": "[\"9339601677464922.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "51706591677464921.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabón de Barra Liquido Brilla King 1lt",
    //         "oferta": "[\"Fixed\",1.75,\"2024-12-31\"]",
    //         "precio": 1.8725,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-de-barra-liquido-brilla-king-1lt",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NMJoEs9_QOwtDSmCey_": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2024-06-20T16:06:47.543Z",
    //         "galeria": "[\"6618621674312743.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "27362241674312742.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Harina Trigo Robinson Leudante 1kg",
    //         "oferta": "[\"Fixed\",1.4,\"2025-01-31\"]",
    //         "precio": 1.498,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "harina-trigo-robinson-leudante-1kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N7PyLeQGTGRx0e-Q717": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-07-20T09:38:47.095Z",
    //         "galeria": "[\"30296091658309929.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "68296471658309927.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Pasta Capri Corta 1kg",
    //         "oferta": "",
    //         "precio": 1.85,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pasta-capri-corta-1kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MZh0hLUrWFeyr78IuNN": {
    //         "categoria": "articulos-de-limpieza",
    //         "descripcion": "Esponja izy extra absorbente",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-05-02T12:21:30.137Z",
    //         "galeria": "[\"4206461619958092.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "36183271619958089.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "S.esponja Izy Extra Grande ",
    //         "oferta": "",
    //         "precio": 2.63,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "s-esponja-izy-extra-grande-",
    //         "video": "[]",
    //         "views": 5
    //     },
    //     "-MQEavZBb1IU-G_-XIEX": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-vnw-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Arroz primor 1kg</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-04T22:54:14.704Z",
    //         "galeria": "[\"85986951609800856.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "9675521609972431.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Arroz Primor Clásico 1kg",
    //         "oferta": "",
    //         "precio": 1.62,
    //         "prox": false,
    //         "sales": 3,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "arroz-primor-clasico-1kg",
    //         "video": "[]",
    //         "views": 14
    //     },
    //     "-MPkOAD1ST4JolxADDlo": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "<ol><li><b>2Kg Pechuga</b></li><li><b>2Kg Muslos</b></li><li><b>2Kg Alas o Picadillo de Pollo</b></li></ol><p><br></p>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Preparación\":[\"*todo entero*\",\"*todo arreglado con la pechuga entera*\",\"*todo arreglado con la pechuga en filet*\"]}]",
    //         "estado": true,
    //         "fecha_create": "2021-11-21T10:26:20.034Z",
    //         "galeria": "[\"22064871720176641.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "26517461720176640.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo Pollero",
    //         "oferta": "[\"Fixed\",24,\"2025-12-31\"]",
    //         "precio": 25.68,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-pollero",
    //         "video": "[]",
    //         "views": 2
    //     },
    //     "-MQDvWerlKV_C7lGSWEs": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-vnw-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Toallas sanitarias Nocturna 8 und Fresh &amp; Free</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T16:35:50.256Z",
    //         "galeria": "[\"98195061609789479.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "99736741609789476.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Toallas sanitarias Nocturna 8und Fresh y Free",
    //         "oferta": "[\"Fixed\",1.3,\"2025-12-31\"]",
    //         "precio": 1.391,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "toallas-sanitarias-nocturna-8und-fresh-y-free",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPtgLkS67ZvtDatfOO6": {
    //         "categoria": "bebidas-y-lacteos",
    //         "costo": "",
    //         "descripcion": "<b>Coca Cola 2lt</b>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-03-14T09:19:18.222Z",
    //         "galeria": "[\"87441791629638995.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "30872171629638984.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Coca Cola 2lt ",
    //         "oferta": "",
    //         "precio": 1.77,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "coca-cola-2lt-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQKGt6-MimRDinlGJdV": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h4 _ngcontent-anc-c32=\"\" style=\"margin-bottom: 0.5rem; font-size: 1.5rem; color: rgb(255, 255, 255); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Miel de Abeja La Colmena 250gr</h4>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-06T01:20:03.190Z",
    //         "galeria": "[\"3328751609896005.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "27145821609896003.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Miel de Abeja La Colmena 250gr",
    //         "oferta": "",
    //         "precio": 2.26,
    //         "prox": false,
    //         "sales": 1,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "miel-de-abeja-la-colmena-250gr",
    //         "video": "[]",
    //         "views": 3
    //     },
    //     "-NDNC6w5QYcbd56Q1KYk": {
    //         "categoria": "pollo-criollo",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Carapacho\":[{\"1kg\":1.3054}]}]",
    //         "estado": true,
    //         "fecha_create": "2022-10-02T10:18:36.261Z",
    //         "galeria": "[\"28029971664706111.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "41536781664706109.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Carapacho De pollo",
    //         "oferta": "[\"Fixed\",1.22,\"2025-12-31\"]",
    //         "precio": 1.3054,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "carapacho-de-pollo",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mww2qKPCSKBWATPfOol": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-06-08T12:40:31.288Z",
    //         "galeria": "[\"66617301645980114.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "42325711645980110.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Desodorante Rexona Rollon 50ml",
    //         "oferta": "",
    //         "precio": 2.3,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 3,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "desodorante-rexona-rollon-50ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NmGqflFSVzOkfa5kH_Z": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<p>Salsas de soya, ajo , inglesa de 150 ml</p>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-12-22T14:07:52.469Z",
    //         "galeria": "[\"84998541703254079.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "3234141703254072.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Salsas Heinz Tri Pack",
    //         "oferta": "",
    //         "precio": 4.32,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salsas-heinz-tri-pack",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MkCHg3aGFaN-UoLn37I": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "Granola Fit Graan 350g Surtida",
    //         "detalles": "",
    //         "especificaciones": "[{\"Sabores \":[\"Frutos rojos\"]}]",
    //         "estado": true,
    //         "fecha_create": "2025-01-16T00:28:31.671Z",
    //         "galeria": "[\"92086461632310571.jpg\",\"52274621632310574.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "91245291632310561.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Granola Fit Graan 350g surtida",
    //         "oferta": "[\"Fixed\",2.25,\"2025-12-31\"]",
    //         "precio": 2.4075,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "granola-fit-graan-350g-surtida",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mb3ky3ueGfVHxXhCF3Z": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-05-31T23:30:37.576Z",
    //         "galeria": "[\"61597431622503840.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "99286631622503837.jpg",
    //         "mostrarOcultar": true,
    //         "nombre": "Hisopos Expert 100 unid",
    //         "oferta": "",
    //         "precio": 0.77,
    //         "prox": false,
    //         "sales": 1,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "hisopos-expert-100-unid",
    //         "video": "[]",
    //         "views": 2
    //     },
    //     "-OKDGFIQK0J2EJudsFLA": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-28T20:34:54.961Z",
    //         "galeria": "[\"20956311740774897.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "6165101740774895.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Afeitadora Schik Xtreme 3",
    //         "oferta": "[\"Fixed\",1,\"2025-12-31\"]",
    //         "precio": 1.07,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "afeitadora-schik-xtreme-3",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NhWEnN81s6txAtx6NE7": {
    //         "categoria": "bebes-",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-10-24T10:33:31.335Z",
    //         "galeria": "[\"24461741698143614.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "8831181698143613.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Toallas Húmedas Little Angel ",
    //         "oferta": "",
    //         "precio": 1.8,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "toallas-humedas-little-angel-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPtNEQCk5Vx4zu0hyd_": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "descripcion": "<b>Carne Molida</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"500gr\":3.852,\"1kg\":7.704}]}]",
    //         "estado": true,
    //         "fecha_create": "2020-12-31T15:18:25.818Z",
    //         "galeria": "[\"89875541609427907.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "12061071609427905.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Carne Molida ",
    //         "oferta": "[\"Fixed\",3.6,\"2025-12-31\"]",
    //         "precio": 3.852,
    //         "prox": false,
    //         "sales": 18,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "carne-molida-",
    //         "video": "[]",
    //         "views": 356
    //     },
    //     "-O9o_gkpj7oDapbrhuNV": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-22T14:17:13.993Z",
    //         "galeria": "[\"37607441729606634.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "53065741729606633.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Champú Savital 510ml",
    //         "oferta": "[\"Fixed\",5.49,\"2025-12-31\"]",
    //         "precio": 5.8743,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "champu-savital-510ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OCdvgvBukOSibn_rmla": {
    //         "categoria": "licores",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-26T19:24:01.675Z",
    //         "galeria": "[\"91475941732649077.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "35525611732649041.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Ron macondo 1ltr",
    //         "oferta": "[\"Fixed\",4,\"2025-12-31\"]",
    //         "precio": 4.28,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "ron-macondo-1ltr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPj_xKcD__WAVI5MORI": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "<ol><li><b>1Kg Bistec</b></li><li><b>1Kg Mechar</b></li><li><b>1Kg Guisar</b></li><li><b>1Kg Molida</b></li><li><b>1Kg Costilla de Res</b></li><li><b>1Kg Chuleta o Costilla de Cerdo</b></li><li><b>1Kg muslo</b></li><li><b>1Kg Alas</b></li></ol>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Preparación \":[\"*muslo arreglado sin piel*\",\"*muslo sin arreglar*\"]}]",
    //         "estado": true,
    //         "fecha_create": "2020-12-29T18:16:08.357Z",
    //         "galeria": "[\"64966051720176558.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "15094831720176557.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo Carne Pollo Y Cerdo 8kg",
    //         "oferta": "[\"Fixed\",45,\"2025-12-31\"]",
    //         "precio": 48.15,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-carne-pollo-y-cerdo-8kg",
    //         "video": "[]",
    //         "views": 136
    //     },
    //     "-N279vixcMFz3Zv_f6kn": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-28T21:22:59.985Z",
    //         "galeria": "[\"88457191652625749.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "90988721652625744.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Colgate Triple Acción 75ml",
    //         "oferta": "",
    //         "precio": 2.39,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "colgate-triple-accion-75ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MR_XnjMMa2vWCRJgG-R": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "descripcion": "<b>Ajos pelados paquete&nbsp;</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Paquete\":[{\"1 paquete \":1.35,\"2 paquetes\":2.7}]}]",
    //         "estado": true,
    //         "fecha_create": "2021-01-21T15:23:36.758Z",
    //         "galeria": "[\"27943081611242616.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "76391631611242615.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Ajos Pelados Paquete",
    //         "oferta": "[\"Disccount\",10,\"2025-02-28\"]",
    //         "precio": 1.35,
    //         "prox": false,
    //         "sales": 1,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "ajos-pelados-paquete",
    //         "video": "[]",
    //         "views": 16
    //     },
    //     "-MQ2pH14tUV6oOjW4uLb": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-aue-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Jabón Vel Rosa 1000cm³</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-02-09T21:29:11.961Z",
    //         "galeria": "[\"97261631609603292.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "18941451609603292.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabón Vel Rosa 1000cm",
    //         "oferta": "",
    //         "precio": 9.6,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 3,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-vel-rosa-1000cm",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MpvRFZAsABmET1c17ag": {
    //         "categoria": "cerdo",
    //         "costo": "",
    //         "descripcion": "Pulpa De Cerdo Para Hallaca",
    //         "detalles": "",
    //         "especificaciones": "[{\"Pulpa De Cerdo Para Hallaca\":[{\"500gr\":4.1195,\"1kg\":8.239}]}]",
    //         "estado": true,
    //         "fecha_create": "2021-12-02T13:59:00.131Z",
    //         "galeria": "[\"56678301638453544.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "75582881638453542.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Pulpa De Cerdo Para hallaca",
    //         "oferta": "[\"Fixed\",3.85,\"2025-12-31\"]",
    //         "precio": 4.1195,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pulpa-de-cerdo-para-hallaca",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NSm-YgDAPQQ4AuhUGUq": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-08-08T19:39:26.723Z",
    //         "galeria": "[\"16121851681244961.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "27383901681244960.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Predilecta Dueto 240g ",
    //         "oferta": "",
    //         "precio": 1.63,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 50,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "predilecta-dueto-240g-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MR_VXYU_IAV5fnflLFu": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "descripcion": "<b>Cilantro paquete de 100gr&nbsp;</b>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-21T15:13:40.523Z",
    //         "galeria": "[\"53169451611242022.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "31827091611242019.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Cilantro",
    //         "oferta": "[\"Disccount\",10,\"2025-02-28\"]",
    //         "precio": 0.9,
    //         "prox": false,
    //         "sales": 2,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cilantro",
    //         "video": "[]",
    //         "views": 30
    //     },
    //     "-NnR4ueiM9s38qBctC9-": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-01-06T00:06:27.475Z",
    //         "galeria": "[\"49179811704499587.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "21378321704499586.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Aceite Vatel 1lit Vegetal",
    //         "oferta": "",
    //         "precio": 3.76,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "aceite-vatel-1lit-vegetal",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQ2o9W0wdtWZfCCsfLq": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-aue-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Cloros 1litro nevex</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-08-16T15:31:39.311Z",
    //         "galeria": "[\"20434761609602999.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "87744591609602996.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Cloros 1litro nevex",
    //         "oferta": "",
    //         "precio": 1.81,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cloros-1litro-nevex",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NGbEEUTY46Y50pNpT30": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-11-11T15:09:18.348Z",
    //         "galeria": "[\"15479741668179359.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "54905981668179358.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Leche Campiña 400g",
    //         "oferta": "",
    //         "precio": 5.72,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "leche-campina-400g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NmN6ChcR072uhRatPB1": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-12-23T19:17:56.480Z",
    //         "galeria": "[\"27561251703359076.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "9619821703359075.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Ariel 2kg",
    //         "oferta": "",
    //         "precio": 10.58,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "ariel-2kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQKHI8iyNhkpQeIl4GG": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Mostaza Heinz 490g</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-06T01:21:47.430Z",
    //         "galeria": "[\"92135031609896111.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "36632931609896107.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Mostaza Heinz 490g",
    //         "oferta": "",
    //         "precio": 4.65,
    //         "prox": false,
    //         "sales": 1,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "mostaza-heinz-490g",
    //         "video": "[]",
    //         "views": 1
    //     },
    //     "-NNDFth5FhGZdUMfSIho": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-02-01T18:35:53.984Z",
    //         "galeria": "[\"48861981675276556.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "62959441675276553.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Chococolores Mani Bianchi 20g",
    //         "oferta": "",
    //         "precio": 0.53,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "chococolores-mani-bianchi-20g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N4YLkESEIIY504YeEgw": {
    //         "categoria": "viveres",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-08-06T14:01:31.181Z",
    //         "galeria": "[\"8286281655229316.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "20495141655229311.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Atun Paraguana 140g",
    //         "oferta": "",
    //         "precio": 1.55,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "atun-paraguana-140g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQK-EB9wlInuPmnjR8O": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Arvejas La Gran Redoma 500g</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-06T00:02:53.647Z",
    //         "galeria": "[\"73264341609891374.jpg\",\"56826081609891377.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "6412631609891373.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "G Arvejas La Gran Redoma 500g",
    //         "oferta": "",
    //         "precio": 1.17,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "g-arvejas-la-gran-redoma-500g",
    //         "video": "[]",
    //         "views": 10
    //     },
    //     "-OCOjkRYGQtQfrA-1Rc6": {
    //         "categoria": "accesorios-de-cumpleanos-",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-23T15:58:29.820Z",
    //         "galeria": "[\"42028571732377511.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "84588051732377511.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Tina Plástica Nro 32 X 10 Unidades",
    //         "oferta": "",
    //         "precio": 2.27,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "tina-plastica-nro-32-x-10-unidades",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPsvE-nNsPEpLFEEQ4x": {
    //         "categoria": "cerdo",
    //         "costo": "",
    //         "descripcion": "<b>Chuleta Ahumada de Cerdo</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"500gr\":5.35,\"1kg\":10.7}]}]",
    //         "estado": true,
    //         "fecha_create": "2022-06-10T09:34:52.373Z",
    //         "galeria": "[\"33321901696207821.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "15571661696207820.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Chuleta Ahumada de Cerdo",
    //         "oferta": "[\"Fixed\",5,\"2025-12-31\"]",
    //         "precio": 5.35,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "chuleta-ahumada-de-cerdo",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O-qOdm4Aisp4VB97YIS": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Aroma \":[\"Limon\"]}]",
    //         "estado": false,
    //         "fecha_create": "2024-06-20T16:06:47.543Z",
    //         "galeria": "[\"31897901718899612.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "73695131718899608.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabón En Polvo Arraz 1000g",
    //         "oferta": "[\"Fixed\",1.45,\"2025-01-31\"]",
    //         "precio": 1.5515,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-en-polvo-arraz-1000g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MqyZ0cxolFRYzb7EONm": {
    //         "categoria": "mascotas",
    //         "costo": "",
    //         "descripcion": "PERRARINA MAGIC FRIENDS CROQUETAS 2KG",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-01-16T00:33:01.251Z",
    //         "galeria": "[\"45942341639579653.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "96276221639579652.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "PERRARINA MAGIC FRIENDS CROQUETAS 2KG",
    //         "oferta": "[\"Fixed\",4.48,\"2025-12-31\"]",
    //         "precio": 4.7936,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "perrarina-magic-friends-croquetas-2kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N495GasgKH23PKVmz94": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-06-09T20:12:44.702Z",
    //         "galeria": "[\"94417521654805567.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "91493881654805564.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Repuesto De Coleto con palo",
    //         "oferta": "",
    //         "precio": 2.81,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "repuesto-de-coleto-con-palo",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MvKuhSKIfJRv_Bjt2oi": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-12-01T22:04:19.235Z",
    //         "galeria": "[\"39855241644266443.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "49509601644266437.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Maíz Tierno Cooltivo 285g",
    //         "oferta": "[\"Fixed\",1.89,\"2023-12-31\"]",
    //         "precio": 2,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "maiz-tierno-cooltivo-285g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NLgrTbnD8Dq_kh1Do0G": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-01-13T20:41:37.850Z",
    //         "galeria": "[\"49911921673642502.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "6783471673642498.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Crema De Leche Latti 200ml",
    //         "oferta": "[\"Fixed\",1.22,\"2025-01-31\"]",
    //         "precio": 1.3054,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "crema-de-leche-latti-200ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NpUhFlZsX8nJlvo0Olo": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "descripcion": "<p>Cocos de agua tierno</p>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-01-31T13:28:34.602Z",
    //         "galeria": "[\"39108271706707716.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "47748471706707714.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Cocos de agua ",
    //         "oferta": "[\"Disccount\",10,\"2025-02-28\"]",
    //         "precio": 1.3,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cocos-de-agua-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MkILaZhdJxZ2ss2mtcS": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "Shampoo Head Shoulders Original Surtido 180ml",
    //         "detalles": "",
    //         "especificaciones": "[{\"Head Shoulders Original \":[{\" Limpieza Renovadora \":4.23}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-09-23T13:28:05.478Z",
    //         "galeria": "[\"14325841632412260.jpg\",\"42862401632412261.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "1061271632412248.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Champoo  Head Shoulders Original Surtido 180ml",
    //         "oferta": "",
    //         "precio": 4.23,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "champoo--head-shoulders-original-surtido-180ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N0MHMDyxWWRPYZ2a8WV": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-12-01T22:04:19.235Z",
    //         "galeria": "[\"37568001650731872.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "68372921650731869.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Mayonesa Bassi 500gr",
    //         "oferta": "[\"Fixed\",1.41,\"2025-01-31\"]",
    //         "precio": 1.5087,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "mayonesa-bassi-500gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQDhVzIi-M66N5b_ODp": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-cup-c32=\"\" class=\"card-title mb-2\" style=\"\"><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\"><font color=\"#212529\"><span style=\"font-size: 15px;\"><b>Jabón</b></span></font><b style=\"color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;; font-size: 15px;\">&nbsp;liquido Avena antibacteriano 500ml</b></font></font></h5>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Aroma \":[\"Avena\",\"Aloe vera\"]}]",
    //         "estado": true,
    //         "fecha_create": "2023-10-24T10:33:31.335Z",
    //         "galeria": "[\"49792611739037347.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "32914561739037327.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabón Liquido Antibacterial Natural Feeling 500ml",
    //         "oferta": "[\"Fixed\",1.33,\"2025-12-31\"]",
    //         "precio": 1.4231,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-liquido-antibacterial-natural-feeling-500ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MXbv8ZbVqobF_jmjiBA": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "descripcion": "Piñas por unidad&nbsp;",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-12-03T01:17:42.551Z",
    //         "galeria": "[\"97959131617724994.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "77312861617724979.jpg",
    //         "mostrarOcultar": true,
    //         "nombre": "Piñas",
    //         "oferta": "[\"Disccount\",10,\"2025-02-28\"]",
    //         "precio": 3.2,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pinas",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MgJ3O-12B3iO9tYIZ3d": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Chocolate Savoy De 30gr\":[{\"Leche \":1.12}]}]",
    //         "estado": true,
    //         "fecha_create": "2021-08-05T02:08:17.250Z",
    //         "galeria": "[\"7278591628129299.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "55493481628129298.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Chocolate savoy De 30gr",
    //         "oferta": "",
    //         "precio": 1.12,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "chocolate-savoy-de-30gr",
    //         "video": "[]",
    //         "views": 4
    //     },
    //     "-NIElGxqNr3wA4YfsoXy": {
    //         "categoria": "bebidas-y-lacteos",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Sabor \":[\"Normal\",\"Deslatosada\"]}]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T19:34:21.766Z",
    //         "galeria": "[\"76194811669933112.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "91803701669933111.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Leche liquida Latti",
    //         "oferta": "[\"Fixed\",1.35,\"2025-12-31\"]",
    //         "precio": 1.4445,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "leche-liquida-latti",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPtSgHykQbWET-_nZNX": {
    //         "categoria": "pollo-criollo",
    //         "costo": "",
    //         "descripcion": "<b>Alas de Pollo</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"1kg\":4.6545,\"500gr \":2.32725}]}]",
    //         "estado": true,
    //         "fecha_create": "2020-12-31T15:41:45.837Z",
    //         "galeria": "[\"51727691609429336.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "18994541609429304.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Alas de Pollo",
    //         "oferta": "[\"Fixed\",4.35,\"2025-12-31\"]",
    //         "precio": 4.6545,
    //         "prox": false,
    //         "sales": 3,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "alas-de-pollo",
    //         "video": "[]",
    //         "views": 64
    //     },
    //     "-NsPR3Yb9X1-CIU34lTN": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-03-07T19:49:51.435Z",
    //         "galeria": "[\"31941841709840549.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "19983551709840545.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Cloro Kikex 2 Lilitros",
    //         "oferta": "",
    //         "precio": 1.78,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cloro-kikex-2-lilitros",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OIaqQ8DSJCC-BGZvQkd": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T18:36:45.814Z",
    //         "galeria": "[\"60291811739039812.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "30303531739039805.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Cereal Fiocco Azucaradas 300g",
    //         "oferta": "[\"Fixed\",2.26,\"2025-12-31\"]",
    //         "precio": 2.4182,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cereal-fiocco-azucaradas-300g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NFoozgsVByMFr9WdBuL": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-11-01T20:13:35.336Z",
    //         "galeria": "[\"33648281667836085.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "18006331667836083.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Shampu  Amalfi Camomila400ml",
    //         "oferta": "",
    //         "precio": 4.73,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 3,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "shampu--amalfi-camomila400ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPt9BclaBFH8zaukZjK": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "descripcion": "<b>Pollo de Res</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"1kg \":7.2332,\"1.5kg\":10.8498}]}]",
    //         "estado": true,
    //         "fecha_create": "2020-12-31T14:17:06.063Z",
    //         "galeria": "[\"79887761609424226.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "94518861609424225.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Pollo de Res",
    //         "oferta": "[\"Fixed\",6.76,\"2025-12-31\"]",
    //         "precio": 7.2332,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pollo-de-res",
    //         "video": "[]",
    //         "views": 14
    //     },
    //     "-OIzSqS1UAXmrtZNQB2p": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-13T13:19:47.029Z",
    //         "galeria": "[\"3578601739452799.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "1034881739452787.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Çrema Crest 150 g",
    //         "oferta": "",
    //         "precio": 2.36,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "çrema-crest-150-g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MTuQAk3mZ7fFNTTz3z4": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "<p><b>Tapa amarilla desengrasante</b></p><ul><li><b>1ltro</b></li></ul>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-04-20T19:16:29.118Z",
    //         "galeria": "[\"47407541613740422.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "80267051613740421.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Tapa Amarilla Desengrasante  De 1 litro",
    //         "oferta": "",
    //         "precio": 6.35,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "tapa-amarilla-desengrasante--de-1-litro",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NSHAvlHsHLFQu7afyGi": {
    //         "categoria": "bebidas-y-lacteos",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Gatorade 1ltr\":[\"Tropical\"]}]",
    //         "estado": false,
    //         "fecha_create": "2023-04-05T16:11:09.000Z",
    //         "galeria": "[\"77217741680711072.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "71327601680711068.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Gatorade 1 litro ",
    //         "oferta": "[\"Fixed\",3.07,\"2023-12-31\"]",
    //         "precio": 3.17,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "gatorade-1-litro-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N1kCDhezYhhKKangRQ6": {
    //         "categoria": "pollo-criollo",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Pechuga molida\":[{\"500gr\":4.5475,\"1kg\":9.095}]}]",
    //         "estado": true,
    //         "fecha_create": "2022-05-10T23:01:36.149Z",
    //         "galeria": "[\"2161661652223699.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "76393871652223697.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Pechuga Suprema molida",
    //         "oferta": "[\"Fixed\",4.25,\"2025-12-31\"]",
    //         "precio": 4.5475,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pechuga-suprema-molida",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NV4eOcfVIWBVs2-G-Ky": {
    //         "categoria": "viveres",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-05-10T12:36:18.597Z",
    //         "galeria": "[\"5665971683722180.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "59955321683722179.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Margarina Sabrina En Barras 500g",
    //         "oferta": "",
    //         "precio": 2.16,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "margarina-sabrina-en-barras-500g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N7KT-cfs4j0w3w8NsVj": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-07-19T07:59:26.543Z",
    //         "galeria": "[\"36513461658217564.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "58983631658217563.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Salsa Soya China La Original 150ml",
    //         "oferta": "",
    //         "precio": 3.35,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salsa-soya-china-la-original-150ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQK8MLlfXayy9as6ZPM": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Maizina Americana 800g</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-06T00:42:47.695Z",
    //         "galeria": "[\"18748351609893770.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "81664881609893767.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Maizina Americana 800g",
    //         "oferta": "",
    //         "precio": 5.7,
    //         "prox": false,
    //         "sales": 2,
    //         "stock": 30,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "maizina-americana-800g",
    //         "video": "[]",
    //         "views": 1
    //     },
    //     "-Mt8_QeMu_2mo0gdxq_P": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-02-15T21:37:29.411Z",
    //         "galeria": "[\"48892171641912055.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "13278751641912054.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Talco Para Pies Xen 150g",
    //         "oferta": "[\"Fixed\",1.67,\"2025-12-31\"]",
    //         "precio": 1.77,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "talco-para-pies-xen-150g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NNDmthS-z62tGcyV1iz": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-02-01T21:04:28.893Z",
    //         "galeria": "[\"546591675285468.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "65224731675285466.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Limpiador Más Con Amoniaco 700ml",
    //         "oferta": "",
    //         "precio": 3.7,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "limpiador-mas-con-amoniaco-700ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O3LXrnU5Tjc8cFjmrzU": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "<ol><li>1kg carne molida </li><li>1 pqt. Pan de hamburguesa x 6</li><li>250gr queso amarillo </li><li>1 ketchup Heinz </li><li>1 mayonesa mayonesa 445g</li><li>1 Pepsi 2ltr</li></ol>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-08-03T04:42:05.318Z",
    //         "galeria": "[\"16481281725269768.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "9317581726650045.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo Burgers",
    //         "oferta": "[\"Fixed\",20,\"2025-12-31\"]",
    //         "precio": 21.4,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-burgers",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O1cRurO2XKmuGVUCTFH": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-07-12T19:37:50.642Z",
    //         "galeria": "[\"75295691720813071.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "74894121720813071.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Club Social Integral X6",
    //         "oferta": "",
    //         "precio": 1.71,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "club-social-integral-x6",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mi8HzR4CwiCyoRrhcre": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-12-15T14:47:31.729Z",
    //         "galeria": "[\"7850211630096061.jpg\",\"49859281639580554.jpg\",\"82054611639580671.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "356741630096060.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Shampoo Sedal keratina",
    //         "oferta": "",
    //         "precio": 4.71,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "shampoo-sedal-keratina",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NGhEesIHiANtU7G9tT0": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Sabor\":[\"Original\",\"Vainilla\",\"Americana\"]}]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T16:09:15.635Z",
    //         "galeria": "[\"78383981668280134.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "16194051668280132.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Oreo Tubo 108g",
    //         "oferta": "[\"Fixed\",1,\"2025-12-31\"]",
    //         "precio": 1.07,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "oreo-tubo-108g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQEkrBYM4KXZ8ANs8Xk": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-vnw-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Cachapas Pan 500g</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-04T23:37:28.768Z",
    //         "galeria": "[\"32105581609803460.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "77012371609803448.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Cachapas Pan 500g",
    //         "oferta": "",
    //         "precio": 2.5,
    //         "prox": false,
    //         "sales": 1,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cachapas-pan-500g",
    //         "video": "[]",
    //         "views": 9
    //     },
    //     "-O5ZsRRVC46SOGMXtTdx": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-08-30T20:02:24.932Z",
    //         "galeria": "[\"83857491725048145.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "19656251725048144.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Corn Fflakes 230grs",
    //         "oferta": "",
    //         "precio": 3.3,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "corn-fflakes-230grs",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MYBjqFCk1tKmggu5U66": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-05-27T15:31:03.215Z",
    //         "galeria": "[\"31283481618342797.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "2091941618342796.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Atun Margarita 140g",
    //         "oferta": "",
    //         "precio": 2.12,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 20,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "atun-margarita-140g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mb8_kwIhvqo2CLhy3Dk": {
    //         "categoria": "bebes-",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-06-01T21:59:50.292Z",
    //         "galeria": "[\"32712561622584790.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "61221761622584789.jpg",
    //         "mostrarOcultar": true,
    //         "nombre": "Colonia Chicco 220 Ml",
    //         "oferta": "",
    //         "precio": 5.86,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "colonia-chicco-220-ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MUPh-m_mf53UnzI6s7g": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<b>Salsa inglesa heinz</b>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-02-25T19:39:26.798Z",
    //         "galeria": "[\"73097151614281967.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "26001371614281965.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Salsa Inglesa Heinz 300ml",
    //         "oferta": "",
    //         "precio": 2.79,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salsa-inglesa-heinz-300ml",
    //         "video": "[]",
    //         "views": 1
    //     },
    //     "-MPt2DiANkdXDQw620eo": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "<b>Queso Amarillo Gouda</b>",
    //         "detalles": "[{\"title\":\"Marca\",\"value\":\"Agua linda\"}]",
    //         "especificaciones": "[{\"Peso\":[{\"250gr\":3.5848,\"300gr\":4.3014,\"500gr\":7.169}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-07-09T14:00:25.460Z",
    //         "galeria": "[\"35955411612278506.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "28412231612278504.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Queso Amarillo Gouda",
    //         "oferta": "[\"Fixed\",3.35,\"2025-12-31\"]",
    //         "precio": 3.5845,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "queso-amarillo-gouda",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NiKukEMd7RlMLJlsvfl": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-11-03T16:01:28.142Z",
    //         "galeria": "[\"30241121699027287.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "5186431699027287.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabon Baño Dalan  375g x3",
    //         "oferta": "",
    //         "precio": 2.43,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-bano-dalan-x3",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPz_-n5j2ujZZV_xa3L": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2023-05-23T20:30:40.668Z",
    //         "galeria": "[\"4977891609531918.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "3802561609531916.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Pata De Res ",
    //         "oferta": "[\"Fixed\",2.7,\"2025-01-31\"]",
    //         "precio": 2.889,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pata-de-res-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQjEhnqof2NkUEaUjC4": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "<b>Gel Fijador Blanco Rolda</b>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-11T02:20:36.991Z",
    //         "galeria": "[\"5047041610331642.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "30342121610331636.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "gel fijador Rolda blanco 500g",
    //         "oferta": "",
    //         "precio": 3.53,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "gel-fijador-rolda-blanco-500g",
    //         "video": "[]",
    //         "views": 5
    //     },
    //     "-O0pI_mJDilO6DMrzZcT": {
    //         "categoria": "accesorios-de-cumpleanos-",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-07-02T21:16:25.868Z",
    //         "galeria": "[\"44778901719954987.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "82267581719954986.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Plato Nro 6 X20 Unid Para Torta",
    //         "oferta": "",
    //         "precio": 1.03,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "plato-nro-6-x20-unid-para-torta",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQDmjlvAopI_KYoeGHR": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-cup-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Protectores diarios 15unid</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-28T20:34:54.961Z",
    //         "galeria": "[\"4853531609787177.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "31715161609787176.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Protectores Diarios Fresh & Free 15und",
    //         "oferta": "[\"Fixed\",0.8,\"2025-12-31\"]",
    //         "precio": 0.856,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "protectores-diarios-fresh-y-free-15und",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NztNDEFCZo_nQ36S0nS": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-06-08T19:43:46.927Z",
    //         "galeria": "[\"91565641717875827.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "37934991717875826.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Dioxogen 460ml",
    //         "oferta": "",
    //         "precio": 2.48,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "dioxogen-460ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQEnJhNtmAIUqiVN6dP": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-vnw-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Café Sello Rojo 250gr</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2023-03-19T09:07:34.872Z",
    //         "galeria": "[\"91006921609804105.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "21071521609804104.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Café Sello Rojo 250gr",
    //         "oferta": "[\"Fixed\",3.07,\"2025-12-31\"]",
    //         "precio": 3.2849,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cafe-sello-rojo-250gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQFry9ZpcxBO_5lF7sF": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-nhs-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Café concafe 400gr</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-05T04:48:17.038Z",
    //         "galeria": "[\"60189971609822100.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "51367841609822096.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Café concafe 400gr",
    //         "oferta": "",
    //         "precio": 5.51,
    //         "prox": false,
    //         "sales": 2,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cafe-concafe-400gr",
    //         "video": "[]",
    //         "views": 8
    //     },
    //     "-MWKWij_u26DhzL0kS_5": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "Café Arauca 500g",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-06-02T22:31:38.598Z",
    //         "galeria": "[\"29641381616342608.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "64782731616342607.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Café flor arauca 500g",
    //         "oferta": "",
    //         "precio": 5,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cafe-flor-arauca-500g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OCxleWjLWSUKrR9HyVb": {
    //         "categoria": "licores",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-30T15:53:02.055Z",
    //         "galeria": "[\"33501131732981990.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "57193051732981982.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Old Parr",
    //         "oferta": "[\"Fixed\",36.6,\"2025-12-31\"]",
    //         "precio": 39.162,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "old-parr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NYN48by9Mxdu46zYj-J": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-06-20T09:13:28.027Z",
    //         "galeria": "[\"22581241687252408.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "43129231687252407.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Cafe Gurmet Amanecer 200g",
    //         "oferta": "",
    //         "precio": 2.12,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cafe-gurmet-amanecer-200g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NQ6rbhiqrB41x2suYKJ": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-06-19T15:01:30.295Z",
    //         "galeria": "[\"95381841678390489.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "90156181678390478.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Aceite Puro Gold Soya 760ml",
    //         "oferta": "[\"Fixed\",2.65,\"2024-12-31\"]",
    //         "precio": 2.8355,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "aceite-puro-gold-soya-760ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O6A5zJ74Gq1DwLQUgAQ": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-09-07T06:11:32.221Z",
    //         "galeria": "[\"47875871725689492.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "69285081725689492.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Colador De Jugo Mediano",
    //         "oferta": "",
    //         "precio": 1.18,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "colador-de-jugo-mediano",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NyBHJYudOJUmSM1aCQ2": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-05-18T15:19:23.772Z",
    //         "galeria": "[\"3992781716045563.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "95035161716045563.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Crema De Arroz Primor 450g",
    //         "oferta": "",
    //         "precio": 1.41,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "crema-de-arroz-primor-450g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O2_FN7gvy0sz7Cji2_d": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-07-24T14:59:53.817Z",
    //         "galeria": "[\"48342061721833193.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "66662941721833192.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Leche Entera Vitta 380g",
    //         "oferta": "",
    //         "precio": 3.97,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "leche-entera-vitta-380g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPtM4fMAhVdfmJomRG6": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "descripcion": "<b>Carne Ossobuco</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"500g\":3.6166,\"1kg\":7.2332}]}]",
    //         "estado": false,
    //         "fecha_create": "2020-12-31T15:13:21.339Z",
    //         "galeria": "[\"37417361609427605.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "66262961609427600.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Carne Ossouco",
    //         "oferta": "[\"Fixed\",3.38,\"2025-12-31\"]",
    //         "precio": 3.6166,
    //         "prox": false,
    //         "sales": 4,
    //         "stock": 1000,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "carne-ossouco",
    //         "video": "[]",
    //         "views": 16
    //     },
    //     "-O7yOx2NYS7jf_UdncH1": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-09-29T15:30:43.476Z",
    //         "galeria": "[\"69885211727623843.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "98125181727623843.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Cre.a Dental Alident 100ml",
    //         "oferta": "",
    //         "precio": 1.06,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cre-a-dental-alident-100ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MnGh7QwT63qvFB4EboV": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "Frasco de alcaparras de 500gr",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-29T08:47:52.248Z",
    //         "galeria": "[\"97394901635605841.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "461271635605834.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Alcaparras De 500gr La Giralda ",
    //         "oferta": "",
    //         "precio": 5.47,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 20,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "alcaparras-de-500gr-la-giralda-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MZm5dsk1huhy_2ZTlWy": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T19:34:21.766Z",
    //         "galeria": "[\"31396171739044508.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "95991471739044473.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabón Bonaropa Floral 1kg",
    //         "oferta": "[\"Fixed\",1.8,\"2025-12-31\"]",
    //         "precio": 1.926,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-bonaropa-floral-1kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NAR4Ggwc_W1yDr8pyD-": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-08-26T21:35:44.058Z",
    //         "galeria": "[\"47358191667755712.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "99757401667755710.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Baygon Insect Y Voladores",
    //         "oferta": "",
    //         "precio": 4.86,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "baygon-insect-y-voladores",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPk9BKxu8W-ccR6ag01": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "<ol><li><b>1Kg Bistec</b></li><li><b>1Kg Mechar</b></li><li><b>1Kg Guisar</b></li><li><b>1Kg Molida</b></li><li><b>1kg Costilla de Res</b></li><li><b>1Kg Chuleta Ahumada</b></li><li><b>1Kg Pechuga de Pollo</b></li><li><b>1Kg Muslos de Pollo</b></li><li><b>1Kg Alas o Picadillo de Pollo</b></li><li><b>1Kg Chuleta o Costilla de cerdo</b></li><li><b>1 Cartón de huevos</b></li></ol>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2020-12-29T20:53:23.157Z",
    //         "galeria": "[\"36964471690539409.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "1387601690539405.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo Full Mixto",
    //         "oferta": "[\"Fixed\",66,\"2025-12-31\"]",
    //         "precio": 70.62,
    //         "prox": false,
    //         "sales": 1,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-full-mixto",
    //         "video": "[]",
    //         "views": 126
    //     },
    //     "-M_B_imuQEDYBt7ECEqa": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "Leche en polvo campestre de 1k",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-05-08T15:27:09.478Z",
    //         "galeria": "[\"54463661620487628.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "67234881620487628.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Leche En Polvo Campestre De 900g",
    //         "oferta": "",
    //         "precio": 11.6,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "leche-en-polvo-campestre-de-900g",
    //         "video": "[]",
    //         "views": 28
    //     },
    //     "-MPzSvICLEN6u4IwFgHy": {
    //         "categoria": "cerdo",
    //         "costo": "",
    //         "descripcion": "<b>Chuleta de cerdo</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"500gr\":3.2849,\"1kg\":6.5698}]}]",
    //         "estado": true,
    //         "fecha_create": "2021-01-01T19:40:51.968Z",
    //         "galeria": "[\"3226291609530061.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "78539411609530051.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Chuleta de Cerdo",
    //         "oferta": "[\"Fixed\",3.07,\"2025-12-31\"]",
    //         "precio": 3.2849,
    //         "prox": false,
    //         "sales": 2,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "chuleta-de-cerdo",
    //         "video": "[]",
    //         "views": 54
    //     },
    //     "-MPzawVEkgH17KV1nJKO": {
    //         "categoria": "huevos",
    //         "costo": "",
    //         "descripcion": "<p><b>1 Cartón de huevos x30</b></p><p><b><br></b></p>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-01-21T04:54:05.493Z",
    //         "galeria": "[\"50276801609532425.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "21598511609532420.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Cartón De Huevos ",
    //         "oferta": "[\"Fixed\",6.5,\"2025-12-31\"]",
    //         "precio": 6.955,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "carton-de-huevos-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O3EAx5Yfv31aaOTzh77": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Preparación \":[\"Rebanada\",\"Entera\"]}]",
    //         "estado": true,
    //         "fecha_create": "2024-08-01T18:24:36.667Z",
    //         "galeria": "[\"69616671723683235.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "114971723683235.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Mortadela Caracas 500gr",
    //         "oferta": "[\"Fixed\",1.93,\"2025-12-31\"]",
    //         "precio": 2.0651,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "mortadela-caracas-500gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OArcBJGTgvUoKjnHe7S": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-04T14:42:39.458Z",
    //         "galeria": "[\"67385801730731361.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "17101551730731359.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Vel Rosa 500 ml",
    //         "oferta": "",
    //         "precio": 5.18,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 4,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "vel-rosa-500-ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NYycodWyB3bw26MOzbI": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-06-27T20:54:52.918Z",
    //         "galeria": "[\"44292761687899293.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "57798461687899292.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Suavizantekarens 1 Litro",
    //         "oferta": "",
    //         "precio": 2.13,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "suavizantekarens-1-litro",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MblJQcrqnnZYiDrywTR": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso 500gr\":[\"Bofe\",\"Riñon\",\"Pajarilla\"]}]",
    //         "estado": false,
    //         "fecha_create": "2021-06-09T15:08:53.544Z",
    //         "galeria": "[\"74641641623251334.jpg\",\"17611591623251335.jpg\",\"2736651623254998.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "48405321623251333.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Trastes ",
    //         "oferta": "[\"Fixed\",1.62,\"2025-01-31\"]",
    //         "precio": 1.7334,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "trastes-",
    //         "video": "[]",
    //         "views": 24
    //     },
    //     "-N_oqITePfbjJlcsQi1X": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-01-12T16:26:26.179Z",
    //         "galeria": "[\"10764831689882538.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "42573761689882537.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Rexona Talco 85gr",
    //         "oferta": "[\"Fixed\",2.85,\"2024-12-31\"]",
    //         "precio": 3.0495,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "rexona-talco-85gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NFQDfksddHkB9iB4ExA": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-10-27T21:03:17.626Z",
    //         "galeria": "[\"87499821667754101.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "7736301667754100.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Soya Ibiberia 300g",
    //         "oferta": "",
    //         "precio": 3.89,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "soya-ibiberia-300g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQKnQWwWBWPOR_M0UkX": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Salsa de tomate heinz 397gr</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-06T03:46:31.676Z",
    //         "galeria": "[\"38273401609904796.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "34254351609904791.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Salsa de tomate heinz 397gr",
    //         "oferta": "",
    //         "precio": 2.61,
    //         "prox": false,
    //         "sales": 3,
    //         "stock": 26,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salsa-de-tomate-heinz-397gr",
    //         "video": "[]",
    //         "views": 12
    //     },
    //     "-MPzYf1_Ijikz5SvJYH9": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "descripcion": "<br>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Cantidad \":[{\"500gr\":1.605,\"1kg\":3.21}]}]",
    //         "estado": true,
    //         "fecha_create": "2021-01-01T20:05:28.618Z",
    //         "galeria": "[\"2103761691058676.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "85036791738341940.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Callo De Res ",
    //         "oferta": "[\"Fixed\",1.5,\"2025-12-31\"]",
    //         "precio": 1.605,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "callo-de-res-",
    //         "video": "[]",
    //         "views": 46
    //     },
    //     "-N441FVdM3Ja9mi6-i1m": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-06-17T18:49:54.099Z",
    //         "galeria": "[\"60409461654720628.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "38203461654720625.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Detergente Ace De 800gr ",
    //         "oferta": "",
    //         "precio": 3.62,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 3,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "detergente-ace-de-800gr-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O82NlMTOl-7isIxw81N": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-09-30T14:43:38.881Z",
    //         "galeria": "[\"41154061727707419.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "87252321727707419.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Tripack La Viña De 150 Ml",
    //         "oferta": "",
    //         "precio": 2.27,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "tripack-la-vina-de-150-ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MYHMq50Nf3nc9YZ_7BV": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-04-14T21:52:47.799Z",
    //         "galeria": "[\"15881211618437168.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "87724511618437167.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Margarina Nelly 250g",
    //         "oferta": "",
    //         "precio": 1.33,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "margarina-nelly-250g",
    //         "video": "[]",
    //         "views": 8
    //     },
    //     "-O6DHXz3N-yMEsgFm0oh": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-09-07T21:00:53.131Z",
    //         "galeria": "[\"92959461725742853.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "7734771725742852.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Rayados acero inoxidable",
    //         "oferta": "",
    //         "precio": 2.74,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "rayados-acero-inoxidable",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NpQzUhtYsxr_iFSsup3": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-01-30T20:09:45.557Z",
    //         "galeria": "[\"39296921706645386.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "53682491706645385.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Mrmusculo",
    //         "oferta": "",
    //         "precio": 3.7,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "mrmusculo",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mpsef-Ojck67YPSReFj": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-29T08:47:52.248Z",
    //         "galeria": "[\"11408201638406991.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "637881638406990.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Onoto 100gr",
    //         "oferta": "",
    //         "precio": 1.21,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "onoto-100gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPtO_UWYyT3e3wZSgcf": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "descripcion": "<b>Carne de Bistec</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"500gr\":4.173,\"1kg\":8.346}]}]",
    //         "estado": true,
    //         "fecha_create": "2020-12-31T15:24:18.455Z",
    //         "galeria": "[\"66406821609428260.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "36069171609428257.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Carne Bistec",
    //         "oferta": "[\"Fixed\",3.9,\"2025-12-31\"]",
    //         "precio": 4.173,
    //         "prox": false,
    //         "sales": 16,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "carne-bistec",
    //         "video": "[]",
    //         "views": 289
    //     },
    //     "-NH06iJA3ZtM0wbjNass": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-05-09T08:38:17.864Z",
    //         "galeria": "[\"93474681668613596.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "21925101668613595.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Nuthos X5 ",
    //         "oferta": "[\"Fixed\",3.44,\"2025-12-31\"]",
    //         "precio": 3.5631,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "nuthos-x5-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N0MIaFiEnQcz8JU7q6X": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T16:35:50.256Z",
    //         "galeria": "[\"13603561650732196.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "5298821650732195.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Servilleta Rendy 200 Unid Cortadas ",
    //         "oferta": "[\"Fixed\",0.85,\"2025-12-31\"]",
    //         "precio": 0.9095,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "servilleta-rendy-200-unid-cortadas-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OIhFNjR-73ds-jvBNqn": {
    //         "categoria": "pollo-criollo",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-10T00:28:02.683Z",
    //         "galeria": "[\"19975181739147283.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "9919721739147282.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Nuggets De Pollo X 20 Unidades ",
    //         "oferta": "[\"Fixed\",5.2,\"2025-12-31\"]",
    //         "precio": 5.564,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "nuggets-de-pollo-x-20-unidades-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OCE_AKLMjfxlD0eE7EG": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-21T16:36:04.540Z",
    //         "galeria": "[\"78417241732206965.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "27104631732206965.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Gelatina Royal Fresa Cereza 190g",
    //         "oferta": "",
    //         "precio": 4.06,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "gelatina-royal-fresa-cereza-190g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O-jp1M08ndAyhT7tSAa": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-06-19T09:29:08.700Z",
    //         "galeria": "[\"28477661718789350.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "65334941718789349.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo Oferta 4",
    //         "oferta": "[\"Fixed\",12,\"2025-12-31\"]",
    //         "precio": 12.84,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-oferta-4",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NPBquUbPc7l25i2wjl6": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-01-16T00:33:01.251Z",
    //         "galeria": "[\"4326501677400450.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "93947361677400449.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Saltin Integral Crakeñas 384g",
    //         "oferta": "[\"Fixed\",2.92,\"2025-12-31\"]",
    //         "precio": 3.1244,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "saltin-integral-crakenas-384g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MUIdJRHqWz0nk8XQP7K": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "<br>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T16:35:50.256Z",
    //         "galeria": "[\"70432581614163559.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "7369751614163557.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabón Protex Por Unidad ",
    //         "oferta": "[\"Fixed\",1.2,\"2025-12-31\"]",
    //         "precio": 1.284,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-protex-por-unidad-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NqlFde06bD7g19rbI7E": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "descripcion": "<p>Lechuga por unidad </p>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Lechuga por unidad\":[{\"Américana\":1.5,\"Criolla\":1.5}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-02-16T09:32:27.480Z",
    //         "galeria": "[\"1107091708076229.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "7497301708076228.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Lechuga ",
    //         "oferta": "[\"Disccount\",15,\"2025-02-28\"]",
    //         "precio": 1.5,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "lechuga-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NYJ3efjC6bE6Xwufq6u": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Sabor \":[\"Durazno\",\"Pera\",\"Manzana\"]}]",
    //         "estado": true,
    //         "fecha_create": "2023-06-19T14:32:45.865Z",
    //         "galeria": "[\"33919081687185172.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "57595231687185165.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Compota Baby Fruit ",
    //         "oferta": "[\"Fixed\",0.86,\"2024-12-31\"]",
    //         "precio": 0.9202,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "compota-baby-fruit-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MWg336lVZ8NezCzyRzo": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "Desinfectante las llaves vinagre activo fragancia marea cristalina 1ltro&nbsp;",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-03-26T01:04:12.149Z",
    //         "galeria": "[\"30242971616720695.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "77055891616720642.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Desinfectante Las Llaves Marea Cristalina 1ltro",
    //         "oferta": "",
    //         "precio": 3.86,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "desinfectante-las-llaves-marea-cristalina-1ltro",
    //         "video": "[]",
    //         "views": 11
    //     },
    //     "-OIMaKYyJdexXWmz4Kp7": {
    //         "categoria": "bebidas-y-lacteos",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-05T19:32:13.368Z",
    //         "galeria": "[\"40454861738783936.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "47983711738783933.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Coca cola 1ltro",
    //         "oferta": "[\"Fixed\",1.09,\"2025-12-31\"]",
    //         "precio": 1.1663,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "coca-cola-1ltro",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mx0-lnV1QI6wf4M_9r9": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-02-28T15:46:36.151Z",
    //         "galeria": "[\"65616361646063196.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "93777831646063196.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Cafe Kiwa 200gr",
    //         "oferta": "",
    //         "precio": 2.05,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cafe-kiwa-200gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MoEUEE_LoDrht8JHhaj": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "Gelatina Frutiño Sabores Surtidos X4",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-07-19T16:30:31.640Z",
    //         "galeria": "[\"70567661636643119.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "71084641636643115.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Gelatina Frutiño Sabores surtidos x4",
    //         "oferta": "[\"Fixed\",2.11,\"2025-01-31\"]",
    //         "precio": 2.2577,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "gelatina-frutino-sabores-surtidos-x4",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mi8IYEe0MbSqBkZq4Le": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-08-27T20:30:07.793Z",
    //         "galeria": "[\"91894361630096208.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "53814101630096207.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Pride Multiuso Aerosol",
    //         "oferta": "",
    //         "precio": 5.9,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pride-multiuso-aerosol",
    //         "video": "[]",
    //         "views": 3
    //     },
    //     "-Mi7sjO29PhgMRFZvzJW": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "Arvejas Amarillas Enteras Pantera 500g",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-01-13T15:16:25.055Z",
    //         "galeria": "[\"71073231630159377.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "30323701630159372.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Arvejas Amarillas Enteras Pantera 500g",
    //         "oferta": "[\"Fixed\",1.14,\"2024-12-31\"]",
    //         "precio": 1.2198,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "arvejas-amarillas-enteras-pantera-500g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NnySG9Qn3jMaERlDPvH": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-28T21:31:33.903Z",
    //         "galeria": "[\"71957501705076133.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "6787701705076126.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Colgate Menta 60ml ",
    //         "oferta": "[\"Fixed\",1.27,\"2025-12-31\"]",
    //         "precio": 1.3589,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "colgate-menta-60ml-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NehyZBaH-I7eyqDZQZP": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-09-19T15:07:23.185Z",
    //         "galeria": "[\"63471941695135972.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "9166191695135972.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Semillas De Girasol Pantera 400g",
    //         "oferta": "[\"Fixed\",2.76,\"2023-12-31\"]",
    //         "precio": 2.86,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "semillas-de-girasol-pantera-400g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N7kl5bluU71Vp8T4ZPq": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-10-05T13:46:29.835Z",
    //         "galeria": "[\"19518991658675553.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "30177321658675551.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Arroz Mary Dorado 800g",
    //         "oferta": "",
    //         "precio": 1.9,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 30,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "arroz-mary-dorado-800g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O9UsW4--SQI0vjxk5C7": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-18T13:47:25.657Z",
    //         "galeria": "[\"61653451729259245.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "51707061729259244.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Crema Dental Oral B 70g",
    //         "oferta": "",
    //         "precio": 3.38,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "crema-dental-oral-b-70g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPtNkf7tCUQ7QcYlUgE": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "descripcion": "<b>2Kg Aproximado&nbsp;&nbsp;</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Lomito\":[{\"En bisteck 1.8 kg\":15.9858,\"Entero 1.8 kg\":15.9858}]}]",
    //         "estado": true,
    //         "fecha_create": "2020-12-31T15:20:44.157Z",
    //         "galeria": "[\"35909381609428044.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "201471609428043.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Lomito De Res 1.8kg",
    //         "oferta": "[\"Fixed\",14.94,\"2025-12-31\"]",
    //         "precio": 15.9858,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "lomito-de-res-1-8kg",
    //         "video": "[]",
    //         "views": 18
    //     },
    //     "-OCxkpOC9cWJM_IKyKLK": {
    //         "categoria": "licores",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-30T15:49:29.327Z",
    //         "galeria": "[\"27542231732981772.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "59333831732981769.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Buchanan's Delux",
    //         "oferta": "[\"Fixed\",39,\"2025-12-31\"]",
    //         "precio": 41.73,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "buchanan's-delux",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MmhwiNRUXRoOmLM965_": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<p>Especies para dulce&nbsp;</p><p>1. Clavos de olor&nbsp;</p><p>2 canela entera&nbsp;</p><p>3. Canela molida&nbsp;</p><p>4. Guayabita&nbsp;</p>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Sabores\":[{\"Canela entera \":1.12,\"Canela molida\":1.29,\"Guayabita\":0.74,\"Anís estrellado \":1.06,\"Clavito\":0.44,\"Nuez moscada \":0.71}]}]",
    //         "estado": true,
    //         "fecha_create": "2021-10-23T16:19:02.460Z",
    //         "galeria": "[\"71868941635005948.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "43631481635005942.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Especias dulces ",
    //         "oferta": "",
    //         "precio": 1,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "especias-dulces-",
    //         "video": "[]",
    //         "views": 1
    //     },
    //     "-MszQCt2G5kdVAMvonpQ": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T19:34:21.766Z",
    //         "galeria": "[\"61641521641741604.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "81526811641741599.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Galleta Saltisima 6 Tacos",
    //         "oferta": "[\"Fixed\",2.22,\"2025-12-31\"]",
    //         "precio": 2.3754,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "galleta-saltisima-6-tacos",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mqigt0Y8e8g5WFwxJzV": {
    //         "categoria": "bebidas-y-lacteos",
    //         "costo": "",
    //         "descripcion": "Refresco Golden 2lt&nbsp;",
    //         "detalles": "",
    //         "especificaciones": "[{\"Sabor\":[\"Manzana\",\"Kolita\",\"7up\"]}]",
    //         "estado": true,
    //         "fecha_create": "2021-12-16T15:16:22.287Z",
    //         "galeria": "[\"97184911639313540.jpg\",\"51273171639313542.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "52965561639313535.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Refresco Golden 2lt ",
    //         "oferta": "[\"Fixed\",1.76,\"2025-12-31\"]",
    //         "precio": 1.8832,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "refresco-golden-2lt-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NVif1zR8hPXfHN75-r_": {
    //         "categoria": "bebidas-y-lacteos",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2023-05-18T11:43:34.593Z",
    //         "galeria": "[\"44448581684410215.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "18895031684410214.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Speed max ",
    //         "oferta": "[\"Fixed\",0.46,\"2023-12-31\"]",
    //         "precio": 1,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "speed-max-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MR_O1jQyKETtkyyLqYg": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "descripcion": "<p><b>Plátano maduro</b></p><ul><li><b>1kg</b></li><li><b>1.5kg</b></li></ul>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"1kg\":1.3,\"1.5 Kg \":1.95,\"2 kg\":2.6}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-02-16T09:42:18.255Z",
    //         "galeria": "[\"8727251611240057.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "60564571611240056.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Plátano Maduro",
    //         "oferta": "[\"Disccount\",15,\"2025-02-28\"]",
    //         "precio": 1.3,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "platano-maduro",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NFv1DSC9HKxjc2d2JN0": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-11-03T01:09:09.191Z",
    //         "galeria": "[\"87057501667754823.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "55762431667754822.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Atún Margarita Natural 140g",
    //         "oferta": "",
    //         "precio": 2.12,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "atun-margarita-natural-140g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NqlLly4sfU6ttWYCR5U": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-02-16T09:59:14.513Z",
    //         "galeria": "[\"50784091708077555.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "82085041708077554.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Perejil",
    //         "oferta": "[\"Disccount\",10,\"2024-12-31\"]",
    //         "precio": 0.9,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "perejil",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Ng-57_ESB2SeL_IM5xK": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-10-05T13:48:08.409Z",
    //         "galeria": "[\"70954481696513689.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "72986011696513688.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Desodorante Para Pies Xen 260ml",
    //         "oferta": "[\"Fixed\",3.48,\"2025-12-31\"]",
    //         "precio": 3.7236,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "desodorante-para-pies-xen-260ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MpvQMpAVC4Mufz_UjOE": {
    //         "categoria": "pollo-criollo",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Filet Pechuga picada para hallacas\":[{\"500kg \":4.5475,\"1kg\":9.095}]}]",
    //         "estado": true,
    //         "fecha_create": "2022-05-10T23:01:36.149Z",
    //         "galeria": "[\"76421691638453312.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "25585631638453311.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Pechuga Suprema picada Para Hallacas ",
    //         "oferta": "[\"Fixed\",4.25,\"2025-12-31\"]",
    //         "precio": 4.5475,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pechuga-suprema-picada-para-hallacas-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NPSuBiTq3gdrPSzyRlI": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-03-01T16:01:57.114Z",
    //         "galeria": "[\"28596551677686524.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "45361801677686516.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Aceite Calioil 1000ml",
    //         "oferta": "[\"Fixed\",1.7,\"2023-07-31\"]",
    //         "precio": 1.8,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "aceite-calioil-1000ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPtJuxRDGSX1kM9YYrc": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "descripcion": "Solomo de Cuerito",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"500gr\":4.28,\"1kg\":8.56}]}]",
    //         "estado": true,
    //         "fecha_create": "2022-05-11T20:35:22.698Z",
    //         "galeria": "[\"88334061609427037.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "73111311609427036.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Solomo de Cuerito",
    //         "oferta": "[\"Fixed\",4,\"2025-12-31\"]",
    //         "precio": 4.28,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "solomo-de-cuerito",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NLgohtpsxBKskfE3Ba0": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-01-13T20:29:37.029Z",
    //         "galeria": "[\"38861111673641778.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "95439341673641777.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Enjuague Bucal Bucarine 500ml",
    //         "oferta": "",
    //         "precio": 2.15,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "enjuague-bucal-bucarine-500ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MbgnauzGRpbbCGORcp6": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Mortadela\":[{\"Rebanada 500gr\":2.508,\"Rebanada 1kg\":5.016}]}]",
    //         "estado": true,
    //         "fecha_create": "2023-06-19T14:32:45.865Z",
    //         "galeria": "[\"34083421623175621.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "15252221623175620.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Mortadela Punta Monte",
    //         "oferta": "[\"Fixed\",2.28,\"2025-12-31\"]",
    //         "precio": 2.508,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "mortadela-punta-monte",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O6DITdXULZ8ALhNHDnQ": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-09-07T21:04:55.795Z",
    //         "galeria": "[\"8054151725743097.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "83977891725743095.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Papel Aluminio 7 Mts",
    //         "oferta": "",
    //         "precio": 2.5,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "papel-aluminio-7-mts",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O9BVqouCeNUQCJTB993": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-14T19:31:15.391Z",
    //         "galeria": "[\"91735511728934275.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "1922731728934275.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Arroz Primor X 24 kg",
    //         "oferta": "[\"Fixed\",30,\"2024-12-31\"]",
    //         "precio": 32.1,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "arroz-primor-x-24-kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPl1alHJebVS6x3hsjZ": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "<ol><li><b>1/2kg Queso Tipo Paisa</b></li><li><b>1/2kg Queso Duro</b></li><li><b>1/2 Jamón de Espalda Arichuna</b></li><li><b>1/2 Boloña Corral</b></li><li><b>1/2 Cartón de Huevos</b></li><li><b>1 Crema de Leche 500gr</b></li></ol>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2020-12-30T00:26:54.430Z",
    //         "galeria": "[\"61692511690539557.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "85256501690539551.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo Arepero",
    //         "oferta": "[\"Fixed\",19,\"2025-12-31\"]",
    //         "precio": 20.33,
    //         "prox": false,
    //         "sales": 2,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-arepero",
    //         "video": "[]",
    //         "views": 180
    //     },
    //     "-OK7UbY8bhiN8sfYcdMo": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-27T17:40:00.171Z",
    //         "galeria": "[\"91916891740678002.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "13892311740678001.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabón Moncler 90g",
    //         "oferta": "",
    //         "precio": 1.12,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 48,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-moncler-90g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N3KMftxp4u5EE1KoZlN": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-05-30T14:28:56.920Z",
    //         "galeria": "[\"52843051653920938.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "23748501653920937.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Vela Número 80 X 5 unidades ",
    //         "oferta": "",
    //         "precio": 2.5,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 80,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "vela-numero-80-x-5-unidades-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQKowpS2IrkA836LsJE": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Servilleta Z Grande</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-06T03:53:14.725Z",
    //         "galeria": "[\"62246741609905195.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "19866531609905194.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Servilleta Z Grande",
    //         "oferta": "",
    //         "precio": 2.85,
    //         "prox": false,
    //         "sales": 1,
    //         "stock": 32,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "servilleta-z-grande",
    //         "video": "[]",
    //         "views": 3
    //     },
    //     "-Nnzp6YO1ZREVQA9hXRG": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-01-12T22:39:22.994Z",
    //         "galeria": "[\"84971771705099163.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "41826421705099163.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Enjuague Bucal Colgate 500ml",
    //         "oferta": "",
    //         "precio": 5.88,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "enjuague-bucal-colgate-500ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OCJH68zA7e4BKt8DQWT": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-22T14:30:48.601Z",
    //         "galeria": "[\"97024981732285853.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "35895181732285849.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Tobo Plástico 13 Litros",
    //         "oferta": "",
    //         "precio": 3.12,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "tobo-plastico-13-litros",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NPFfsDGDLbqkyHaBelp": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-02-27T02:24:23.423Z",
    //         "galeria": "[\"9949871677464665.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "7108911677464665.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Bonaropa Quita Manchas Ropa Color 1lt",
    //         "oferta": "[\"Fixed\",1.44,\"2023-12-31\"]",
    //         "precio": 1.54,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "bonaropa-quita-manchas-ropa-color-1lt",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NFJuWEZWDf9Bn1iYD-a": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-04-18T20:33:04.221Z",
    //         "galeria": "[\"6502241667836145.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "3323891667836144.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Rosal X4 Plus 600 Hojas",
    //         "oferta": "",
    //         "precio": 3.38,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "rosal-x4-plus-600-hojas",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NRHnicJwYxM3nfT9FD5": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-03-24T08:49:20.397Z",
    //         "galeria": "[\"97787501679647762.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "98026231679647760.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo Mercafruver Practi",
    //         "oferta": "[\"Fixed\",65,\"2025-12-31\"]",
    //         "precio": 69.55,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-mercafruver-practi",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N7ML8Q1KuOXKKrnRMX8": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Sabor\":[\"Fresa\",\"Vainilla\"]}]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T16:09:15.635Z",
    //         "galeria": "[\"72088661658249056.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "44359981658249044.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Galleta Festival 600g",
    //         "oferta": "[\"Fixed\",4.63,\"2025-12-31\"]",
    //         "precio": 4.9541,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "galleta-festival-600g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MT1KOLLP-AKOLxqXkE5": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "Toallas Duos nocturna se con alas&nbsp;",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-01-08T21:50:44.527Z",
    //         "galeria": "[\"91773121612799379.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "8290551612799377.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Toallas duo Nocturnas Con Alas Ellas",
    //         "oferta": "[\"Fixed\",1.55,\"2023-04-30\"]",
    //         "precio": 1.65,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "toallas-duo-nocturnas-con-alas-ellas",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MV1xEtsOyEaRwkS2LkT": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<p>Carbón vegetal</p><ul><li>Abasto pachos</li></ul>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-03-05T15:14:22.732Z",
    //         "galeria": "[\"16318521614957312.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "26301811614957262.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Carbón Vegetal 2kg",
    //         "oferta": "",
    //         "precio": 3,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "carbon-vegetal-2kg",
    //         "video": "[]",
    //         "views": 3
    //     },
    //     "-OJTs3ESbyOmuvEBdesQ": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-19T15:42:46.246Z",
    //         "galeria": "[\"583241739979768.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "13896271739979766.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Champoo Sedal Zero Caspa 2 En 1 340ml",
    //         "oferta": "",
    //         "precio": 4.71,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "champoo-sedal-zero-caspa-2-en-1-340ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NWNiiXT8Osy-JIApBQM": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-04-03T12:24:45.086Z",
    //         "galeria": "[\"7147861685115823.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "91853451685115822.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Salsa Soya Heinz 150g",
    //         "oferta": "",
    //         "precio": 2.2,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salsa-soya-heinz-150g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MV1z1RhqsSr_jIcEpXB": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "<p><b>Don toallin</b></p><ul><li><b>Paveca</b></li></ul>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-03-05T15:22:58.474Z",
    //         "galeria": "[\"88889751614957780.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "18167841614957778.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Don Ttoallin Paveca",
    //         "oferta": "",
    //         "precio": 1.15,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 40,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "don-ttoallin-paveca",
    //         "video": "[]",
    //         "views": 4
    //     },
    //     "-Mb3tHhLtyCQhRP0bniN": {
    //         "categoria": "bebes-",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-06-01T00:06:58.446Z",
    //         "galeria": "[\"6280771622506021.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "93928261622506018.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Champu Chicco  200ml",
    //         "oferta": "",
    //         "precio": 3.5,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "champu-chicco--200ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NHjfKcOdSq_pbyv3efL": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Dove Rollon 50ml\":[{\"Dove Rollon 50ml Original\":2.5}]}]",
    //         "estado": true,
    //         "fecha_create": "2023-06-08T12:40:31.288Z",
    //         "galeria": "[\"1212711669394683.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "29991221669394681.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Dove Rollon 50ml",
    //         "oferta": "",
    //         "precio": 2.5,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "dove-rollon-50ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NRcL2Vz6VDew-Yzvn_1": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-05-31T07:45:36.610Z",
    //         "galeria": "[\"54616661680009082.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "73366071680009079.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Atun Eveba Aceite 170gr",
    //         "oferta": "",
    //         "precio": 2.56,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "atun-eveba-aceite-170gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MikkFwuHx28miyg4dl0": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-10-05T13:46:29.835Z",
    //         "galeria": "[\"88528701630758038.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "20879971630758036.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Galleta marilu chocolate",
    //         "oferta": "",
    //         "precio": 1.88,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "galleta-marilu-chocolate",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OAwi74JpQgkbwt4r6_n": {
    //         "categoria": "bebes-",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-05T14:26:42.898Z",
    //         "galeria": "[\"98107851730816802.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "68373541730816802.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Toallas Humedas X50 Baby",
    //         "oferta": "",
    //         "precio": 1.29,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "toallas-humedas-x50-baby",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OCxb1Ih_Z8NKdyXXjxl": {
    //         "categoria": "bebidas-y-lacteos",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-30T15:06:19.862Z",
    //         "galeria": "[\"27281421732979203.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "3981841732979179.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Jugo Yukeri Naranja 1.5lt",
    //         "oferta": "[\"Fixed\",4.25,\"2025-12-31\"]",
    //         "precio": 4.5475,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jugo-yukeri-naranja-1-5lt",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQK0BoZ9ZJXAe7e1v2l": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Garbanzo La Gran Redoma 500g</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-04-13T19:17:52.443Z",
    //         "galeria": "[\"43882911609891629.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "93041761609891628.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "G Garbanzo La Gran Redoma 500g",
    //         "oferta": "",
    //         "precio": 1.87,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "g-garbanzo-la-gran-redoma-500g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-ODWb4S0EAJU23nLkkNa": {
    //         "categoria": "bebidas-y-lacteos",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-12-07T14:53:11.093Z",
    //         "galeria": "[\"94487881733583197.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "90965901733583190.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Agua Minalba 1.5lt",
    //         "oferta": "[\"Fixed\",1.93,\"2025-12-31\"]",
    //         "precio": 2.0651,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "agua-minalba-1-5lt",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NNvNv5XesynxDwakxTc": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-07-08T17:11:23.471Z",
    //         "galeria": "[\"45852721676033633.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "72398161676033624.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabon Tripack Lux ",
    //         "oferta": "[\"Fixed\",3.25,\"2025-01-31\"]",
    //         "precio": 3.4775,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-tripack-lux-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O0UBTgmC70dFoaSoRWp": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-06-28T14:13:42.723Z",
    //         "galeria": "[\"661671719584024.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "5700921719584023.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Bianche Barra Surtido 22g",
    //         "oferta": "",
    //         "precio": 0.41,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "bianche-barra-surtido-22g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MfK-oJpvh8fNooKHCcB": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-04-13T19:17:52.443Z",
    //         "galeria": "[\"6942711627071398.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "21982901627071395.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Cafe Flor De Arauca 200gr",
    //         "oferta": "",
    //         "precio": 2.12,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 20,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cafe-flor-de-arauca-200gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQ30v8dS4KJnwH6eusV": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-aue-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Suavizante las llaves 500cm³</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-02T16:56:45.454Z",
    //         "galeria": "[\"80006161609606606.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "28911991609606604.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Suavizante las llaves 500cm",
    //         "oferta": "",
    //         "precio": 3.47,
    //         "prox": false,
    //         "sales": 1,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "suavizante-las-llaves-500cm",
    //         "video": "[]",
    //         "views": 3
    //     },
    //     "-Nvx-X_YAZjaCrJu2hAh": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-04-20T19:35:57.637Z",
    //         "galeria": "[\"81215821713641757.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "61723701713641756.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Desengrazante Mr Músculo con pistola",
    //         "oferta": "",
    //         "precio": 5.4,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 3,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "desengrazante-mr-musculo-con-pistola",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQPruJ0zwAIe8nWYclv": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<b>Salsa Puré Grande De Tomate Heinz</b><br>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-07T03:24:15.171Z",
    //         "galeria": "[\"58898661609989857.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "93631021609989854.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Salsa Puré grande De Tomate Heinz ",
    //         "oferta": "",
    //         "precio": 4.31,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salsa-pure-grande-de-tomate-heinz-",
    //         "video": "[]",
    //         "views": 8
    //     },
    //     "-Mww5Pfeugb-_lgkMkf7": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-01-12T16:26:26.179Z",
    //         "galeria": "[\"76261151645980788.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "76401441645980787.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabón Rexona 120g ",
    //         "oferta": "[\"Fixed\",1.15,\"2025-01-31\"]",
    //         "precio": 1.2305,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-rexona-120g-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MbBifJC4AxTE_2ssNUZ": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-06-02T12:37:29.238Z",
    //         "galeria": "[\"99316561622637454.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "66382001622637450.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Cafe fama de america 200g",
    //         "oferta": "",
    //         "precio": 2.024,
    //         "prox": false,
    //         "sales": 3,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cafe-fama-de-america-200g",
    //         "video": "[]",
    //         "views": 10
    //     },
    //     "-NGhFUPy_AOcXdF4Z6Fp": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-01-16T00:33:01.251Z",
    //         "galeria": "[\"88749431668280349.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "1144881668280347.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Papel rendy x2",
    //         "oferta": "[\"Fixed\",1.34,\"2025-12-31\"]",
    //         "precio": 1.4338,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "papel-rendy-x2",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NnyQDuN1CQwxTXhH1bp": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-01-12T16:15:24.753Z",
    //         "galeria": "[\"76379161705075600.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "61845471705075594.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Colgate Luminous White 75ml",
    //         "oferta": "[\"Fixed\",5.61,\"2024-12-31\"]",
    //         "precio": 6,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "colgate-luminous-white-75ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OCnA5IGsPyyy_DA2SG4": {
    //         "categoria": "articulos-de-belleza",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-28T14:28:16.255Z",
    //         "galeria": "[\"69729001732804108.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "38336821732804095.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Kolors Tratamiento Crema Para Peinar 2en1",
    //         "oferta": "[\"Fixed\",3,\"2025-01-31\"]",
    //         "precio": 3.18,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "kolors-tratamiento-crema-para-peinar-2en1",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NKZKkb595kCnqQUI-uY": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-12-30T18:41:43.157Z",
    //         "galeria": "[\"15388541672425703.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "48832791672425702.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Sopa Pollo Con Fideo",
    //         "oferta": "",
    //         "precio": 1.25,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "sopa-pollo-con-fideo",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MS3TvPmvHMQHU1I67Kq": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "<b>Crema Dental Fluo Cardent</b>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-02-16T17:35:27.564Z",
    //         "galeria": "[\"63273891611761693.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "37948571611761691.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Crema Dental Fluo Cardent 75 ml ",
    //         "oferta": "[\"Fixed\",1.13,\"2025-12-31\"]",
    //         "precio": 1.2091,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "crema-dental-fluo-cardent-75-ml-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mqyd7RGAqvgwrQTC_P6": {
    //         "categoria": "mascotas",
    //         "costo": "",
    //         "descripcion": "Galletas Para Gatos Magic Friends 75g",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2023-01-13T20:29:37.029Z",
    //         "galeria": "[\"18655321639580992.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "84297361639580990.png",
    //         "mostrarOcultar": false,
    //         "nombre": "GALLETAS PARA GATOS MAGIC FRIENDS 75G",
    //         "oferta": "[\"Fixed\",0.84,\"2023-12-31\"]",
    //         "precio": 0.91,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "galletas-para-gatos-magic-friends-75g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OBQYc7kstuk8SRBxpBB": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-11T14:09:01.993Z",
    //         "galeria": "[\"38292681731334144.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "95491801731334142.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Ace regular 5kg",
    //         "oferta": "",
    //         "precio": 18.88,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "ace-regular-5kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N5_DXYmW6h8H4k_nvn6": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "Mostaza heinz 195g",
    //         "detalles": "",
    //         "especificaciones": "[{\"Moztasa \":[{\"Heinz 195 gr\":2.3}]}]",
    //         "estado": true,
    //         "fecha_create": "2022-06-27T12:54:21.904Z",
    //         "galeria": "[\"40213801656334460.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "13442871656334460.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Mostaza Heinz 195g",
    //         "oferta": "",
    //         "precio": 2.3,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "mostaza-heinz-195g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQKg6V92jjdbWvGAzkx": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Salsa 57 Heinz</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-06T03:14:37.498Z",
    //         "galeria": "[\"45963881609902880.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "24953531609902877.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Salsa 57 Heinz",
    //         "oferta": "",
    //         "precio": 2.74,
    //         "prox": false,
    //         "sales": 1,
    //         "stock": 3,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salsa-57-heinz",
    //         "video": "[]",
    //         "views": 3
    //     },
    //     "-MQ37qpaU2OAlQ3-Xf38": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-aue-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Afeitadora Dorco ×2</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-02T17:27:02.688Z",
    //         "galeria": "[\"37724611609608423.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "39666281609608421.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Afeitadora Dorco x2",
    //         "oferta": "",
    //         "precio": 0.54,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "afeitadora-dorco-x2",
    //         "video": "[]",
    //         "views": 2
    //     },
    //     "-O1cTo3uSuzjBdC80YQC": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-07-12T19:46:06.662Z",
    //         "galeria": "[\"53631791720813567.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "95941871720813567.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Pala Anandecol",
    //         "oferta": "",
    //         "precio": 2.12,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pala-anandecol",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPt7g5QT4Rn5dgHANoJ": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "<b>Queso Llanero Agua Linda</b>",
    //         "detalles": "[{\"title\":\"Marca\",\"value\":\"Agua Linda\"}]",
    //         "especificaciones": "[{\"Peso\":[{\"500gr\":3.959,\"1kg\":7.918}]}]",
    //         "estado": true,
    //         "fecha_create": "2023-01-30T16:10:48.283Z",
    //         "galeria": "[\"94919121609423830.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "69387861609423830.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Queso Merideño ",
    //         "oferta": "[\"Fixed\",3.7,\"2025-12-31\"]",
    //         "precio": 3.959,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "queso-merideno-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQJtK3H3THLXC0XwEaK": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Diablitos Under Wood 115g</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-05T23:32:45.142Z",
    //         "galeria": "[\"21684371609889566.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "62191681609889565.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Diablitos Under Wood 115g",
    //         "oferta": "",
    //         "precio": 2.56,
    //         "prox": false,
    //         "sales": 3,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "diablitos-under-wood-115g",
    //         "video": "[]",
    //         "views": 8
    //     },
    //     "-OC_-FHBvAVpuZFu2riq": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-25T20:26:23.771Z",
    //         "galeria": "[\"30472121732566385.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "86658431732566384.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Salsa Ketchup Under Woof 380 g",
    //         "oferta": "",
    //         "precio": 2.77,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salsa-ketchup-under-woof-380-g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQ2pby3dYMaNZD-_3Zf": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-aue-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Jabón Vel Rosa 500cm³</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-02-09T21:29:11.961Z",
    //         "galeria": "[\"10041981609603382.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "76015131609603381.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabón Vel Rosa 500cm",
    //         "oferta": "",
    //         "precio": 5.19,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-vel-rosa-500cm",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MmdieL2zJmhUracVXAj": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-10-22T20:39:12.174Z",
    //         "galeria": "[\"54818741634935153.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "26417901634935152.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Lomo De Atun Eveba En Aceite 140g",
    //         "oferta": "",
    //         "precio": 2.14,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 30,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "lomo-de-atun-eveba-en-aceite-140g",
    //         "video": "[]",
    //         "views": 2
    //     },
    //     "-OAN6XcPQ0mjThmhDd22": {
    //         "categoria": "licores",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-09T09:20:27.640Z",
    //         "galeria": "[\"7859541730202708.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "40219031730202707.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Cerveza Zulia Caja Retornable ",
    //         "oferta": "[\"Fixed\",20,\"2025-12-31\"]",
    //         "precio": 21.4,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cerveza-zulia-caja-retornable-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N43Jg1TvSvVFl1t84Pl": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Mortadela \":[{\"Rebanada 250gr\":1.177,\"Rebanada 300g\":1.4124,\"Rebanada 500gr\":2.354,\"Rebanada 1kg\":4.708}]}]",
    //         "estado": true,
    //         "fecha_create": "2023-12-04T15:13:21.902Z",
    //         "galeria": "[\"75928161654708682.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "13621591654708681.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Mortadela Corral",
    //         "oferta": "[\"Fixed\",1.1,\"2025-12-31\"]",
    //         "precio": 1.177,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "mortadela-corral",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPt3LkK9xycSgOYEgaX": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "<b>Salchicha Cocida de Pollo Superior Tipo Bologna</b>",
    //         "detalles": "[{\"title\":\"Marca\",\"value\":\"Corral\"}]",
    //         "especificaciones": "[{\"Bologña \":[{\"500gr rebanada\":2.3005,\"1kg rebanada \":4.601,\"300gr\":1.3803}]}]",
    //         "estado": true,
    //         "fecha_create": "2021-11-05T19:52:19.856Z",
    //         "galeria": "[\"53926501614694891.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "32057971614694889.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Bologna Corral",
    //         "oferta": "[\"Fixed\",2.15,\"2025-12-31\"]",
    //         "precio": 2.3005,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "bologna-corral",
    //         "video": "[]",
    //         "views": 2
    //     },
    //     "-NNpOeiO9ZbT8nA18qFS": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-02-09T08:59:23.473Z",
    //         "galeria": "[\"3452931675933166.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "85854161675933165.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Gomitas Trululu Surtidas X6 Unidades ",
    //         "oferta": "[\"Fixed\",4.3,\"2024-12-31\"]",
    //         "precio": 4.601,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "gomitas-trululu-surtidas-x6-unidades-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O4GISfqo3LfaiecwmmZ": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Cantidad\":[{\"500gr\":3.2849,\"1kg\":6.5698}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-08-14T14:32:20.838Z",
    //         "galeria": "[\"75554501723645941.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "24292021723645940.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Queso ahumado ",
    //         "oferta": "[\"Fixed\",3.07,\"2025-12-31\"]",
    //         "precio": 3.2849,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1000,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "queso-ahumado-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NGfJOaNCn3D_pkjgiq0": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-11-12T10:10:18.831Z",
    //         "galeria": "[\"84681761668247819.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "92731181668247818.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Cera Tapa Amarilla 1 lit",
    //         "oferta": "",
    //         "precio": 2.7,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cera-tapa-amarilla-1-lit",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OBGzSwplcplgxQzNflr": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-09T17:33:41.666Z",
    //         "galeria": "[\"31722161731173624.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "16039931731173621.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Dalvito cheddar 200g",
    //         "oferta": "",
    //         "precio": 3.74,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "dalvito-cheddar-200g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPtAGxN_HSRNQlTebt1": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "descripcion": "<b>Costilla de Res</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Cantidad \":[{\"500gr\":2.14,\"1kg \":4.28}]}]",
    //         "estado": true,
    //         "fecha_create": "2020-12-31T14:21:49.419Z",
    //         "galeria": "[\"79784791699866241.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "64078611737223850.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Costilla De Res ",
    //         "oferta": "[\"Fixed\",4,\"2025-12-31\"]",
    //         "precio": 4.28,
    //         "prox": false,
    //         "sales": 7,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "costilla-de-res-",
    //         "video": "[]",
    //         "views": 61
    //     },
    //     "-MiBzddArRggd2Rl8w9r": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "Avena En Hojuelas Pantera 800g",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-28T22:32:22.101Z",
    //         "galeria": "[\"63747241630158100.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "25268491630158089.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Avena En Hojuelas Pantera 800g",
    //         "oferta": "[\"Fixed\",2.88,\"2025-12-31\"]",
    //         "precio": 3.0816,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "avena-en-hojuelas-pantera-800g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MY85qldAZlGe-GpUWrX": {
    //         "categoria": "mascotas",
    //         "costo": "",
    //         "descripcion": "<font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">Gatarina Magic Friends 1kg</font></font>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-01-16T00:33:01.251Z",
    //         "galeria": "[\"87737911618281716.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "3502631618281699.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Gatarina Magic Friends 1kg",
    //         "oferta": "[\"Fixed\",2.83,\"2025-12-31\"]",
    //         "precio": 3.0281,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "gatarina-magic-friends-1kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MVq157MEWdw7D2ONyYN": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "Lomitos de atún carlo forte 170g",
    //         "detalles": "",
    //         "especificaciones": "[{\"Sabor \":[\"Agua\",\"Aceite\"]}]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T19:27:21.853Z",
    //         "galeria": "[\"19588201615814219.jpg\",\"74128861638373013.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "36945801615814219.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Atun Lomo Carlo Forte 170g",
    //         "oferta": "[\"Fixed\",2.12,\"2026-12-31\"]",
    //         "precio": 2.2684,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "atun-lomo-carlo-forte-170g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQP_OhH7a3FIlUc5Ki-": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "<b>Limpiador De Pocetas</b>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-06-02T22:31:38.598Z",
    //         "galeria": "[\"7047221609985005.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "78529211609985004.jpg",
    //         "mostrarOcultar": true,
    //         "nombre": "Limpiador De Pocetas De 1litr",
    //         "oferta": "",
    //         "precio": 5.68,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 3,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "limpiador-de-pocetas-de-1litr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MR_QYnCOoUQUrlzGrFV": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"1 kg\":1,\"1.5kg\":1.5}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-02-16T09:42:18.255Z",
    //         "galeria": "[\"73335491611240716.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "2595371611240716.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Ahuyama",
    //         "oferta": "[\"Disccount\",15,\"2025-02-28\"]",
    //         "precio": 1,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "ahuyama",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MUFnCVwjaDxu9Eej6Tn": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "Pepito 80g",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-02-23T21:30:17.319Z",
    //         "galeria": "[\"66387331614115820.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "65249311614115817.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Pepito 80g",
    //         "oferta": "",
    //         "precio": 1.35,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pepito-80g",
    //         "video": "[]",
    //         "views": 4
    //     },
    //     "-NfRvs6Y5zz6XuFfn37B": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-09-28T17:56:36.664Z",
    //         "galeria": "[\"50272831695923798.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "51682091695923797.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Melocotón Almíbar 850g Tigo",
    //         "oferta": "",
    //         "precio": 4.22,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "melocoton-almibar-850g-tigo",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O-fXFeZ78dvTv9Nk_rk": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-06-18T13:28:36.415Z",
    //         "galeria": "[\"29962911718717319.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "18222701718717316.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo Surtido 1",
    //         "oferta": "[\"Fixed\",43,\"2025-12-31\"]",
    //         "precio": 46.01,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-surtido-1",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MTuouoauQy6OZhOTUmU": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"1kg\":1.8}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-02-16T09:42:18.255Z",
    //         "galeria": "[\"86035711613747165.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "96119531613747163.jpg",
    //         "mostrarOcultar": true,
    //         "nombre": "Parchita",
    //         "oferta": "[\"Disccount\",10,\"2025-02-28\"]",
    //         "precio": 1.8,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "parchita",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MszTSFhmDJ7qOxnM6Jo": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-09-23T14:50:27.864Z",
    //         "galeria": "[\"2344151641742454.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "26643981641742445.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Café Propatria 200g",
    //         "oferta": "[\"Fixed\",2.7,\"2025-01-31\"]",
    //         "precio": 2.889,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cafe-propatria-200g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O-jnBSwD4cjxU2lngEB": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-06-19T09:21:06.498Z",
    //         "galeria": "[\"60298201718788867.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "43649341718788867.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo Oferta 1",
    //         "oferta": "[\"Fixed\",12,\"2025-12-31\"]",
    //         "precio": 12.84,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-oferta-1",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N6ZiaAP3_MUJmxNNF8O": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-07-09T20:50:26.600Z",
    //         "galeria": "[\"19512721657399828.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "60351301657399825.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Gelatina Sonrisa Cereza 10 Porciones",
    //         "oferta": "",
    //         "precio": 2.64,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "gelatina-sonrisa-cereza-10-porciones",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O2PxtyPOgK7Ov17Tv1u": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-07-22T15:02:57.454Z",
    //         "galeria": "[\"57132101721660577.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "22133921721660577.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Arroz Innova Tipo A 1kg",
    //         "oferta": "[\"Fixed\",1.26,\"2025-12-31\"]",
    //         "precio": 1.3482,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "arroz-innova-tipo-a-1kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQK84ayQDXCGInktgdt": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Maizina Americana 400g</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-06T00:41:35.162Z",
    //         "galeria": "[\"4055191609893697.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "9048791609893695.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Maizina Americana 400g",
    //         "oferta": "",
    //         "precio": 3.06,
    //         "prox": false,
    //         "sales": 1,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "maizina-americana-400g",
    //         "video": "[]",
    //         "views": 6
    //     },
    //     "-MQK_Plqt03lCLeZhDZx": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Pasta primor 1kg surtida</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-06T02:45:13.174Z",
    //         "galeria": "[\"31649671609901123.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "27264341609901112.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Pasta Primor 1kg surtida",
    //         "oferta": "",
    //         "precio": 2.24,
    //         "prox": false,
    //         "sales": 1,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pasta-primor-1kg-surtida",
    //         "video": "[]",
    //         "views": 19
    //     },
    //     "-O9oDjkRpsXD30Yy2Erl": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-22T12:36:39.802Z",
    //         "galeria": "[\"42987631729600615.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "87483231729600599.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Harina Lucharepa X 3kg ",
    //         "oferta": "[\"Fixed\",2.78,\"2025-12-31\"]",
    //         "precio": 2.9746,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "harina-lucharepa-x-3kg-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O1c_tytUEzIVYWdRiz-": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-07-12T20:17:05.047Z",
    //         "galeria": "[\"57495601720815426.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "52186101720815425.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Chips Sanitatia",
    //         "oferta": "",
    //         "precio": 2.06,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "chips-sanitatia",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NIEi57QbPGYZQFVmwrU": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2022-12-01T22:04:19.235Z",
    //         "galeria": "[\"36651941669932275.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "64295971669932258.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Bocadillo El Rey 800g",
    //         "oferta": "[\"Fixed\",2.27,\"2023-07-31\"]",
    //         "precio": 3.76,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "bocadillo-el-rey-800g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MmKDTVWaHpL_YDFQAVt": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-03-10T18:43:39.800Z",
    //         "galeria": "[\"94863531634591172.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "32631321634591171.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Baygon Verde 194ml",
    //         "oferta": "",
    //         "precio": 4.86,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "baygon-verde-194ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQKsE77FxFn6Ad308N2": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">panela</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-06T04:07:34.465Z",
    //         "galeria": "[\"6777791609906057.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "1171751609906054.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "panela",
    //         "oferta": "",
    //         "precio": 0.91,
    //         "prox": false,
    //         "sales": 10,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "panela",
    //         "video": "[]",
    //         "views": 30
    //     },
    //     "-NIEpSAnCICSnGTXMKE4": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T19:34:21.766Z",
    //         "galeria": "[\"24052491670525435.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "69989461670525434.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Lavaplatos Brilla King 500g",
    //         "oferta": "[\"Fixed\",1.02,\"2025-12-31\"]",
    //         "precio": 1.0914,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "lavaplatos-brilla-king-500g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NYJAGwP_MVC4KfKIZFX": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-06-19T15:01:30.295Z",
    //         "galeria": "[\"8412411687186902.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "52491611687186889.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Salsa De Tomate Puro Gold 397g",
    //         "oferta": "[\"Fixed\",1.98,\"2023-12-31\"]",
    //         "precio": 2.1,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salsa-de-tomate-puro-gold-397g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OAJr5Gsx_4lFUdzf2RO": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-29T08:47:52.248Z",
    //         "galeria": "[\"96508961730148066.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "2297201730148064.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Alcaparra Eureka 500g",
    //         "oferta": "",
    //         "precio": 5.14,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 50,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "alcaparra-eureka-500g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O8SArY9rYmJ1HrnYIRA": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-05T14:57:23.463Z",
    //         "galeria": "[\"1031291728140244.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "57253371728140243.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Papel Parafinado Multiuso 5 Mtrs",
    //         "oferta": "",
    //         "precio": 1.47,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 30,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "papel-parafinado-multiuso-5-mtrs",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MezGLSNTo7lchBVag-l": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-07-19T14:57:13.490Z",
    //         "galeria": "[\"8768571626706634.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "69431151626706633.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Compota Heinz Surtida 113gr",
    //         "oferta": "",
    //         "precio": 1.19,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "compota-heinz-surtida-113gr",
    //         "video": "[]",
    //         "views": 7
    //     },
    //     "-MQDim3pyMg15IaptOxg": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-cup-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\"><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">Jabón protex tripack&nbsp;</font></font></h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T16:35:50.256Z",
    //         "galeria": "[\"84841451614172745.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "83622661614172743.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabón Protex Tripack ",
    //         "oferta": "[\"Fixed\",3.45,\"2025-12-31\"]",
    //         "precio": 3.6915,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-protex-tripack-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O9VDb5noi9wcu7l8jd_": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-29T08:47:52.248Z",
    //         "galeria": "[\"32372421729265034.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "3345151729265032.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Flan Royal Sabor Vainilla 80g",
    //         "oferta": "",
    //         "precio": 3.15,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "flan-royal-sabor-vainilla-80g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NOCXnskkCf8gauz9vma": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-02-14T01:30:14.657Z",
    //         "galeria": "[\"11916641676338215.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "15849721676338214.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Crema Peinar Sedal Rizos 300ml",
    //         "oferta": "",
    //         "precio": 4.39,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "crema-peinar-sedal-rizos-300ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OIapjgm2vYIeE6IPWnP": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T18:33:43.998Z",
    //         "galeria": "[\"9986821739039634.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "69033421739039623.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Cereal Fiocco Arroz Achocolatado 320g",
    //         "oferta": "[\"Fixed\",2.25,\"2025-12-31\"]",
    //         "precio": 2.4075,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cereal-fiocco-arroz-achocolatado-320g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O1CZWwcjE83T6eOjVVm": {
    //         "categoria": "articulos-de-belleza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-07-07T14:21:15.039Z",
    //         "galeria": "[\"9773551720362077.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "50342441720362074.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Tratamiento  Capilar Roldan 300g",
    //         "oferta": "",
    //         "precio": 4.3,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 3,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "tratamiento--capilar-roldan-300g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NUzblRzd1N36YLZ4JL8": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-05-09T08:27:06.333Z",
    //         "galeria": "[\"90433581683620828.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "55749751683620827.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Chocolate Nuzart 350g",
    //         "oferta": "[\"Fixed\",3.14,\"2023-12-31\"]",
    //         "precio": 3.24,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "chocolate-nuzart-350g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MWg55zLAcr5FmUeydTr": {
    //         "categoria": "articulos-de-belleza",
    //         "descripcion": "Toallas Desmaquillantes Mimlot 25 unidades&nbsp;",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-03-26T01:13:48.970Z",
    //         "galeria": "[\"1342521616721233.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "76594411616721219.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Toallas Desmaquillantes Mimlot 25 Unidades",
    //         "oferta": "",
    //         "precio": 1.21,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 25,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "toallas-desmaquillantes-mimlot-25-unidades",
    //         "video": "[]",
    //         "views": 1
    //     },
    //     "-MSmwe8xQa_mfZmcS2ha": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "descripcion": "Pepino por kg",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"1kg\":1.8}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-02-16T09:42:18.255Z",
    //         "galeria": "[\"96969751612541401.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "14442151612541398.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Pepino",
    //         "oferta": "[\"Disccount\",10,\"2025-02-28\"]",
    //         "precio": 1.8,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pepino",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NNDEmGpAMv5NJW7HBeK": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-12-03T20:29:44.340Z",
    //         "galeria": "[\"52191581675276264.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "7663661675276261.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Chocolate Milkyway ",
    //         "oferta": "",
    //         "precio": 1.92,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 5,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "chocolate-milkyway-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mbn5kQQuivj8vuiJu_g": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-06-09T23:28:20.222Z",
    //         "galeria": "[\"78894111623281300.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "9624521623281299.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Desodorante Old Spice rolon",
    //         "oferta": "",
    //         "precio": 2.5,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "desodorante-old-spice-rolon",
    //         "video": "[]",
    //         "views": 6
    //     },
    //     "-Mr2oDNx9X3xZG4UXVTQ": {
    //         "categoria": "bebidas-y-lacteos",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-09-29T09:55:20.350Z",
    //         "galeria": "[\"69226411639667786.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "25075921639667784.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Six Pack De Pepsi 2lt",
    //         "oferta": "[\"Fixed\",10,\"2025-12-31\"]",
    //         "precio": 10.7,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "six-pack-de-pepsi-2lt",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPthj72wblEjUZrV_us": {
    //         "categoria": "bebidas-y-lacteos",
    //         "costo": "",
    //         "descripcion": "<ul><li><strong>Maltín polar 1.5L</strong></li></ul>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2020-12-31T16:52:19.970Z",
    //         "galeria": "[\"88604001614606977.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "70403051614606974.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Maltin Polar",
    //         "oferta": "",
    //         "precio": 2.13,
    //         "prox": false,
    //         "sales": 2,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "maltin-polar",
    //         "video": "[]",
    //         "views": 12
    //     },
    //     "-NFQFPZ9yU0TMBw6WLq4": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-10-27T21:03:17.626Z",
    //         "galeria": "[\"42757541667754153.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "33564181667754152.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Ajo Iiberia 300g",
    //         "oferta": "",
    //         "precio": 2.64,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "ajo-iiberia-300g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPtQWObVFnHwM7jq-ek": {
    //         "categoria": "pollo-criollo",
    //         "costo": "",
    //         "descripcion": "<br>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Pollo entero\":[{\"Entero \":10.2399,\"Despresado con piel\":10.2399,\"Despresado sin piel \":10.2399}]}]",
    //         "estado": true,
    //         "fecha_create": "2022-09-30T08:13:47.354Z",
    //         "galeria": "[\"53709631653919227.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "81368881653919226.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Pollo Entero Oferta 2.2kg",
    //         "oferta": "[\"Fixed\",9.57,\"2025-12-31\"]",
    //         "precio": 10.2399,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pollo-entero-oferta-2-2kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OKDTC_dVSv9qOj_V9AF": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-28T21:31:33.903Z",
    //         "galeria": "[\"32681031740778295.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "44066141740778294.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Colgate Triple Acción 50ml + Cepillo",
    //         "oferta": "[\"Fixed\",2.45,\"2025-12-31\"]",
    //         "precio": 2.6215,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "colgate-triple-accion-50ml---cepillo",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MUjKSQwZkowZI_qqIcK": {
    //         "categoria": "licores",
    //         "costo": "",
    //         "descripcion": "<p><b>Caja de cerveza polar negra</b></p><ul><li><b>36 unidades</b></li></ul>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Sabor \":[\"Negra\",\"Light\",\"Solera azul\"]}]",
    //         "estado": true,
    //         "fecha_create": "2025-02-09T09:20:27.640Z",
    //         "galeria": "[\"47535341614628114.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "12442971614628110.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Caja De Cerveza Polar retornable ",
    //         "oferta": "[\"Fixed\",20,\"2025-12-31\"]",
    //         "precio": 21.4,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "caja-de-cerveza-polar-retornable-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OGf04xflhiqNuaX-fdF": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-01-15T17:30:28.875Z",
    //         "galeria": "[\"858421736962235.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "39008571736962228.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Galleta Saltisima Semidulces 3 Tacos",
    //         "oferta": "[\"Fixed\",2.16,\"2025-12-31\"]",
    //         "precio": 2.3112,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "galleta-saltisima-semidulces-3-tacos",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OJ4Cw0fWe-vcT5Adlht": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-14T16:08:09.482Z",
    //         "galeria": "[\"88922791739549294.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "1617081739549290.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Pañal Baby Finger Talla G X18",
    //         "oferta": "",
    //         "precio": 5,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 5,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "panal-baby-finger-talla-g-x18",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPt5Br7O1Z3CLLyuCI6": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "<b>Masa Fácil</b>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2020-12-31T13:59:36.737Z",
    //         "galeria": "[\"5761671609423178.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "24213981609423176.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Masa Fácil",
    //         "oferta": "[\"Fixed\",0.91,\"2025-12-31\"]",
    //         "precio": 0.9737,
    //         "prox": false,
    //         "sales": 2,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "masa-facil",
    //         "video": "[]",
    //         "views": 33
    //     },
    //     "-NIwLJcUs8z5oRpEAvur": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-12-10T14:03:38.364Z",
    //         "galeria": "[\"97772861670681020.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "2366821670681019.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Vinagre Mavesa 500ml",
    //         "oferta": "",
    //         "precio": 1.16,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "vinagre-mavesa-500ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O9_A0flYpuAfiHtMALr": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-19T14:26:14.725Z",
    //         "galeria": "[\"45903011729347983.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "3481821729347974.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Salsa  Tomate Heinz 567",
    //         "oferta": "",
    //         "precio": 2.96,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salsa--tomate-heinz-567",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MqydUPICwu9nIL-jL0y": {
    //         "categoria": "mascotas",
    //         "descripcion": "Perrarina Nutrecan 2kg",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2022-04-03T14:19:39.828Z",
    //         "galeria": "[\"264561639581086.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "43918291639581085.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Perrarina Nutrecan 2kg",
    //         "oferta": "",
    //         "precio": 3.66,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "perrarina-nutrecan-2kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NAjN8VN8V-2RTiz454t": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso \":[{\"250gr rebanado \":2.14,\"500gr rebanado\":4.28,\"250gr Entero\":2.14,\"500gr Entero \":4.28}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-01-13T15:16:25.055Z",
    //         "galeria": "[\"19676371661873460.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "49759951661873459.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Queso Mozzarella",
    //         "oferta": "[\"Fixed\",2,\"2025-12-31\"]",
    //         "precio": 2.14,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "queso-mozzarella",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MXsI_V_Fk50aXjkWzPi": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "Compotas Heinz sabores surtidos de 113gr sobres manzana peras melocoton",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-04-09T20:24:02.702Z",
    //         "galeria": "[\"59028151617999844.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "37078931617999843.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Compotas Heinz de Sobre",
    //         "oferta": "",
    //         "precio": 1.34,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "compotas-heinz-de-sobre",
    //         "video": "[]",
    //         "views": 17
    //     },
    //     "-MUi29CODqxbU3YyiQvb": {
    //         "categoria": "bebidas-y-lacteos",
    //         "costo": "",
    //         "descripcion": "<p><b>Bebida energética&nbsp;</b></p><ul><li><b>Gatorade</b></li></ul>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2021-12-12T13:02:16.958Z",
    //         "galeria": "[\"6536801614606538.jpg\",\"44193431639314229.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "91939141614606537.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Gatorade 500ml",
    //         "oferta": "[\"Fixed\",1.79,\"2023-12-31\"]",
    //         "precio": 1.89,
    //         "prox": false,
    //         "sales": 3,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "gatorade-500ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mpfuyorz3HNIeaRo9Ua": {
    //         "categoria": "accesorios-de-cumpleanos-",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-11-29T13:39:22.148Z",
    //         "galeria": "[\"39205301638193162.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "77751851638193162.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Vaso Nro 7 x50",
    //         "oferta": "",
    //         "precio": 1.3,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "vaso-nro-7-x50",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NwQwLxs0GgOk868cP-i": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-04-26T19:45:51.800Z",
    //         "galeria": "[\"76485541714160752.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "49599241714160752.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Protectores Diarios Olwys X50 largos",
    //         "oferta": "",
    //         "precio": 3.82,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "protectores-diarios-olwys-x50-largos",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MvKuJ__7eFmZzA_KJNO": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-07-19T18:40:59.045Z",
    //         "galeria": "[\"21682961644266341.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "7323931644266335.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Bocadillo Veleño De Guayava 300g",
    //         "oferta": "[\"Fixed\",0.85,\"2025-12-31\"]",
    //         "precio": 0.9095,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "bocadillo-veleno-de-guayava-300g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O7U3LJZmuTMS93OAc1V": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-09-23T13:28:05.478Z",
    //         "galeria": "[\"18802811727098086.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "70434111727098085.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Champoo Pantene Surtido 200ml",
    //         "oferta": "",
    //         "precio": 4.11,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "champoo-pantene-surtido-200ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NEM8f5mkGpWUzfN2aGf": {
    //         "categoria": "bebidas-y-lacteos",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Sabor \":[\"Descremada\",\"Deslactosada\",\"Completa\"]}]",
    //         "estado": true,
    //         "fecha_create": "2023-08-23T15:22:53.810Z",
    //         "galeria": "[\"23678611665839916.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "44322261665839915.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Leche La Purisima 1lt",
    //         "oferta": "[\"Fixed\",2.22,\"2025-12-31\"]",
    //         "precio": 2.3754,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "leche-la-purisima-1lt",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O7xCBCk_fc7MP3BQ0Qo": {
    //         "categoria": "bebidas-y-lacteos",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-09-29T09:55:20.350Z",
    //         "galeria": "[\"86811461727603720.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "84663441727603720.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Caja Coca Cola 2litros X 6 Unidades ",
    //         "oferta": "[\"Fixed\",10,\"2025-12-31\"]",
    //         "precio": 10.7,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "caja-coca-cola-2litros-x-6-unidades-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NYN3oaouTOzRRIMqqML": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-06-20T09:11:56.772Z",
    //         "galeria": "[\"91198061687252322.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "4712341687252316.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Cafe Gurmet Amanecer 500g",
    //         "oferta": "",
    //         "precio": 4.89,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cafe-gurmet-amanecer-500g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O6CipOo98Pl7MVrO74I": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-09-07T18:24:46.781Z",
    //         "galeria": "[\"32254281725733491.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "65544391725733486.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Sal Esmeralda 1kg",
    //         "oferta": "",
    //         "precio": 0.59,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 25,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "sal-esmeralda-1kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPtRyNVfaXwau_Ci-qJ": {
    //         "categoria": "pollo-criollo",
    //         "costo": "",
    //         "descripcion": "<b>Milanesa de Pollo</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"500gr\":4.5475,\"1kg\":9.095}]}]",
    //         "estado": true,
    //         "fecha_create": "2022-05-10T23:01:36.149Z",
    //         "galeria": "[\"15587451609429148.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "2632381609429147.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Milanesa de Pollo",
    //         "oferta": "[\"Fixed\",4.25,\"2025-12-31\"]",
    //         "precio": 4.5475,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "milanesa-de-pollo",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQKFJvP-dIYJ-c0zNj8": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Mayonesa mavesa 1kg</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-06T01:13:12.957Z",
    //         "galeria": "[\"16015641609895595.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "73182651609895592.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Mayonesa Mavesa 910g",
    //         "oferta": "",
    //         "precio": 7,
    //         "prox": false,
    //         "sales": 6,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "mayonesa-mavesa-910g",
    //         "video": "[]",
    //         "views": 18
    //     },
    //     "-MbBwuUI3vHE0BLE-HTY": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-01-16T00:33:01.251Z",
    //         "galeria": "[\"9381201622641190.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "54576201622641189.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Salsa De Tomate Zev 500g",
    //         "oferta": "[\"Fixed\",2.02,\"2025-12-31\"]",
    //         "precio": 2.1614,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salsa-de-tomate-zev-500g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NH07fQzk7hvclrfTwqS": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-06-30T18:39:09.762Z",
    //         "galeria": "[\"60467051668613844.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "76779531668613831.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Mostaza Zev 200g",
    //         "oferta": "[\"Fixed\",0.9,\"2025-01-31\"]",
    //         "precio": 0.963,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "mostaza-zev-200g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NP-XCe7XUM8q8JWZLf2": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-02-23T23:08:15.606Z",
    //         "galeria": "[\"28807371677193695.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "80351671677193694.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "La campesina nutririnde 400g",
    //         "oferta": "",
    //         "precio": 5.72,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "la-campesina-nutririnde-400g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MY8QZwrIL1s0LjvMJTU": {
    //         "categoria": "confiteria-y-galletas",
    //         "descripcion": "<font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">PAG Galleta noel Tipo leche 321gr</font></font>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-04-23T17:21:03.341Z",
    //         "galeria": "[\"55776891618287151.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "29548051618287143.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Paquete De Galleta Noel Tipo Leche 321gr",
    //         "oferta": "",
    //         "precio": 2.04,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "paquete-de-galleta-noel-tipo-leche-321gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mhczd0XQ18P7pX--WYM": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "Salsas Zev Surtidas 200g",
    //         "detalles": "",
    //         "especificaciones": "[{\"Salsas Zev Surtidas 200g\":[\"Salsa de piña\",\"Maíz Dulce\",\"Mostaza\"]}]",
    //         "estado": true,
    //         "fecha_create": "2025-01-16T00:33:01.251Z",
    //         "galeria": "[\"52495001629554116.jpg\",\"27574641629554116.jpg\",\"82811721629554116.jpg\",\"82738601629554117.jpg\",\"85681891633457580.jpg\",\"5664881633457580.jpg\",\"50128041668614046.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "49587741629554113.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Salsas Zev Surtidas 200g",
    //         "oferta": "[\"Fixed\",1.48,\"2025-12-31\"]",
    //         "precio": 1.5836,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salsas-zev-surtidas-200g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MR_OS82o-ss_BxLZzWK": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Por kg\":[{\"1 kg\":1.8,\"1.5 kg\":2.7,\"500gr\":0.9}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-02-16T09:42:18.255Z",
    //         "galeria": "[\"63814901611240165.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "78325301611240164.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Cebolla",
    //         "oferta": "[\"Disccount\",15,\"2025-02-28\"]",
    //         "precio": 1.8,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cebolla",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MR_WJ2LpgwMs7cFYuyL": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "descripcion": "<b>Tamarindo por unidad</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Unidad\":[{\"1 unidad \":1.3,\"2 unidades \":2.6}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-02-16T09:42:18.255Z",
    //         "galeria": "[\"55151641611242225.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "80048351611242222.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Tamarindo",
    //         "oferta": "[\"Disccount\",10,\"2025-02-28\"]",
    //         "precio": 1.3,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mhcrmf7Dd7aHFKKZ3gQ": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "Cereal Kelloggs Zucaritas 200g",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T18:41:24.724Z",
    //         "galeria": "[\"80942591629552060.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "47025321629552057.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Cereal Kelloggs Zucaritas 180g",
    //         "oferta": "[\"Fixed\",2.9,\"2025-12-31\"]",
    //         "precio": 3.103,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cereal-kelloggs-zucaritas-180g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MlGPxre52JrByD_76Qo": {
    //         "categoria": "mascotas",
    //         "costo": "",
    //         "descripcion": "Alimento Para Gatos Whiskas Surtido 85g",
    //         "detalles": "",
    //         "especificaciones": "[{\"Sabor\":[\"Carne\",\"Pollo\",\"Salmon\"]}]",
    //         "estado": false,
    //         "fecha_create": "2023-06-30T18:39:09.762Z",
    //         "galeria": "[\"63435531633453592.jpg\",\"73312041633453593.jpg\",\"3141691633453594.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "53569101633453585.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Alimento Para Gatos Whiskas 85g",
    //         "oferta": "[\"Fixed\",1.11,\"2024-01-31\"]",
    //         "precio": 1.2,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "alimento-para-gatos-whiskas-85g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NsnHc4ZLiJG1lKIBB9C": {
    //         "categoria": "bebidas-y-lacteos",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-03-12T15:31:43.346Z",
    //         "galeria": "[\"6819321710257504.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "13542811710257503.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Postobón 2.5 Ltrs",
    //         "oferta": "[\"Fixed\",1.89,\"2025-12-31\"]",
    //         "precio": 2.0223,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "postobon-2-5-ltrs",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O6A41MIjBDyTfOqBYxt": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-09-07T06:02:59.934Z",
    //         "galeria": "[\"51425241725688980.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "59105011725688979.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Pinza Para Ropa X 50 Unid",
    //         "oferta": "",
    //         "precio": 2.12,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 30,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pinza-para-ropa-x-50-unid",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NNBQ8ylgK0w5EuRQktQ": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-07-22T15:02:57.454Z",
    //         "galeria": "[\"51759961675245690.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "6779581675245684.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Arroz La Lucha 1kg",
    //         "oferta": "[\"Fixed\",1.39,\"2025-12-31\"]",
    //         "precio": 1.4873,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "arroz-la-lucha-1kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NBU4itjFPSc8avpQtkd": {
    //         "categoria": "accesorios-de-cumpleanos-",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Plastico\":[{\"Cuchara\":0.57,\"Tenedor\":0.57}]}]",
    //         "estado": true,
    //         "fecha_create": "2022-09-08T21:51:10.384Z",
    //         "galeria": "[\"51677121668699227.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "91022171668699207.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Cucharilla y temor plástico x20",
    //         "oferta": "",
    //         "precio": 0.57,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cucharilla-y-temor-plastico-x20",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MgRCYSBsC7V_Pm27At-": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "Leche En Polvo Entera Latti 350g",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-01-16T00:33:01.251Z",
    //         "galeria": "[\"26027921628267157.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "10574511628267147.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Leche En Polvo Entera Latti 1kg",
    //         "oferta": "[\"Fixed\",8.22,\"2025-12-31\"]",
    //         "precio": 8.7954,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "leche-en-polvo-entera-latti-1kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPtOuXdZFoVJDUzEcEl": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "descripcion": "<b>Asado de Tira</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"500gr\":2.14,\"1kg\":4.28}]}]",
    //         "estado": true,
    //         "fecha_create": "2020-12-31T15:25:40.284Z",
    //         "galeria": "[\"6877481663290350.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "41911901663290349.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Asado de Tira",
    //         "oferta": "[\"Fixed\",2,\"2025-12-31\"]",
    //         "precio": 2.14,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "asado-de-tira",
    //         "video": "[]",
    //         "views": 29
    //     },
    //     "-NcmLoiK8pVxKuGAEaNM": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-08-26T14:59:11.692Z",
    //         "galeria": "[\"508331693061958.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "56422151693061951.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Toalla Alwys Ultra Ffina X8 Unidades",
    //         "oferta": "",
    //         "precio": 2.5,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "toalla-alwys-ultra-ffina-x8-unidades",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPzbD1jNgg5_7jg3LK-": {
    //         "categoria": "huevos",
    //         "costo": "",
    //         "descripcion": "<b>1/2 Cartón de Huevos x15</b>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-01-21T04:54:05.493Z",
    //         "galeria": "[\"61485981609532497.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "31263941609532494.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Medio Cartón de Huevos",
    //         "oferta": "[\"Fixed\",3.25,\"2025-12-31\"]",
    //         "precio": 3.4775,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "medio-carton-de-huevos",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MV6eIPpggfk0_r-9TU9": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "<b>Esponja multiuso izy clean&nbsp;</b>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-03-06T13:10:31.809Z",
    //         "galeria": "[\"17955501615036232.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "98848781615036231.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Esponja multiuso izy clean ",
    //         "oferta": "",
    //         "precio": 1.35,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "esponja-multiuso-izy-clean-",
    //         "video": "[]",
    //         "views": 9
    //     },
    //     "-NNDDVwWhUQg9bKoGiNA": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-02-01T18:25:26.661Z",
    //         "galeria": "[\"51064621675275930.jpg\",\"78587811675275930.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "86001721675275927.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Chocolate M&m 49.3g",
    //         "oferta": "",
    //         "precio": 1.77,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 63,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "chocolate-mym-49-3g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O82JfvqClTEgKgH_jK1": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-09-30T14:25:46.850Z",
    //         "galeria": "[\"80673321727706348.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "8876661727706347.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Tripack La Viña De 300ml",
    //         "oferta": "",
    //         "precio": 4.15,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "tripack-la-vina-de-300ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MY7whUn_lfVNsv1pq6I": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "Mayonesa zev 500g",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-01-16T00:33:01.251Z",
    //         "galeria": "[\"11991841618279060.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "6389561618279052.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Mayonesa Zev 500g",
    //         "oferta": "[\"Fixed\",1.7,\"2025-12-31\"]",
    //         "precio": 1.819,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "mayonesa-zev-500g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPtKPBuu-cIwYgTa9xN": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "descripcion": "<b>Carne Sobrebarriga</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"500gr\":3.852,\"1kg\":7.704}]}]",
    //         "estado": true,
    //         "fecha_create": "2023-05-23T20:30:40.668Z",
    //         "galeria": "[\"58839451609427163.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "57546351609427151.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Carne Sobrebarriga",
    //         "oferta": "[\"Fixed\",3.6,\"2025-12-31\"]",
    //         "precio": 3.852,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "carne-sobrebarriga",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NfazSShiSLjMgU86Uvv": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-09-30T16:48:28.641Z",
    //         "galeria": "[\"61734011696092509.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "83334561696092507.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Leche Campestre 400g",
    //         "oferta": "",
    //         "precio": 5.56,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "leche-campestre-400g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NoC50a3g7ShRK9PxEKa": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"1kg \":1.8}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-02-16T09:42:18.255Z",
    //         "galeria": "[\"26543591705321698.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "89242371705321695.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Apio ",
    //         "oferta": "[\"Disccount\",10,\"2025-02-28\"]",
    //         "precio": 1.8,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "apio-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPtkDaUPQxl5y-OMw-G": {
    //         "categoria": "bebidas-y-lacteos",
    //         "costo": "",
    //         "descripcion": "<b>Pepsi 2L</b>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-12-04T15:13:21.902Z",
    //         "galeria": "[\"80252511609434195.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "17175691609434192.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Pepsi 2L",
    //         "oferta": "[\"Fixed\",1.76,\"2025-12-31\"]",
    //         "precio": 1.8832,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pepsi-2l",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NqlHEA3Gh60q9sAHwgu": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-02-16T09:42:18.255Z",
    //         "galeria": "[\"9934501708077148.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "12799241708077147.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Alcelgas",
    //         "oferta": "[\"Disccount\",10,\"2025-02-28\"]",
    //         "precio": 0.9,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "alcelgas",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NRJinSt1k6EV2I5n2sT": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-03-24T17:47:04.137Z",
    //         "galeria": "[\"48653981679680025.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "94445441679680024.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Cepillo Palma real",
    //         "oferta": "",
    //         "precio": 3.53,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NZ7lrDwKZwpl34vLn2i": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-06-29T20:10:15.837Z",
    //         "galeria": "[\"38074241688069430.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "10379231688069415.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Maizina americana 200g",
    //         "oferta": "",
    //         "precio": 1.76,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 20,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "maizina-americana-200g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NIEmubH_SMiOvMDp2Zu": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-12-01T22:25:39.949Z",
    //         "galeria": "[\"4556291669933541.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "80522271669933540.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Aceite Ideal Soya 500ml",
    //         "oferta": "",
    //         "precio": 1.54,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "aceite-ideal-soya-500ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPtRiCy5NQFegCmkwlI": {
    //         "categoria": "pollo-criollo",
    //         "costo": "",
    //         "descripcion": "<b>Mollejas de Pollo</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"1kg\":3.852,\"500g\":1.926}]}]",
    //         "estado": true,
    //         "fecha_create": "2023-05-18T11:43:34.593Z",
    //         "galeria": "[\"64421751609429082.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "52378301609429079.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Mollejas de Pollo",
    //         "oferta": "[\"Fixed\",3.6,\"2025-12-31\"]",
    //         "precio": 3.852,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "mollejas-de-pollo",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NFy1QyaW8xdmDBByGRT": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Cantidad \":[{\"250gr\":2.981,\"300gr\":3.5772,\"500gr\":5.962}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-07-09T14:00:25.460Z",
    //         "galeria": "[\"69599051714963463.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "28411111714963460.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Jamón De Pierna ",
    //         "oferta": "[\"Fixed\",2.71,\"2025-12-31\"]",
    //         "precio": 2.981,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jamon-de-pierna-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NOM-hw2liVfNaTi86iR": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T18:53:01.879Z",
    //         "galeria": "[\"82573771676497050.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "13318591676497049.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Cereal Froot Loops 135gr",
    //         "oferta": "[\"Fixed\",2.67,\"2025-12-31\"]",
    //         "precio": 2.8569,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cereal-froot-loops-135gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O7Atg1XRy3YnCn4VSGo": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-09-19T20:08:41.646Z",
    //         "galeria": "[\"3668511726776524.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "9204941726776521.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Biachi En Barbarra Surtido 30g",
    //         "oferta": "",
    //         "precio": 0.38,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "biachi-en-barbarra-surtido-30g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NTJmzFNsweBBTgsliEm": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-04-18T14:36:45.399Z",
    //         "galeria": "[\"47414931681828606.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "677131681828604.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Adobo Completo Iberia 185 gr",
    //         "oferta": "",
    //         "precio": 2.06,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "adobo-completo-iberia-185-gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Niq2VFUCZZxxcRr82Gl": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-11-09T21:47:32.158Z",
    //         "galeria": "[\"19388431699566453.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "74879711699566451.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Rodajas Piña Tigo 830g",
    //         "oferta": "",
    //         "precio": 4.94,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "rodajas-pina-tigo-830g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N3aWmTKpYRP5Qpi9EIM": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "Galleta Maria Puig 250g 9 galletas",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-03-02T13:19:23.910Z",
    //         "galeria": "[\"48381311654208799.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "82192871654208798.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Galleta Maria Puig 250g",
    //         "oferta": "",
    //         "precio": 2.05,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "galleta-maria-puig-250g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQJtg5XUy4H9LR4PA9N": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Diablitos Under Wood 54g</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-08-03T19:15:02.594Z",
    //         "galeria": "[\"2017131609889660.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "61556521609889659.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Diablitos Under Wood 54g",
    //         "oferta": "",
    //         "precio": 1.66,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 50,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "diablitos-under-wood-54g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N0MRF8aEcnNA3DjIG26": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Galleta \":[{\"Fresa\":2.81,\"Chocolate \":2.81,\"Vainilla\":2.81}]}]",
    //         "estado": true,
    //         "fecha_create": "2022-11-12T19:08:51.201Z",
    //         "galeria": "[\"45776281650734464.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "69498341650734462.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Galleta Brinky Surtida 510gr",
    //         "oferta": "[\"Fixed\",2.45,\"2023-12-31\"]",
    //         "precio": 2.81,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "galleta-brinky-surtida-510gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OJ4E0su0W-H7Ke4Sty8": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-14T16:12:58.421Z",
    //         "galeria": "[\"8503271739549580.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "1824401739549579.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Pañal Baby Finger Talla M X 18",
    //         "oferta": "",
    //         "precio": 4.63,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 5,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "panal-baby-finger-talla-m-x-18",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N7MGJneY0Y8w2ToXQfX": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-06-30T18:39:09.762Z",
    //         "galeria": "[\"43186381658247793.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "55651841658247788.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Bocadillo Veleño De Guayava De Hoja 470g",
    //         "oferta": "[\"Fixed\",1.85,\"2025-12-31\"]",
    //         "precio": 1.9795,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "bocadillo-veleno-de-guayava-de-hoja-470g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mhp7ZpDhk8xQnHKbQBV": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2022-06-10T09:24:50.445Z",
    //         "galeria": "[\"41865831629757787.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "96754271629757786.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Café Aroma 500gr",
    //         "oferta": "[\"Fixed\",6.06,\"2025-12-31\"]",
    //         "precio": 6.4842,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cafe-aroma-500gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MVl0NquD96iyDsUzhwc": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "Detergente Líquido Bonaropa&nbsp;",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-02-26T08:38:24.457Z",
    //         "galeria": "[\"56658871632060882.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "5909341675009687.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Detergente Líquido Bonaropa 1lt",
    //         "oferta": "[\"Fixed\",2,\"2023-12-31\"]",
    //         "precio": 2.1,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "detergente-liquido-bonaropa-1lt",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQ2xwknFYa9zoS4cpKf": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-aue-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Jabón en polvo Las Llaves 1kg</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-02T16:39:23.693Z",
    //         "galeria": "[\"30661251609605565.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "27905301609605562.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabón En Polvo Las Llaves 900gr",
    //         "oferta": "",
    //         "precio": 3.61,
    //         "prox": false,
    //         "sales": 1,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-en-polvo-las-llaves-900gr",
    //         "video": "[]",
    //         "views": 4
    //     },
    //     "-OIazyC_wog3bQ7b-po_": {
    //         "categoria": "confiteria-y-galletas",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T19:18:29.941Z",
    //         "galeria": "[\"48764281739042316.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "38779311739042309.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Galletas Happy Wafer 288g",
    //         "oferta": "[\"Fixed\",1.83,\"2025-12-31\"]",
    //         "precio": 1.9581,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "galletas-happy-wafer-288g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OAboM_PbQaSJloWSlj8": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-01T13:01:48.329Z",
    //         "galeria": "[\"92083291730466117.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "77105391730466107.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Fideo Capri De 250g",
    //         "oferta": "",
    //         "precio": 1.83,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "fideo-capri-de-250g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPjsyma5db_-UCuOxwf": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "<ol><li><b>1Kg Bistec</b></li><li><b>1Kg Mechar o Guisar</b></li><li><b>1Kg Molida</b></li><li><b>1Kg Costilla de Res</b></li><li><b>1kg Muslos de pollo</b></li><li><b>1Kg Picadillo de pollo</b></li><li><b>1Kg Pechuga de Pollo</b></li><li><b>1Kg Chuleta o Costilla de Cerdo</b></li><li><b>1 Catón de huevos</b></li></ol>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-02-04T13:44:49.683Z",
    //         "galeria": "[\"60248801720176598.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "32964581720176597.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo carne pollo Cerdo Y Huevos",
    //         "oferta": "[\"Fixed\",48,\"2025-12-31\"]",
    //         "precio": 51.36,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-carne-pollo-cerdo-y-huevos",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OAhiyzZu7OkQP7Wm7y9": {
    //         "categoria": "articulos-de-belleza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-02T16:35:27.680Z",
    //         "galeria": "[\"15181601730565368.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "77591921730565327.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Crema Peinar Sedal 300ml",
    //         "oferta": "",
    //         "precio": 4.11,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "crema-peinar-sedal-300ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Njajq56KKKhKefHrH75": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-11-19T08:42:59.631Z",
    //         "galeria": "[\"52804971700383383.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "94148221700383379.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Pirulin Estuche 60g",
    //         "oferta": "",
    //         "precio": 2.15,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pirulin-estuche-60g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N48Xr_RgDMyQG19vyOX": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-06-09T17:38:03.532Z",
    //         "galeria": "[\"82791191654796285.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "53673661654796283.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Papelera",
    //         "oferta": "",
    //         "precio": 3.55,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "papelera",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MlGS9kPsyU_jPsEVILg": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "Endulzante Con Base En Sucralosa Dulcralight 180g",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-03-25T16:28:35.038Z",
    //         "galeria": "[\"68182291633454172.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "8946661633454165.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Endulzante Con Base En Sucralosa dulcralight 180g",
    //         "oferta": "[\"Fixed\",1.4,\"2023-12-31\"]",
    //         "precio": 1.5,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 3,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "endulzante-con-base-en-sucralosa-dulcralight-180g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N1h7HWWeYxeqR90yKxU": {
    //         "categoria": "pollo-criollo",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Strogonoff\":[{\"500gr\":4.5475,\"1kg \":9.095}]}]",
    //         "estado": true,
    //         "fecha_create": "2022-05-10T23:01:36.149Z",
    //         "galeria": "[\"31526441652172072.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "44432661652172071.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Filet Pechuga En Strogonoff ",
    //         "oferta": "[\"Fixed\",4.25,\"2025-12-31\"]",
    //         "precio": 4.5475,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "filet-pechuga-en-strogonoff-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OIbCtOrc534WgrfZUSn": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T20:18:54.693Z",
    //         "galeria": "[\"76856571739045966.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "67134151739045934.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabón En Polvo Bonaropa Extra Tech 1.100kg",
    //         "oferta": "[\"Fixed\",2.93,\"2025-12-31\"]",
    //         "precio": 3.1351,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-en-polvo-bonaropa-extra-tech-1-100kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQ9DvAG0gVW3NRYg3Su": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-yha-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Gel De Baño Natural Feeling 750ml</h5>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Aroma \":[\"Botanic\"]}]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T16:35:50.256Z",
    //         "galeria": "[\"74193111625157072.png\",\"49771061625157073.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "1921971625157063.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Gel De Baño Natural Feeling 750ml",
    //         "oferta": "[\"Fixed\",3.14,\"2025-12-31\"]",
    //         "precio": 3.3598,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "gel-de-bano-natural-feeling-750ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MkIJOraCqrIir9UeIm9": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "Shampoo Pantene Liso Extremo 400ml",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-09-23T13:28:05.478Z",
    //         "galeria": "[\"45146591632411685.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "4622311632411674.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Shampoo pantene liso extremo 400ml",
    //         "oferta": "",
    //         "precio": 6.65,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "shampoo-pantene-liso-extremo-400ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MSmv4gxw1dHxjSDlXZJ": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "descripcion": "<b>Manzana por unidad</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Unidad\":[{\"1 unidad \":1.2,\"2 unidades \":2.4,\"3 unidades \":3.6}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-02-16T09:42:18.255Z",
    //         "galeria": "[\"93924971612540827.jpg\",\"51418811622740292.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "70559951612540826.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Manzana ",
    //         "oferta": "[\"Disccount\",10,\"2025-02-28\"]",
    //         "precio": 1.2,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "manzana-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MY8O4ETxx1wuvvzGSIs": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "<font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">Galleta Nucita 160g</font></font>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T16:09:15.635Z",
    //         "galeria": "[\"1963611618286496.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "22128891618286489.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Galleta Nucita 160g",
    //         "oferta": "[\"Fixed\",1.73,\"2025-12-31\"]",
    //         "precio": 1.8511,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "galleta-nucita-160g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NNpPTwshpMm0OvsAgqL": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-02-09T09:02:57.542Z",
    //         "galeria": "[\"76564601675933380.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "32081911675933379.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Bianchi Bolsa Surtidos x6 unidades ",
    //         "oferta": "[\"Fixed\",4.3,\"2024-12-31\"]",
    //         "precio": 4.601,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "bianchi-bolsa-surtidos-x6-unidades-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPzP1uTgoe3P2eps0fs": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-mqj-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Galleta Soda sol 240gr</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-08-30T20:03:35.497Z",
    //         "galeria": "[\"54205391609529043.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "59478831609529041.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Galleta Soda sol 240gr",
    //         "oferta": "",
    //         "precio": 1.83,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "galleta-soda-sol-240gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQK53FWHZgLzhcvHfYU": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Leche Condensada Natulac 397g</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-29T08:47:52.248Z",
    //         "galeria": "[\"13601041609892905.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "41769921609892903.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Leche Condensada Natulac 397g",
    //         "oferta": "",
    //         "precio": 2.65,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 9,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "leche-condensada-natulac-397g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Nkkhtk9q6vTiTeMtNAD": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-09-23T13:28:05.478Z",
    //         "galeria": "[\"52006711701624379.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "8427791701624377.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Pantene Resrestauración 400ml",
    //         "oferta": "",
    //         "precio": 6.65,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pantene-resrestauracion-400ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NvmtxltruKMxkIeQSU8": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-04-18T20:30:41.004Z",
    //         "galeria": "[\"69801101713472263.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "56852091713472240.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Papel Rosal Vino Tinto X4 De 300hojas",
    //         "oferta": "",
    //         "precio": 1.79,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "papel-rosal-vino-tinto-x4-de-300hojas",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NNvUhGMdBShJzXY3Co_": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-07-31T20:45:36.618Z",
    //         "galeria": "[\"1314121676035411.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "9933551676035400.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Aceite Puro Gold Soya 900ml",
    //         "oferta": "[\"Fixed\",2.7,\"2023-12-31\"]",
    //         "precio": 2.88,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "aceite-puro-gold-soya-900ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OCJFUxkUgLBnsATu55G": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-22T14:23:48.923Z",
    //         "galeria": "[\"37419881732285430.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "7745111732285429.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Haragán Palma real",
    //         "oferta": "",
    //         "precio": 3.09,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "haragan-palma-real",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NwUwbPWHSSzhgtrbgHs": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-04-27T14:25:25.006Z",
    //         "galeria": "[\"84786671714227933.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "53702221714227924.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Cloro Kikex 1 Llitro",
    //         "oferta": "",
    //         "precio": 1.29,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cloro-kikex-1-llitro",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPjR5_18E8sOxv_jxCa": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "<ol><li><b>1Kg Bistec</b></li><li><b>1Kg Mechar</b></li><li><b>1Kg Guisar</b></li><li><b>1Kg Molida</b></li><li><b>1Kg Costilla de res</b></li><li><b>1Kg Pechuga o muslo</b></li></ol>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Preparación \":[\"*con pechuga entera sin arreglar**\",\"*con muslo entero sin arreglar*\",\"*con pechuga entera arreglada en filet*\",\"Con muslo entero arreglado sin piel*\"]}]",
    //         "estado": true,
    //         "fecha_create": "2020-12-29T17:32:13.464Z",
    //         "galeria": "[\"85001121720175160.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "12057031720175159.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo Familiar 6kg",
    //         "oferta": "[\"Fixed\",35,\"2025-12-31\"]",
    //         "precio": 37.45,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-familiar-6kg",
    //         "video": "[]",
    //         "views": 117
    //     },
    //     "-OD25FEqMD1XgkWUHF95": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-12-01T16:41:13.864Z",
    //         "galeria": "[\"55159771733071274.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "99497581733071273.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Kerosene 1 litro",
    //         "oferta": "",
    //         "precio": 6.07,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 3,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "kerosene-1-litro",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NpQS2UfpeH3b-NCqmDP": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-01-30T17:39:17.535Z",
    //         "galeria": "[\"52245581706636359.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "14291471706636357.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Palitos De Cchocolate",
    //         "oferta": "",
    //         "precio": 0.5,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "palitos-de-cchocolate",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MY7w8LHeXr4ugLLmCZv": {
    //         "categoria": "viveres",
    //         "descripcion": "Mostaza bassi 180gr",
    //         "detalles": "",
    //         "especificaciones": "[{\"Bassi\":[\"Mayomostaza\"]}]",
    //         "estado": true,
    //         "fecha_create": "2022-12-01T22:04:19.235Z",
    //         "galeria": "[\"96569361618278911.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "80925761618278901.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Mostaza Bassi 180g",
    //         "oferta": "",
    //         "precio": 0.55,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "mostaza-bassi-180g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O12PunzJAse-BRWe0ns": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-07-05T15:03:08.153Z",
    //         "galeria": "[\"34046221720191789.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "67725881720191788.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Salsa De Ostra China 260g",
    //         "oferta": "",
    //         "precio": 4.94,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salsa-de-ostra-china-260g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NQjakRXpSuPzLuCKPDK": {
    //         "categoria": "confiteria-y-galletas",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-03-17T12:45:49.553Z",
    //         "galeria": "[\"6462911679057159.jpg\",\"14633221679057159.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "58894801679057149.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Flips 120g Surtido",
    //         "oferta": "",
    //         "precio": 2.15,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "flips-120g-surtido",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NMdbXAbeD8XLHiv9fGr": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-09-08T11:06:46.357Z",
    //         "galeria": "[\"845861674661729.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "44076111674661722.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Harina la Oz 1kg",
    //         "oferta": "[\"Fixed\",0.98,\"2025-01-31\"]",
    //         "precio": 1.0486,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "harina-la-oz-1kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-ODSWlibWKNRPYpBwDVN": {
    //         "categoria": "cuentas-de-streaming-",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"1 PERFIL\":[{\"HBO max\":2,\"Prime video\":3,\"Disney plus \":3,\"Paramount plus \":1.5,\"Netflix solo TV\":4.5,\"Flujo tv (magis tv)\":6.5,\"Crunchyroll\":1.5}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-12-06T19:51:36.250Z",
    //         "galeria": "[\"31335521733514696.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "51647701733514696.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Cuentas De Streaming ",
    //         "oferta": "",
    //         "precio": 2,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1000,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cuentas-de-streaming-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NsGMqyYD0Ym5HcWeCl4": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-03-06T01:27:22.712Z",
    //         "galeria": "[\"21263131709688447.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "23027201709688442.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Suavizante Karens 4litos",
    //         "oferta": "",
    //         "precio": 7.22,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "suavizante-karens-4litos",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MtNbbmz-a4bfhdlbLqR": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Galleta Muuu Sándwich 396g\":[{\"Yogurt fresa \":2.3,\"Avena leche \":2.3}]}]",
    //         "estado": true,
    //         "fecha_create": "2022-02-12T00:06:52.382Z",
    //         "galeria": "[\"89961831642164286.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "94771331642164258.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Galleta Muuu Sándwich 396g",
    //         "oferta": "[\"Fixed\",2.3,\"2023-07-31\"]",
    //         "precio": 2.5,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 5,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "galleta-muuu-sandwich-396g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NWDsCRuLXzhdh0Lab36": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-05-24T17:48:57.254Z",
    //         "galeria": "[\"3098501684950537.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "7974291684950536.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Sabroceador Iberia 180g",
    //         "oferta": "",
    //         "precio": 2.57,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "sabroceador-iberia-180g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Niltoa6id05weKfBm-L": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-29T08:47:52.248Z",
    //         "galeria": "[\"32776571699496797.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "96921061699496790.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Alcaparra Giralda 200g",
    //         "oferta": "",
    //         "precio": 2.03,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "alcaparra-giralda-200g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MV6q8phtkL58rVY6fq7": {
    //         "categoria": "articulos-de-belleza",
    //         "costo": "",
    //         "descripcion": "Algodón puro higietex 20g",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-09-07T20:39:05.276Z",
    //         "galeria": "[\"87073321615039337.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "61272621615039337.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Algodón puro higietex 20g",
    //         "oferta": "",
    //         "precio": 1.15,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 20,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "algodon-puro-higietex-20g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NMHfIrlKtJidPTOpXcl": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Recao\":[{\"Entera 1kg\":3.465,\"Rebanada 1kg\":3.465}]}]",
    //         "estado": true,
    //         "fecha_create": "2023-01-21T04:54:05.493Z",
    //         "galeria": "[\"25546401674276846.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "66465671674276845.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Mortadela De Pollo Recao 1kg",
    //         "oferta": "[\"Fixed\",3.15,\"2025-12-31\"]",
    //         "precio": 3.465,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "mortadela-de-pollo-recao-1kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Nrqmw3V3Yj0jvIxRiYv": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-02-29T21:36:02.818Z",
    //         "galeria": "[\"79031551709242564.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "12841931709242562.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Harina Robin Hood Leudante",
    //         "oferta": "",
    //         "precio": 1.33,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 20,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "harina-robin-hood-leudante",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NabjG2FE9GIfOocP7Ne": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2023-07-30T16:58:49.894Z",
    //         "galeria": "[\"82223471690736332.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "1233571690736331.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Mayonesa Natu Campo 200g",
    //         "oferta": "[\"Fixed\",1.88,\"2025-01-31\"]",
    //         "precio": 2.0116,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "mayonesa-natu-campo-200g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPjVjkTsbhzhlTSpYyD": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "<ol><li><b>1Kg Bistec</b></li><li><b>1Kg Strogonof</b></li><li><b>1Kg Mechar</b></li><li><b>1Kg Guisar</b></li><li><b>1Kg Molida</b></li><li><b>1Kg Chuleta de Cerdo</b></li></ol>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2020-12-29T17:52:51.427Z",
    //         "galeria": "[\"38161381720176448.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "24606911720176447.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo Carne Y Cerdo 6kg",
    //         "oferta": "[\"Fixed\",42,\"2025-12-31\"]",
    //         "precio": 44.94,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-carne-y-cerdo-6kg",
    //         "video": "[]",
    //         "views": 35
    //     },
    //     "-MQKkoO5nJEjqzyAgl4u": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Salsa Fritz Picante 250g</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-06T03:35:09.522Z",
    //         "galeria": "[\"7928131616370662.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "2662101616370655.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Salsa Fritz Picante 250g",
    //         "oferta": "",
    //         "precio": 2.73,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salsa-fritz-picante-250g",
    //         "video": "[]",
    //         "views": 5
    //     },
    //     "-Moo-Xyc4d69q8nyI12k": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-04-18T20:33:04.221Z",
    //         "galeria": "[\"30651781637255091.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "25007801637255074.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Papel Rosal 400 Hojas X 4 Uunid",
    //         "oferta": "",
    //         "precio": 2.2,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "papel-rosal-400-hojas-x-4-uunid",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OIazLRoFlAIpxePVc7m": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T19:15:42.752Z",
    //         "galeria": "[\"12365001739042153.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "83923111739042142.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Galletas Happy Cream 408g",
    //         "oferta": "[\"Fixed\",2.55,\"2025-12-31\"]",
    //         "precio": 2.7285,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "galletas-happy-cream-408g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NIIMfrvvcr-XZ77jir9": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-29T08:47:52.248Z",
    //         "galeria": "[\"61009611669993511.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "98891861669993510.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Alcaparra Eureka 200g",
    //         "oferta": "",
    //         "precio": 2.29,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "alcaparra-eureka-200g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPsyAzxAwv6A1JqQxkS": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "<b>Queso Mozzarella</b>",
    //         "detalles": "[{\"title\":\"Marca\",\"value\":\"Agua linda\"}]",
    //         "especificaciones": "[{\"Peso\":[{\"250gr Rebanado \":2.2898,\"500gr Rebanado \":4.5796,\"250gr Entero\":2.2898,\"500gr Entero\":4.5796,\"300gr\":2.74776}]}]",
    //         "estado": true,
    //         "fecha_create": "2022-08-30T15:24:03.834Z",
    //         "galeria": "[\"70594191609421078.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "75763961609421056.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Queso Mozzarella agua linda ",
    //         "oferta": "[\"Fixed\",2.14,\"2025-12-31\"]",
    //         "precio": 2.2898,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "queso-mozzarella-agua-linda-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mje8VCvoD7oUfyqlW8a": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Uva pasa\":[{\"500g\":2,\"250g\":1}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-10-29T08:47:52.248Z",
    //         "galeria": "[\"92331431631720964.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "38179121631720946.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Uvas Pasas A Granel kg",
    //         "oferta": "",
    //         "precio": 5,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "uvas-pasas-a-granel-kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NH07L939ffJ85aL_YcG": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T19:34:21.766Z",
    //         "galeria": "[\"81082691668613757.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "3713541668613756.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Nuthos Mani Salado ",
    //         "oferta": "[\"Fixed\",1.55,\"2025-12-31\"]",
    //         "precio": 1.685,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "nuthos-mani-salado-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NSa20UDM8W-78sgrOJs": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-04-09T12:44:39.792Z",
    //         "galeria": "[\"38949461681044280.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "77047731681044279.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Colonia Melody 200ml",
    //         "oferta": "",
    //         "precio": 4.47,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "colonia-melody-200ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPzZRmsKGVrQz4omHkY": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "descripcion": "<b>Hígado</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"500gr\":2.1935,\"1kg\":4.387}]}]",
    //         "estado": true,
    //         "fecha_create": "2023-05-23T20:30:40.668Z",
    //         "galeria": "[\"19177961609531770.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "22662611609531768.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Higado",
    //         "oferta": "[\"Fixed\",2.05,\"2025-12-31\"]",
    //         "precio": 2.1935,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "higado",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPzTwBbzD6THYXw6Cpf": {
    //         "categoria": "cerdo",
    //         "costo": "",
    //         "descripcion": "<b>1kg de Garra / Tocino</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Tocino\":[{\"1kg\":3.9055}]}]",
    //         "estado": true,
    //         "fecha_create": "2021-01-01T19:45:23.267Z",
    //         "galeria": "[\"2530101609530327.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "11573861609530323.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Garra o Tocino 1Kg",
    //         "oferta": "[\"Fixed\",3.65,\"2025-12-31\"]",
    //         "precio": 3.9055,
    //         "prox": false,
    //         "sales": 1,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "garra-o-tocino-1kg",
    //         "video": "[]",
    //         "views": 26
    //     },
    //     "-NWkkfSNLjKRDQPK_bXg": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-05-31T07:43:07.802Z",
    //         "galeria": "[\"61275941685518988.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "7874981685518987.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Pinchos Sol 100 unid",
    //         "oferta": "",
    //         "precio": 1.97,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pinchos-sol-100-unid",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NHaBjzkhBk6ngReIxlw": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-11-23T20:34:30.162Z",
    //         "galeria": "[\"37491141669235670.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "26521371669235668.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Coleto Micro Fibra",
    //         "oferta": "",
    //         "precio": 2.65,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "coleto-micro-fibra",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQJvDK2HgwRhIybrR6L": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Flan Sonrissa 46gr9</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-29T08:47:52.248Z",
    //         "galeria": "[\"93913131609890061.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "7293121609890057.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Flan Sonrissa 46gr",
    //         "oferta": "",
    //         "precio": 1.66,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 48,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "flan-sonrissa-46gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MY8A6xrROqv6IFjp8d3": {
    //         "categoria": "mascotas",
    //         "costo": "",
    //         "descripcion": "Gatarina mirringo 500gr",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2023-04-05T10:23:20.871Z",
    //         "galeria": "[\"20621001663173385.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "31366871663173382.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Gatarina Mirringo 1kg",
    //         "oferta": "[\"Fixed\",3.74,\"2023-12-31\"]",
    //         "precio": 3.84,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "gatarina-mirringo-1kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MY8bIVr-_72ptFG4dUy": {
    //         "categoria": "bebidas-y-lacteos",
    //         "costo": "",
    //         "descripcion": "<font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">Jugos natulac 1L</font></font>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Sabor \":[\"Durazno\",\"Mango\",\"Manzana\",\"Pera\"]}]",
    //         "estado": true,
    //         "fecha_create": "2025-02-09T09:20:27.640Z",
    //         "galeria": "[\"21165051618290224.jpg\",\"83193811618290224.jpg\",\"64835521618290225.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "78399851618290220.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Jugo Natulac 1lt",
    //         "oferta": "[\"Fixed\",2.32,\"2025-12-31\"]",
    //         "precio": 2.4824,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jugo-natulac-1lt",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NRY4CUWAfDTHNz10qdj": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-05-11T14:22:47.710Z",
    //         "galeria": "[\"14014041683815492.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "42397331683815486.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Queso Ricota",
    //         "oferta": "[\"Fixed\",2.99,\"2025-12-31\"]",
    //         "precio": 3.1993,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "queso-ricota",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MnGgT_76U4tOT_iUj3T": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "Aceitunas rellenas frasco de 500gr",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-29T08:47:52.248Z",
    //         "galeria": "[\"98195451635606143.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "60023291635606141.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Aceitunas Rellenas De 500gr La Giralda",
    //         "oferta": "",
    //         "precio": 5.3,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 50,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "aceitunas-rellenas-de-500gr-la-giralda",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MmhvmS29z5o144hoJKs": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<p>Especies:</p><p>1. Onoto molido (color)</p><p>2. Curry en polvo&nbsp;</p><p>3 orégano entero&nbsp;</p><p>4. Hojas de albahaca</p><p>5. Pimienta negra entera</p>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-10-23T16:14:48.977Z",
    //         "galeria": "[\"60728591635005702.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "52914291635005689.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Especies Para Salsas ",
    //         "oferta": "",
    //         "precio": 2.35,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "especies-para-salsas-",
    //         "video": "[]",
    //         "views": 3
    //     },
    //     "-MPtM_NqgyYLfqGdwHFo": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Entero\":[{\"500gr\":4.173,\"1kg\":8.346}]}]",
    //         "estado": true,
    //         "fecha_create": "2020-12-31T15:15:35.768Z",
    //         "galeria": "[\"99731681609427735.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "6595661609427735.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Muchacho Redondo ",
    //         "oferta": "[\"Fixed\",3.675,\"2025-12-31\"]",
    //         "precio": 4.173,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "muchacho-redondo-",
    //         "video": "[]",
    //         "views": 17
    //     },
    //     "-NnyRZcfDu_-NMu8se5N": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-28T21:22:59.985Z",
    //         "galeria": "[\"21471381705075944.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "17826611705075937.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Colgate Triple Acción 60 ml",
    //         "oferta": "[\"Fixed\",1.32,\"2025-12-31\"]",
    //         "precio": 1.4124,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "colgate-triple-accion-60-ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MY8SvF3PA5sEyUNmuXf": {
    //         "categoria": "confiteria-y-galletas",
    //         "descripcion": "Arequipe la cuesta 250gr",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2021-04-13T04:22:44.095Z",
    //         "galeria": "[\"30812851618287767.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "347211618287765.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Arequipe La Cuesta 250gr ",
    //         "oferta": "",
    //         "precio": 1.29,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "arequipe-la-cuesta-250gr-",
    //         "video": "[]",
    //         "views": 29
    //     },
    //     "-NhCNuyXLIqOURinW6ia": {
    //         "categoria": "cerdo",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\" Bandeja \":[\"600gr\"]}]",
    //         "estado": true,
    //         "fecha_create": "2023-10-20T14:00:56.876Z",
    //         "galeria": "[\"93322421697810459.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "12269401697810457.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Papada De Cerdo",
    //         "oferta": "[\"Fixed\",2.5,\"2025-12-31\"]",
    //         "precio": 2.675,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "papada-de-cerdo",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mi7pzYcAk2Xc25Tzym5": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "Caraota Rosada Pantera 500g",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-05-20T20:36:36.298Z",
    //         "galeria": "[\"999431630088459.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "91186251630088455.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Caraota Rosada Pantera 500g",
    //         "oferta": "[\"Fixed\",2.46,\"2023-12-31\"]",
    //         "precio": 2.56,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "caraota-rosada-pantera-500g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MR_IVtLbUWkDHNaJ3p3": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "descripcion": "<p><b>Papa</b></p><ul><li><b>1kg</b></li><li><b>1.5kg</b></li></ul>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"1kg\":1.5,\"1.5kgt\":2.25,\"2kg\":3}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-02-16T09:42:18.255Z",
    //         "galeria": "[\"85037781611238608.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "83369261611238607.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Papa ",
    //         "oferta": "[\"Disccount\",15,\"2025-02-28\"]",
    //         "precio": 1.5,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "papa-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQKbTdDDZ0H-4MWZeX6": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Pepitona Margarita 140g</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-06T02:54:23.342Z",
    //         "galeria": "[\"73569971609901664.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "59735971609901663.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Pepitona Margarita 140g",
    //         "oferta": "",
    //         "precio": 2.04,
    //         "prox": false,
    //         "sales": 2,
    //         "stock": 35,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pepitona-margarita-140g",
    //         "video": "[]",
    //         "views": 5
    //     },
    //     "-O0UC9x2PwE0Qe3oG0Ed": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-06-28T14:16:44.474Z",
    //         "galeria": "[\"2370191719584206.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "55664711719584205.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Trululu Chococolores 30g Surtido",
    //         "oferta": "",
    //         "precio": 0.53,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "trululu-chococolores-30g-surtido",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MgR0RxT1eyx0-tjkvlz": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "Espuma de afeitar Xen 300ml",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2023-02-15T21:37:29.411Z",
    //         "galeria": "[\"5116941628262747.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "45006411628262746.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Espuma de afeitar Xen 300ml",
    //         "oferta": "[\"Fixed\",2.51,\"2024-01-31\"]",
    //         "precio": 2.6857,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "espuma-de-afeitar-xen-300ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPtNT9rgf7Gl6bDd_Ja": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "descripcion": "<b>Carne de Mechar</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"500gr\":3.852,\"1kg\":7.704}]}]",
    //         "estado": true,
    //         "fecha_create": "2023-05-23T20:30:40.668Z",
    //         "galeria": "[\"7095221609427968.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "89074351609427966.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Carne de Mechar",
    //         "oferta": "[\"Fixed\",3.6,\"2025-12-31\"]",
    //         "precio": 3.852,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "carne-de-mechar",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MmhfmoNEpA9BW8m8iXE": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Sabores\":[{\"Comino\":0.5,\"Color\":0.3,\"Sal condimentada \":0.4,\"Pimienta entera\":0.59,\"Pimienta molida\":0.79,\"Curry\":0.29,\"Orégano entero \":0.29,\"Oregano molido \":0.35,\"Ajo desidratado\":1.18,\"Laurel\":0.74,\"Cebolla desidratada\":0.5,\"Paprika \":0.4,\"Albaca\":0.44,\"Cúrcuma \":1.18}]}]",
    //         "estado": true,
    //         "fecha_create": "2021-10-23T15:05:04.396Z",
    //         "galeria": "[\"51329481635001509.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "51843671635001504.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Especias Para Carnes ",
    //         "oferta": "",
    //         "precio": 1,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "especias-para-carnes-",
    //         "video": "[]",
    //         "views": 2
    //     },
    //     "-MPzUSp3Ia9mJALokxo-": {
    //         "categoria": "cerdo",
    //         "costo": "",
    //         "descripcion": "<p><b>Patas de Cerdo</b></p>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Cantidad \":[{\"1 pata\":1.8832,\"2 patas\":3.7664}]}]",
    //         "estado": true,
    //         "fecha_create": "2021-01-01T19:47:44.511Z",
    //         "galeria": "[\"65823311609530464.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "35153711609530464.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Patas de Cerdo",
    //         "oferta": "[\"Fixed\",1.76,\"2025-12-31\"]",
    //         "precio": 1.8832,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "patas-de-cerdo",
    //         "video": "[]",
    //         "views": 18
    //     },
    //     "-NnzpVEedIAyhSa9iLp8": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-01-12T22:41:03.057Z",
    //         "galeria": "[\"29568601705099264.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "9606341705099263.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Hisopos 200 Unidadez",
    //         "oferta": "",
    //         "precio": 1.53,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "hisopos-200-unidadez",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O3E9Pt9V5gxDv0gBuON": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Preparación \":[\"Entera\",\"Rebanada\"]}]",
    //         "estado": true,
    //         "fecha_create": "2024-08-01T18:17:36.919Z",
    //         "galeria": "[\"93856911723683199.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "4864121723683199.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Mortadela Caracas 1kg",
    //         "oferta": "[\"Fixed\",3.57,\"2025-12-31\"]",
    //         "precio": 3.8199,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "mortadela-caracas-1kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NUzhDLFaNc3dLUNzf3Q": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-05-09T08:50:55.363Z",
    //         "galeria": "[\"7936331683622257.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "91842071683622256.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Nuthos Mezcla Premium 180g",
    //         "oferta": "[\"Fixed\",2.97,\"2024-12-31\"]",
    //         "precio": 3.1779,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "nuthos-mezcla-premium-180g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NMpSnbGiRAj2eZ2vxk1": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-01-16T00:33:01.251Z",
    //         "galeria": "[\"88513391674860505.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "13399571674860502.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Papel Rendy X 12 Rollos ",
    //         "oferta": "[\"Fixed\",6.5,\"2025-12-31\"]",
    //         "precio": 6.955,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "papel-rendy-x-12-rollos-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N72vxQ-w6WCADNp_TBZ": {
    //         "categoria": "viveres",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"170g\":[{\"En salsa de tomate \":0.92,\"En salsa italiana\":0.92}]}]",
    //         "estado": true,
    //         "fecha_create": "2022-07-28T17:11:48.107Z",
    //         "galeria": "[\"77402031657923424.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "25454571657923416.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Sardina El Faro 170gr",
    //         "oferta": "",
    //         "precio": 0.92,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "sardina-el-faro-170gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NH076S3hYF866WPQ1Bh": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-11-16T15:48:18.351Z",
    //         "galeria": "[\"29501681668613699.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "85823081668613698.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Salsa De Queso Cheddar Crachos 200g",
    //         "oferta": "[\"Fixed\",1.86,\"2024-12-31\"]",
    //         "precio": 1.9902,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salsa-de-queso-cheddar-crachos-200g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NLgx3wmvxuAcEKoIZvq": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2023-01-13T21:06:08.628Z",
    //         "galeria": "[\"83122431673643970.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "444311673643969.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Lonja Bocadillo Rey ",
    //         "oferta": "[\"Fixed\",0.75,\"2023-07-31\"]",
    //         "precio": 0.84,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "lonja-bocadillo-rey-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPk8-TO4dvCWkJno7LG": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "<ol><li><b>1Kg Bistec</b></li><li><b>1Kg Strogonof</b></li><li><b>1Kg Guisar</b></li><li><b>1Kg Mechar</b></li><li><b>1Kg Molida</b></li><li><b>1Kg Costilla de Res</b></li><li><b>1Kg Chorizos Ahumados</b></li><li><b>1Kg Pechuga de Pollo</b></li><li><b>1Kg Muslo de Pollo</b></li><li><b>1Kg Picadillo de Pollo</b></li><li><b>1Kg Alas de Pollo</b></li><li><b>1Kg Costilla de cerdo o chuleta</b></li><li><b>1 cartón de huevos</b></li></ol>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2020-12-29T20:48:04.275Z",
    //         "galeria": "[\"40474921690539312.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "1181621690539308.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo Full Carnes",
    //         "oferta": "[\"Fixed\",76,\"2025-12-31\"]",
    //         "precio": 81.32,
    //         "prox": false,
    //         "sales": 2,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-full-carnes",
    //         "video": "[]",
    //         "views": 147
    //     },
    //     "-OIarcxglspYycxVazMO": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T18:41:24.724Z",
    //         "galeria": "[\"83445331739040131.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "51266001739040084.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Cereal Fit Graan Hojuelas De Maíz 300g",
    //         "oferta": "[\"Fixed\",1.95,\"2025-12-31\"]",
    //         "precio": 2.0865,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cereal-fit-graan-hojuelas-de-maiz-300g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N442D1nGDCCGGONZLhL": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-06-08T20:41:15.964Z",
    //         "galeria": "[\"8457441654720880.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "70473271654720876.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Predilecta Milho De 255g ",
    //         "oferta": "",
    //         "precio": 1.65,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "predilecta-milho-de-255g-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQKKykDJh0J_0FIc4BY": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Papel aluminio tuc 13mt</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-06T01:37:55.706Z",
    //         "galeria": "[\"99452941616371815.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "95747001616371810.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Papel Aluminio 21 Mtrs",
    //         "oferta": "",
    //         "precio": 4.97,
    //         "prox": false,
    //         "sales": 2,
    //         "stock": 8,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "papel-aluminio-21-mtrs",
    //         "video": "[]",
    //         "views": 7
    //     },
    //     "-MQ3euHBFnKUnzmYuImy": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-aue-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Crema dental fortident 60ml</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-28T21:22:59.985Z",
    //         "galeria": "[\"67502991609617350.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "17844581609617342.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Crema Dental Fortident 76g",
    //         "oferta": "[\"Fixed\",1,\"2025-12-31\"]",
    //         "precio": 1.07,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "crema-dental-fortident-76g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NOLtGuxTPs0Eows8nXZ": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-01-16T00:28:31.671Z",
    //         "galeria": "[\"28776881676495101.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "18476041676495100.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Vinagre Zev 1000ml",
    //         "oferta": "[\"Fixed\",1.28,\"2025-12-31\"]",
    //         "precio": 1.3696,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "vinagre-zev-1000ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQKFc-DCkGXfHwItgrR": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Mayonesa mavesa 445g</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-06T01:14:30.760Z",
    //         "galeria": "[\"23414161609895673.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "68542231609895670.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Mayonesa Mavesa 445g",
    //         "oferta": "",
    //         "precio": 3.81,
    //         "prox": false,
    //         "sales": 2,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "mayonesa-mavesa-445g",
    //         "video": "[]",
    //         "views": 12
    //     },
    //     "-NqlMH6MZ8pJ5l50qdPf": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "descripcion": "<p>Dos unidades </p>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-02-16T10:01:26.132Z",
    //         "galeria": "[\"38622601708077850.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "59251241708077849.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Mazorcas X 2 Unidades ",
    //         "oferta": "[\"Disccount\",10,\"2025-02-28\"]",
    //         "precio": 1.3,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "mazorcas-x-2-unidades-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MR_IuzH-G-xGHAWWuic": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "descripcion": "<p><b>Plátano Verde</b></p><ul><li><b>1kg</b></li><li><b>1.5kg</b></li></ul>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"1kg\":1.3,\"1.5kg\":1.95,\"2kg\":2.6}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-02-16T09:42:18.255Z",
    //         "galeria": "[\"53078881611238714.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "94078771611238709.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Plátano Verde",
    //         "oferta": "[\"Disccount\",15,\"2025-02-28\"]",
    //         "precio": 1.3,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "platano-verde",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O0PCP7W-YFVT9_9Kkjw": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-06-27T14:59:41.615Z",
    //         "galeria": "[\"2091791719500382.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "6755391719500381.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Queso Crema Tachira 200gr",
    //         "oferta": "[\"Fixed\",6.13,\"2025-12-31\"]",
    //         "precio": 6.5591,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "queso-crema-tachira-200gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NLgnnW6lHVg4fS3eZll": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-07-22T15:02:57.454Z",
    //         "galeria": "[\"60146771673641539.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "57418781673641537.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Desengrasante Brilla King 500ml",
    //         "oferta": "[\"Fixed\",1.13,\"2025-12-31\"]",
    //         "precio": 1.2091,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "desengrasante-brilla-king-500ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQKIkk8efCvFmBKD-8M": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Pabilo</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-29T08:47:52.248Z",
    //         "galeria": "[\"11789891609896494.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "69933471609896488.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Pavilo",
    //         "oferta": "",
    //         "precio": 0.54,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pavilo",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mb3dY0drsGPcLhoFMjm": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-07-05T15:03:08.153Z",
    //         "galeria": "[\"66639301622501895.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "55882111622501894.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Salsa Inglesa Heinz 150ml",
    //         "oferta": "",
    //         "precio": 1.98,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salsa-inglesa-heinz-150ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQKiLktvyYEHNEWkWBN": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Salsa Fritz BBQ 290g</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-06T03:24:26.362Z",
    //         "galeria": "[\"58538251609903466.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "27096481609903466.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Salsa Fritz BBQ 290g",
    //         "oferta": "",
    //         "precio": 2.81,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salsa-fritz-bbq-290g",
    //         "video": "[]",
    //         "views": 2
    //     },
    //     "-NH08qde38lqJfObdF3J": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T19:34:21.766Z",
    //         "galeria": "[\"40427121668614154.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "53612071668614153.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Desinfectante Brilla King Bicarbonato ",
    //         "oferta": "[\"Fixed\",1.02,\"2025-12-31\"]",
    //         "precio": 1.0914,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "desinfectante-brilla-king-bicarbonato-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Me_XI52Otkv74q1fB3r": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-07-14T15:01:03.631Z",
    //         "galeria": "[\"4196671626274867.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "47712151626274862.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Antibacterial Con Áloe Vera 300ml",
    //         "oferta": "[\"Fixed\",1.14,\"2023-12-31\"]",
    //         "precio": 1.28,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "antibacterial-con-aloe-vera-300ml",
    //         "video": "[]",
    //         "views": 8
    //     },
    //     "-MR_UCCzr-QhapW5NRXG": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"500gr\":1.75,\"1kg\":3.5}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-02-16T09:42:18.255Z",
    //         "galeria": "[\"4363881611241672.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "98679841611241671.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Fresa Congelada",
    //         "oferta": "[\"Disccount\",10,\"2025-02-28\"]",
    //         "precio": 3.5,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MhEJbffifKGjedoDEf5": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-08-16T14:16:47.846Z",
    //         "galeria": "[\"19539341629123410.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "11714201629123407.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Paño micro fibra matriz 35x35 cm",
    //         "oferta": "",
    //         "precio": 1.23,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pano-micro-fibra-matriz-35x35-cm",
    //         "video": "[]",
    //         "views": 2
    //     },
    //     "-NT44p5wzhufVp5Ch5ea": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Balance\":[\"Men\",\"Care\"]}]",
    //         "estado": true,
    //         "fecha_create": "2023-04-15T13:25:07.820Z",
    //         "galeria": "[\"94957451681565110.jpg\",\"81629031681565110.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "8768271681565108.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Desodorante Balance 30gr",
    //         "oferta": "[\"Fixed\",1.45,\"2025-12-31\"]",
    //         "precio": 1.5515,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "desodorante-balance-30gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MShylhRqp0WmbHzadEw": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "descripcion": "<p><b>Carne en strogonoff</b></p>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"500gr\":3.9269,\"1kg\":7.8538}]}]",
    //         "estado": true,
    //         "fecha_create": "2021-02-04T16:58:26.911Z",
    //         "galeria": "[\"30497731612718070.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "44167011612718069.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Carne En Strogonoff",
    //         "oferta": "[\"Fixed\",3.67,\"2025-12-31\"]",
    //         "precio": 3.9269,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "carne-en-strogonoff",
    //         "video": "[]",
    //         "views": 20
    //     },
    //     "-MPtBocQn7iCs72LRWhr": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "<b>Chorizos Jamonisados</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Paquete 450gr\":[\"Ajo\",\"Chorijamon\",\"Surtido\",\"Ahumados\"]}]",
    //         "estado": true,
    //         "fecha_create": "2024-07-09T13:46:25.236Z",
    //         "galeria": "[\"3560521720533457.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "59352451720533446.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Chorizos Jamonisados ",
    //         "oferta": "[\"Fixed\",4,\"2025-12-31\"]",
    //         "precio": 4.28,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "chorizos-jamonisados-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MxV8a8ukuGoNO12cEd-": {
    //         "categoria": "bebes-",
    //         "costo": "",
    //         "descripcion": "Aceite para Bebé Melody 200cm3",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-03-06T16:53:21.297Z",
    //         "galeria": "[\"19503841646585601.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "10382231646585600.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Aceite Para Bebé Melody 200cm3",
    //         "oferta": "",
    //         "precio": 3.84,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 3,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "aceite-para-bebe-melody-200cm3",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mez3EXAjLHU2YlQmyFV": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-07-19T13:59:57.363Z",
    //         "galeria": "[\"65655941626703197.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "15402341626703197.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Leche Evaporada Nestle 360ml",
    //         "oferta": "",
    //         "precio": 2.94,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "leche-evaporada-nestle-360ml",
    //         "video": "[]",
    //         "views": 3
    //     },
    //     "-MPjYnfjGM48dYLgmn_k": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "<ol><li><strong>1kg Bistec</strong></li><li><strong>1Kg Mechar</strong></li><li><strong>1Kg Guisar</strong></li><li><strong>1Kg Molida</strong></li><li><strong>1Kg Costilla de Res</strong></li><li><strong>1Kg pechuga entera</strong></li><li><strong>1Kg Muslo</strong></li><li><strong>1Kg Alas</strong></li></ol>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Preparación \":[\"*con pechuga y muslo sin arreglar*\",\"*con pechuga arreglada en filet y muslo arreglado sin piel*\"]}]",
    //         "estado": true,
    //         "fecha_create": "2020-12-29T18:06:29.819Z",
    //         "galeria": "[\"42655951720176516.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "65183051720176515.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo Carne y pollo 8Kg",
    //         "oferta": "[\"Fixed\",43,\"2025-12-31\"]",
    //         "precio": 46.01,
    //         "prox": false,
    //         "sales": 1,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-carne-y-pollo-8kg",
    //         "video": "[]",
    //         "views": 139
    //     },
    //     "-MQKriRVPrN8Xk-x59X5": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Vinagre de litro Mavesa</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-06T04:05:22.632Z",
    //         "galeria": "[\"91659701609905923.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "1853791609905922.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Vinagre de litro Mavesa",
    //         "oferta": "",
    //         "precio": 2.02,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "vinagre-de-litro-mavesa",
    //         "video": "[]",
    //         "views": 2
    //     },
    //     "-MPzTbx0CeD5E-iCaVi-": {
    //         "categoria": "cerdo",
    //         "costo": "",
    //         "descripcion": "<b>Pulpa de Cerdo</b>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"500gr\":4.1195,\"1kg\":8.239}]}]",
    //         "estado": true,
    //         "fecha_create": "2021-01-01T19:44:03.049Z",
    //         "galeria": "[\"99335901609530244.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "37991201609530242.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Pulpa de Cerdo",
    //         "oferta": "[\"Fixed\",3.85,\"2025-12-31\"]",
    //         "precio": 4.1195,
    //         "prox": false,
    //         "sales": 2,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "pulpa-de-cerdo",
    //         "video": "[]",
    //         "views": 41
    //     },
    //     "-MUTo3XWnpD-BQddrGTI": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "<p><b>Crema Dental Galactic</b></p><ul><li><b>Classic 100g</b></li></ul>",
    //         "detalles": "",
    //         "especificaciones": "[{\"Unidad\":[{\"1\":2.89}]}]",
    //         "estado": true,
    //         "fecha_create": "2021-02-26T14:48:45.968Z",
    //         "galeria": "[\"95445661614350927.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "29359661614350925.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Crema Dental Galactic Classic 180g",
    //         "oferta": "",
    //         "precio": 2.89,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "crema-dental-galactic-classic-180g",
    //         "video": "[]",
    //         "views": 5
    //     },
    //     "-OCya90nkR31Jp__inMF": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-30T19:42:29.038Z",
    //         "galeria": "[\"17079041732995751.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "58138751732995751.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Listerine Original 180 ml",
    //         "oferta": "",
    //         "precio": 3.55,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 3,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "listerine-original-180-ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MShznlZtzLebX4BV9wX": {
    //         "categoria": "carnes",
    //         "costo": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Medallones de lomito\":[{\"1.8 kg\":15.9858}]}]",
    //         "estado": true,
    //         "fecha_create": "2021-02-04T17:02:57.626Z",
    //         "galeria": "[\"88863161612458178.jpg\",\"9818331612715944.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "127701612715937.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Medallones De Lomito 1.8 kg",
    //         "oferta": "[\"Fixed\",14.94,\"2025-12-31\"]",
    //         "precio": 15.9858,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "medallones-de-lomito-1-8-kg",
    //         "video": "[]",
    //         "views": 18
    //     },
    //     "-MR_QsPqQa27lfH27ghs": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"1kg\":2.5,\"1.5kg\":3.75,\"2kg\":5}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-02-16T09:42:18.255Z",
    //         "galeria": "[\"88328211611240801.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "22926611611240800.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Naranja",
    //         "oferta": "[\"Disccount\",10,\"2025-02-28\"]",
    //         "precio": 2.5,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "naranja",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQK9FzAfkmqGHI7_N0k": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Margarina mavesa 1kg</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-06T00:46:42.488Z",
    //         "galeria": "[\"26421021609894006.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "84293011609894002.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Margarina mavesa 1kg",
    //         "oferta": "",
    //         "precio": 5.38,
    //         "prox": false,
    //         "sales": 2,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "margarina-mavesa-1kg",
    //         "video": "[]",
    //         "views": 10
    //     },
    //     "-N49Hs1F-oBn85mcI0U3": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-01-16T00:33:01.251Z",
    //         "galeria": "[\"65743971654808870.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "17788501654808868.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Aceite De Oliva Castell De Ferro 500ml",
    //         "oferta": "[\"Fixed\",9.13,\"2025-12-31\"]",
    //         "precio": 9.7691,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "aceite-de-oliva-castell-de-ferro-500ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NFQAYrZVyIbfMiQF-X7": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-10-27T21:03:17.626Z",
    //         "galeria": "[\"66633221667754217.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "60154081667754216.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Iberia Inglesa 300g",
    //         "oferta": "",
    //         "precio": 3.75,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "iberia-inglesa-300g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mprf6Pj6xFRMgkwmEVj": {
    //         "categoria": "licores",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Sabor\":[\"Tinto\",\"Blanco\"]}]",
    //         "estado": true,
    //         "fecha_create": "2024-10-29T08:47:52.248Z",
    //         "galeria": "[\"51053701638390329.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "95097871638390314.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Vino Sagrada cena ",
    //         "oferta": "[\"Fixed\",5.23,\"2025-12-31\"]",
    //         "precio": 5.5961,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "vino-sagrada-cena-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NIEmMfNVU4l25r5p99X": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-12-01T22:23:17.111Z",
    //         "galeria": "[\"58952261669933398.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "41892491669933397.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Aceite Ideal Soya 1000ml",
    //         "oferta": "",
    //         "precio": 2.76,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "aceite-ideal-soya-1000ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQEiw7V7grVNv3an03z": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-vnw-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Avena Quaker 400gr</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-04T23:29:12.644Z",
    //         "galeria": "[\"74657911609802956.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "8682231609802952.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Avena Quaker 400gr",
    //         "oferta": "",
    //         "precio": 1.61,
    //         "prox": false,
    //         "sales": 6,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "avena-quaker-400gr",
    //         "video": "[]",
    //         "views": 15
    //     },
    //     "-MTSZq7hri9oqb-VZsFx": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "<b>Paquete de galletas muu</b>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-01-16T00:33:01.251Z",
    //         "galeria": "[\"12559711613256416.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "34599891613256411.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Paquete De Galleta muu ",
    //         "oferta": "[\"Fixed\",2.09,\"2025-12-31\"]",
    //         "precio": 2.2363,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "paquete-de-galleta-muu-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NrkCFI_RXTOFvpb5jPo": {
    //         "categoria": "viveres",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-02-28T14:53:40.358Z",
    //         "galeria": "[\"94892131709132021.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "12744441709132021.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Mostaza Fritz 950g",
    //         "oferta": "[\"Fixed\",5.45,\"2024-12-31\"]",
    //         "precio": 5.8615,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "mostaza-fritz-950g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mi7uTTQwIW6kKO4nG6S": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "Caraota Negra Pantera 500g",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-07-20T19:53:49.338Z",
    //         "galeria": "[\"60041581630089635.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "60052641630089631.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Caraota Negra Pantera 500g",
    //         "oferta": "[\"Fixed\",1.9,\"2025-12-31\"]",
    //         "precio": 2.033,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "caraota-negra-pantera-500g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NeEhEKM8nR6X1qQm4iw": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-09-13T18:01:54.573Z",
    //         "galeria": "[\"88416681694628115.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "22315611694628114.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Lima para uñas ",
    //         "oferta": "",
    //         "precio": 0.5,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 50,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "lima-para-unas-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MY8e2iDLBNXfS5axldT": {
    //         "categoria": "charcuteria",
    //         "costo": "",
    //         "descripcion": "Queso Pecorino Mi Vaquita",
    //         "detalles": "",
    //         "especificaciones": "[{\"Peso\":[{\"200gr\":6.42}]}]",
    //         "estado": false,
    //         "fecha_create": "2021-04-13T05:15:43.655Z",
    //         "galeria": "[\"15107851618290948.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "71385091618290944.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Queso Pecorino Mi Vaquita",
    //         "oferta": "[\"Fixed\",6,\"2025-01-31\"]",
    //         "precio": 6.42,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "queso-pecorino-mi-vaquita",
    //         "video": "[]",
    //         "views": 15
    //     },
    //     "-MdRqI2T1hP7HaubJxff": {
    //         "categoria": "mascotas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": false,
    //         "fecha_create": "2022-04-23T17:10:46.192Z",
    //         "galeria": "[\"30906171625056702.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "39103411625056692.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Perrarina Cachorros Magic Friends 1kg",
    //         "oferta": "[\"Fixed\",2.16,\"2025-01-31\"]",
    //         "precio": 2.3112,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "perrarina-cachorros-magic-friends-1kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MR_XWSB7OasNtrpz5nG": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-02-16T09:42:18.255Z",
    //         "galeria": "[\"39864041611242542.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "33575441611242541.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Lechosa",
    //         "oferta": "[\"Disccount\",10,\"2025-02-28\"]",
    //         "precio": 2,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "lechosa",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NV52Q8xjzkTW4Eu-RMx": {
    //         "categoria": "frutas-y-verduras",
    //         "costo": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Yuca por kg\":[{\"1kg\":1.3,\"1.5 kg\":1.95,\"2kg\":2.6}]}]",
    //         "estado": true,
    //         "fecha_create": "2024-02-16T09:42:18.255Z",
    //         "galeria": "[\"49777381683728740.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "25754641683728738.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Yuca",
    //         "oferta": "[\"Disccount\",10,\"2025-02-28\"]",
    //         "precio": 1.3,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 10,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "yuca",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NGMUq9CD7_hT8_cXHhA": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-04-27T14:25:25.006Z",
    //         "galeria": "[\"71897161667915276.jpg\",\"80123181667915313.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "51089101667915273.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Cloro Aromas 1 lit",
    //         "oferta": "",
    //         "precio": 1.18,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cloro-aromas-1-lit",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQEWVLjB-uv4Q83h2Cg": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-vnw-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Aceite Mazeite 1L</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-04T22:30:30.661Z",
    //         "galeria": "[\"48358051609799431.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "72448361609799430.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Aceite Mazeite 1L",
    //         "oferta": "",
    //         "precio": 3.92,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "aceite-mazeite-1l",
    //         "video": "[]",
    //         "views": 16
    //     },
    //     "-N4BxrbOOYSELqVOXZEa": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-02-27T02:28:40.098Z",
    //         "galeria": "[\"7716151654853695.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "9015681654853691.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Nutribela 10 Sobre Surtido X6 Unidades ",
    //         "oferta": "[\"Fixed\",2.59,\"2023-12-31\"]",
    //         "precio": 2.69,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "nutribela-10-sobre-surtido-x6-unidades-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mi7SCyHAR0sK5AxR0p3": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "Arveja Verde Partida Pantera 500g",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-09-19T15:07:23.185Z",
    //         "galeria": "[\"95515101630081965.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "28671921630081961.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Arveja Verde Partida Pantera 500g",
    //         "oferta": "[\"Fixed\",1.62,\"2025-12-31\"]",
    //         "precio": 1.7334,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "arveja-verde-partida-pantera-500g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O1MjlsY6e74p8tgXVFw": {
    //         "categoria": "cerdo",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[{\"Paquete \":[\"370gr\"]}]",
    //         "estado": true,
    //         "fecha_create": "2024-07-09T13:46:25.236Z",
    //         "galeria": "[\"57814381720532798.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "70968491720532784.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Lomo de cerdo ahumado ",
    //         "oferta": "[\"Fixed\",3.95,\"2025-12-31\"]",
    //         "precio": 4.2265,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "lomo-de-cerdo-ahumado-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MPl0PBWba8m6lBtNMe_": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "<ol><li><b>1Kg Bistec</b></li><li><b>1Kg Mechar</b></li><li><b>1Kg Molida</b></li><li><b>1Kg Costilla de Res</b></li><li><b>1/2kg Queso tipo paisa</b></li><li><b>1/2kg Mozarella</b></li><li><b>1/2kg Boloña Corral</b></li><li><b>1/2kg Jamón de Espalda Arichuna</b></li><li><b>1/2kg Chorizos Ahumados</b></li><li><b>1 Crema de leche 500gr</b></li><li><b>1/2 Cartón de Huevos&nbsp;</b></li></ol>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2020-12-30T00:21:41.003Z",
    //         "galeria": "[\"82201711690539743.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "79643211690539740.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo Charcucarne",
    //         "oferta": "[\"Fixed\",52,\"2025-12-31\"]",
    //         "precio": 55.64,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-charcucarne",
    //         "video": "[]",
    //         "views": 135
    //     },
    //     "-NpQSGdPvkoLlvEP2-GP": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-01-30T17:40:16.934Z",
    //         "galeria": "[\"58139451706636417.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "82899001706636416.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Mrmusculo Dde Ccocina Con rep",
    //         "oferta": "",
    //         "precio": 4.65,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 3,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "mrmusculo-dde-ccocina-con-rep",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-O2fU5T9Hy-_C0G1Mmbb": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-07-25T20:01:55.633Z",
    //         "galeria": "[\"60023531721937716.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "23386161721937715.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Champoo Natural Feeling 600ml",
    //         "oferta": "[\"Fixed\",3.52,\"2024-12-31\"]",
    //         "precio": 3.7664,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "champoo-natural-feeling-600ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NH0ApDnwHJuwjfy2jEk": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T19:34:21.766Z",
    //         "galeria": "[\"96627151668614670.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "27396171668614669.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Lavaplatos Liquido Brilla King ",
    //         "oferta": "[\"Fixed\",1.02,\"2025-12-31\"]",
    //         "precio": 1.0914,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "lavaplatos-liquido-brilla-king-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MhcrIasH6uZ-KslxbT7": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "Cereal Choco Krispis 200g",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-08T18:53:01.879Z",
    //         "galeria": "[\"55133421629551929.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "39298551629551917.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Cereal Choco Krispis 180g",
    //         "oferta": "[\"Fixed\",2.9,\"2025-12-31\"]",
    //         "precio": 3.103,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "cereal-choco-krispis-180g",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQKoX8f_oK8IOWzHD0X": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Salsa de tomate pampero 397 gr</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-06T03:51:25.011Z",
    //         "galeria": "[\"56903461609905086.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "11862021609905084.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Salsa de tomate pampero 397 gr",
    //         "oferta": "",
    //         "precio": 2.05,
    //         "prox": false,
    //         "sales": 2,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "salsa-de-tomate-pampero-397-gr",
    //         "video": "[]",
    //         "views": 14
    //     },
    //     "-MY8WekQxn-foR-2f-Xk": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "<font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">J barra Bonaropa coco 200gr</font></font>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-02-27T02:28:40.098Z",
    //         "galeria": "[\"10884041618288748.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "55506321618288747.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Jabón En Barra Bonaropa Coco 200gr",
    //         "oferta": "[\"Fixed\",0.77,\"2025-01-31\"]",
    //         "precio": 0.8239,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "jabon-en-barra-bonaropa-coco-200gr",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OBX2iIRhSWxT8PBUZsj": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-11-12T20:27:01.926Z",
    //         "galeria": "[\"10222721731443221.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "44651651731443220.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Aceituna Verde Entera Giralda 500grs",
    //         "oferta": "",
    //         "precio": 3.79,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 24,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "aceituna-verde-entera-giralda-500grs",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mbn9w5j6bLhT8EFq5K_": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-06-09T23:46:40.311Z",
    //         "galeria": "[\"9579211623282400.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "42209051623282399.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Hilo Dental Galactic ",
    //         "oferta": "",
    //         "precio": 1.74,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 6,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "hilo-dental-galactic-",
    //         "video": "[]",
    //         "views": 6
    //     },
    //     "-NQUZMpt-fhLsh021qNl": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-03-14T10:01:36.978Z",
    //         "galeria": "[\"47979081678788097.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "33128651678788096.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Hisopos Litte Angel X100 Unidades ",
    //         "oferta": "",
    //         "precio": 0.97,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 0,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "hisopos-litte-angel-x100-unidades-",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MnGgpe1vVFaCtz2yE0U": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "Aceitunas rellenas de frasco de 200gr",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-29T08:47:52.248Z",
    //         "galeria": "[\"90763451635605764.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "72858871635605759.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Aceitunas Rellenas 200gr La Giralda",
    //         "oferta": "",
    //         "precio": 2.62,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "aceitunas-rellenas-200gr-la-giralda",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-OJAkhDbFmxzKM3HXEtz": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-02-15T22:37:49.063Z",
    //         "galeria": "[\"58082041739659071.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "82101361739659070.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Carbon Instantáneo 1.2 Kg",
    //         "oferta": "",
    //         "precio": 2.56,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "carbon-instantaneo-1-2-kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Mr8tK8rrDYXGmceqRsT": {
    //         "categoria": "mascotas",
    //         "costo": "",
    //         "descripcion": "Perrarina Magic Friends Premium Para Adultos 2kg",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2025-01-16T00:33:01.251Z",
    //         "galeria": "[\"17247071639769788.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "93142601639769787.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Perrarina Magic Friends Premium Para Adultos 2kg",
    //         "oferta": "[\"Fixed\",4.5,\"2025-12-31\"]",
    //         "precio": 4.815,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "perrarina-magic-friends-premium-para-adultos-2kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-NHPW_egBG-2GUYVTKE2": {
    //         "categoria": "higiene-personal",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2022-11-21T14:10:06.763Z",
    //         "galeria": "[\"98844591669039806.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "57144251669039805.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Enjuag Colgate Pplan 250ml",
    //         "oferta": "",
    //         "precio": 3.73,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 3,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "enjuag-colgate-pplan-250ml",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-N6Y9OYIYrMDV9EolO_v": {
    //         "categoria": "articulos-de-limpieza",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-10-18T15:23:53.923Z",
    //         "galeria": "[\"87641561657373562.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "7558171657373561.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Detergente Multi Clean 5kg",
    //         "oferta": "",
    //         "precio": 12.5,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 1,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "detergente-multi-clean-5kg",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MlGVOPpH3IsLFv1wRNS": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-10-05T17:30:16.097Z",
    //         "galeria": "[\"45476681633455019.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "7357451633455016.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Vinagre De Sidra De Manzana Con Madre Natri 250ml",
    //         "oferta": "",
    //         "precio": 2,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 2,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "vinagre-de-sidra-de-manzana-con-madre-natri-250ml",
    //         "video": "[]",
    //         "views": 9
    //     },
    //     "-O-jnZ8-T8NtkNHUamVS": {
    //         "categoria": "combos",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-06-19T09:22:43.374Z",
    //         "galeria": "[\"32406641718788964.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "82977451718788964.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Combo Oferta 2",
    //         "oferta": "[\"Fixed\",12,\"2025-12-31\"]",
    //         "precio": 12.84,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "combo-oferta-2",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-MQKrNt_Oim3W5C3o5k3": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "<h5 _ngcontent-anc-c32=\"\" class=\"card-title mb-2\" style=\"font-size: 15px; color: rgb(33, 37, 41); font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;\">Vinagre de litro Heinz</h5>",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2021-01-06T04:03:52.611Z",
    //         "galeria": "[\"91145041609905835.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "71934421609905832.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Vinagre de litro Heinz",
    //         "oferta": "",
    //         "precio": 2.6,
    //         "prox": false,
    //         "sales": 2,
    //         "stock": 12,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "vinagre-de-litro-heinz",
    //         "video": "[]",
    //         "views": 12
    //     },
    //     "-NOo7lDJP5rMvHXsro0Y": {
    //         "categoria": "viveres",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2024-09-08T11:06:46.357Z",
    //         "galeria": "[\"62121031676985700.png\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "6997451676985694.png",
    //         "mostrarOcultar": false,
    //         "nombre": "Harina La Oz X 3 Unidades  ",
    //         "oferta": "[\"Fixed\",2.72,\"2025-01-31\"]",
    //         "precio": 2.9104,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 100,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "harina-la-oz-x-3-unidades--",
    //         "video": "[]",
    //         "views": 0
    //     },
    //     "-Nmv68DLfJPP8zfFBTMm": {
    //         "categoria": "confiteria-y-galletas",
    //         "costo": "",
    //         "descripcion": "",
    //         "detalles": "",
    //         "especificaciones": "[]",
    //         "estado": true,
    //         "fecha_create": "2023-12-30T14:24:19.869Z",
    //         "galeria": "[\"6115671703946260.jpg\"]",
    //         "horizontal_banner": "{\"H4 tag\":\"\",\"H3-1 tag\":\"\",\"H3-2 tag\":\"\",\"H3-3 tag\":\"\",\"H3-4s tag\":\"\",\"Button tag\":\"\",\"IMG tag\":\"assets/img/products/default/default-horizontal-slider.jpg\"}",
    //         "imagen": "67231421703946259.jpg",
    //         "mostrarOcultar": false,
    //         "nombre": "Dorito 45 g",
    //         "oferta": "",
    //         "precio": 1.11,
    //         "prox": false,
    //         "sales": 0,
    //         "stock": 50,
    //         "summary": "",
    //         "tags": "",
    //         "tienda": "frigo-carnes-mercafruver",
    //         "url": "dorito-45-g",
    //         "video": "[]",
    //         "views": 0
    //     }
    // }
    //   observer.next(objeto); // Emite el objeto
  
    //   observer.complete();
    // })

    return this.http.get(`${environment.Api}productos.json?orderBy="tienda"&equalTo="${this.router.url.split('/')[1]}"&print=pretty`)
    .pipe(

      map( (resp:any)=>{

        this.dataProduct = [];

        Object.keys( resp ).map( (a:any)=>{
          this.dataProduct.push({
            id:a,
            categoria: resp[a].categoria,
            descripcion: resp[a].descripcion, 
            detalles: resp[a].detalles,
            especificaciones: resp[a].especificaciones != '' ? JSON.parse(  resp[a].especificaciones ) : [],
            estado: resp[a].estado,
            fecha_create: resp[a].fecha_create,
            galeria: resp[a].galeria != '' ? JSON.parse(resp[a].galeria) : [],
            horizontal_banner: resp[a].horizontal_banner,
            imagen: resp[a].imagen == '' ? 'https://safetyaustraliagroup.com.au/wp-content/uploads/2019/05/image-not-found.png' : `${ environment.Server}assets/img/products/${resp[a].tienda}/${resp[a].imagen}`,
            mostrarOcultar: resp[a].mostrarOcultar,
            nombre: resp[a].nombre,
            oferta: functions.DinamicPrice(resp[a]),
            oferta1: resp[a].oferta,
            precio: resp[a].precio,
            prox: resp[a].prox,
            sales: resp[a].sales,
            stock: resp[a].stock,
            summary: resp[a].summary,
            tags: resp[a].tags,
            tienda: resp[a].tienda,
            url: resp[a].url,
            video:  resp[a].video,
            views: resp[a].views,
            activar: false,
            cantidad: 1,
            costo: resp[a].costo
          })
          
          
          if( resp[a].oferta != ''){

            let today = new Date();
    
            let offerDate = new Date(

              parseInt( JSON.parse(resp[a].oferta )[2].split("-")[0]),
              parseInt( JSON.parse(resp[a].oferta )[2].split("-")[1])-1,
              parseInt( JSON.parse(resp[a].oferta )[2].split("-")[2])

            )

            if( today < offerDate ){
              this.ofertProducts.push(resp[a])
              this.ofertas = true;
              
            }

          }

        })

        this.productOfert.next(this.ofertProducts)
        return resp;
      })

    )

  }

  getFilterDataLimitPedido(orderBy:string, equalTo:string, limit:number){

		return this.http.get(`${environment.Api}pedidos.json?orderBy="${orderBy}"&equalTo="${equalTo}"&limitToLast=${limit}&print=pretty`);

  }

  registroPedidoSinUid(body:object ){

    return this.http.post(`${environment.Api}pedidos.json`, body);

  }

  getFilterDataPedidos(orderBy:string, equalTo:string){

		return this.http.get(`${environment.Api}pedidos.json?orderBy="${orderBy}"&equalTo="${equalTo}"&print=pretty`);

  }

  /*=============================================
  TRAER EL PRODUCTO POR SU ID
	=============================================*/
  getItemId(id: string) {

		return this.http.get(`${environment.Api}productos/${id}.json`);
		
	}

}
